.cart-view {
  padding: 10px;
  margin: 12px 0;
  font-size: 12px;
  line-height: 16px;
  background-color: #fff;
}

.cart-view .review-cart-btn {
  width: 100%;
  height: 55px;
  font-size: 16px;
  font-weight: 700;
}

.cart-view .review-cart-btn img {
  margin-right: 10px;
}

.cart-view .cart-review-text {
  margin: 30px 0 10px;
}
.cart-view .cart-review-text p {
  margin-bottom: 20px;
  letter-spacing: 0.15px;
}

.cart-view .confirm-address-warning {
  gap: 6px;
  margin-bottom: 8px;
}

.cart-view .confirm-address-warning img {
  filter: invert(44%) sepia(61%) saturate(3524%) hue-rotate(337deg) brightness(103%) contrast(107%);
}

