.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker input:focus-visible {
  outline: none;
  border: 2px solid #fd4e3b;
}

.react-datepicker input:focus {
  border: 2px solid #fd4e3b;
}

.react-datepicker input:hover {
  border: 1px solid hsl(0, 0%, 0%);
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  display: flex;
}

.react-datepicker-wrapper input {
  width: 100%;
  padding-left: 35px;
  border: 1px solid #c4c4c4;
  color: #2c2d34;
  border-radius: 4px;
  background-image: url("/public/assets/images/icon-calendar.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #fd4e3b;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #fd4e3b;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #fd4e3b;
}
