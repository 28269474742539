html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body{padding-top: 70px;font-family: 'Open Sans', sans-serif;font-size: 16px;line-height: 22px;
	//&::-webkit-scrollbar { width: 0 !important }
}
$w:#fff;
$b:#000;
$hc:#FD4E3B;
$lato:'Lato', sans-serif;
.h1{font-size: 36px;line-height: 48px;font-weight: 700;}
.h2{font-size: 32px;line-height: 42px;font-weight: 700;}
.h3{font-size: 28px;line-height: 38px;font-weight: 600;}
.h4{font-size: 24px;line-height: 32px;font-weight: 600;}
.h5{font-size: 20px;line-height: 27px;font-weight: 600;}
.h6{font-size: 14px;font-weight: 600;}





@mixin transition($args...){-webkit-transition:$args;-moz-transition:$args;-ms-transition:$args;-o-transition:$args;transition:$args}

a, a:hover, button{cursor: pointer; text-decoration: none;@include transition(all 0.4s ease);}
button.MuiButton-root{text-transform: capitalize;}




header{height: 70px;display: flex;border-bottom: 1px solid #EEEEEE;background-color: $w;
	.container-fluid{display: flex;align-items: center; justify-content: space-between;}
	.logo{
		img{display: block;}
	}
	.header-menu{display: flex;align-items: center; justify-content: space-between;flex-shrink: 0;
		ul{
			li{display: inline-flex;
				a{color:#2C2D34;text-decoration: none;padding: 5px 15px;position: relative;
					&:after{position: absolute;content: '';width: 1px;height: 10px;background-color: #2C2D34;top: 50%;transform: translateY(-50%);right: 0px;}
					&:hover{color: $hc;}
				}
				&:last-child a:after{display: none;}
			}
		}
		.signin-btn{background-color: $hc;color: $w;text-transform: capitalize;padding: 5px 20px;}
		.header-user-name{font-size: 16px;font-weight: 700;margin-left: 10px;color: #383A40;text-transform: capitalize;@include transition(all 0.4s ease);
			&:hover{color:$hc;}
		}
	}
}

// Logged In Header
header.LoggedInHeader{
	.container-fluid{padding-left: 0;}
	.loggedLogo{width: 200px;height: 100%;margin-right: 20px;background-color: $hc;display: flex;align-items: center;justify-content: center;flex-shrink: 0;}
	h3{font-size: 22px;font-weight: 700;}
	.header-loaction-field-wrap{display: flex;align-items: center;}
	.header-loaction-field{
		.header-loaction-data{
			h4{font-size: 16px;line-height: 18px;font-weight: 600;text-transform: capitalize;}
			label{font-size: 12px;display: block;}
		}
		fieldset{
			legend{
				span{display: none;}
			}
		}
		.MuiOutlinedInput-notchedOutline {border-width: 1px;}
		.MuiInputBase-input{padding: 5px 10px;min-height: 40px;}
	}
	.headerAddBusinessBtn{height: 44px;margin-left: 24px;position: relative;
		img{margin-right: 8px;}
		&::after{content: "";position: absolute;height: 51px;width: 1px;background-color: #ebebeb;left: -12px;}
	}
	
}

.MuiSnackbar-root{max-width: 500px;}

///////////////////////////////---Admin---/////////////////////////////

.wrap.wrap-height{}
.wrap-height{height: calc(100vh - 70px);overflow: hidden;}
.wrap-height-no-scroll{height:calc(100vh - 76px);overflow: hidden;}
.overflow{overflow: scroll;height:100%;}

.login-img{background-color: #0C0B09;height:100%;position: relative;
    img{width: 100%;}
    h3{width: 70%;margin: auto;color: #fff;position: absolute;left: 0;right: 0;bottom: 80px;/*top: 50%;transform: translateY(-50%);*/}
}

.login-left{display: flex;}

.textBox{position: relative;
	.error-msg{position: absolute;bottom: 3px;
		&.invalid-error-msg{top:-4px;left: 27px;z-index: 1;}
	}
}

.login-box.create-step-form{width:400px;padding: 30px;margin: auto;border: 1px solid #CFD7E3;border-radius: 13px;
	.h4{padding-bottom: 22px;color: $hc;text-align: center;border-bottom: 1px solid #EFEFEF;margin-bottom: 30px;}
	.formGroup{margin-top: 20px;}
	.form-control{height: 45px;color: #526495;border-color:#CFD7E3;padding: 10px;border-radius: 5px;
		&:focus{outline: 0;box-shadow: none;}
	}
	.login-btn.MuiButton-root {height: 50px;margin-top: 20px;font-size: 14px;border-radius: 6px;background-color: $hc;color:$w;width: 100%;text-transform: capitalize;}
	.MuiFormControlLabel-root {padding-bottom: 0px;}
}

.login-box-frontend{width: 400px;margin:auto;
	.h4{font-size: 20px;font-weight: 600;color: #2C2D34;text-align: center;}
	.login-box.create-step-form{width: auto;padding: 40px 30px;
		.textField {
			input{color: #526495;font-size: 16px;}
			.MuiOutlinedInput-notchedOutline {border-color: #CFD7E3 !important;}
			.MuiInputLabel-root.Mui-focused {color: #000 !important;}
			.MuiInputLabel-root{text-transform: capitalize;}
		}
		.login-btn.MuiButton-root{font-weight: 600;}
		.login-separator{margin: 25px 0 22px;position: relative;text-align:center;
			label{font-size: 16px;color: #9598A8;
				&:after, &:before{position: absolute;height: 1px;width: 50%;background-color: #CFD7E3;content: "";top: 50%;transform: translateY(-50%);}
				&::before{left: -30px;}
				&::after{right: -30px;}

			}
		}
		.login-create{
			h4{font-size: 20px;font-weight: 600;color: #2C2D34;text-align: center;}
			p{margin: 12px 0 20px;font-size: 12px;color: #9598A8;}
		}
		.new-account-btn{margin-top: 20px;;display: flex;align-items:center;gap:20px;
			a{width: 50%;display: block;}
			button{width: 100%;height: 50px;font-weight: 700;font-size: 14px;color: #FFFFFF;background-color: #787878;}
		}
	}
}

.login-check{margin: 5px 0 0px;display: flex;align-items: center;justify-content: space-between;font-size: 12px;color:$b;
	.MuiTypography-root {font-size: 12px;}
	a{color: $b;
		&:hover{text-decoration: underline;}
	}
}

.createOneLink{font-size: 12px;margin-top:15px;
	a{color: $hc;
		&:hover{text-decoration: underline;}
	}
}

// Create
.create-step-wrap{padding: 30px 50px;}

.create-stepper{
	.MuiStepConnector-root {display: none;}
	.MuiStep-root{padding:0 8px;
		&:first-child{padding-left: 0;}
	}
	.MuiStepLabel-label{font-size: 16px;
		&.Mui-active{color: $hc;font-weight: 600;text-transform: capitalize;}
	}
	.MuiStepLabel-iconContainer{font-weight: 600;
		svg{}
	}
	.MuiStepIcon-root{color: #646777;
		&.Mui-completed{color:#FAA095;
			&.MuiStepLabel-label {}
		}
		&.Mui-active{color:#FAA095;}
	}
	.MuiStepLabel-label.Mui-completed{color: $hc;font-weight: 600;}
}



.subhead{font-size: 12px;line-height: 14px;color: #3d3d3d;}
.subheadSmall{font-size: 8px;color: #9598A8;}
.error-msg{font-size: 10px;display: block;margin-top: 3px;color: red;
	&.inst-msg{margin-top: -20px;margin-bottom: 15px;}
}
.create-step-form{padding-top: 24px;
	.subhead{}
	.allFeild{margin-bottom: 24px;}
	.textField{width: 100%;
		.MuiInputLabel-root {color:#646777;/*font-size: 14px;letter-spacing: 0.4px;/*color: #646777;*/
			// &::first-letter, &::first-line{text-transform: capitalize;}
		
		&.MuiInputLabel-shrink{}
		}
		.MuiFilledInput-root{border-radius: 0;}
		input{color: #2C2D34;/*height: 15px;font-size: 16px;*/}
		.MuiSelect-select {color: #2C2D34;/*height: 15px;min-height: 15px;font-size: 12px;line-height: 12px;*/}
		.MuiOutlinedInput-notchedOutline{border-color:#D9E5F3 !important;}
		.MuiOutlinedInput-notchedOutline {}

	}
	.MuiFormControlLabel-root {padding-bottom: 24px;margin-right: 0;
		.MuiCheckbox-root {padding-top: 0;padding-bottom: 0;}
		.MuiFormControlLabel-label{font-size: 14px;}
		&.custom-delivery-fee-label{
			.MuiFormControlLabel-label{font-size: 16px;font-weight: 600;}
		}
	}
	hr{margin: 0;background-color:#D9E5F3;opacity: 1;}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
	}
	input[type=number] {
		-moz-appearance:textfield;
	}
	.MuiOutlinedInput-notchedOutline{border-color: #D9E5F3 !important;}
	.MuiInputLabel-root {color:#646777 !important;}
}

.loginAgreeText{margin-top: 20px;
	.MuiCheckbox-root {padding: 0;padding-right: 10px;padding-left: 9px;}
	.MuiTypography-root {font-size: 12px;color: #000;
		a{color:$hc;text-decoration: underline;
			&:hover{color: $b;}
		}
	}
}

.createStepBtn{margin-top: 30px;display:flex;align-items:center;justify-content:flex-end;
	.MuiButton-root{width: 200px;height: 42px;border-radius: 6px;position: relative;font-size: 14px;font-weight: 600;box-shadow:none;
		&.cancelBtn{margin-right: 24px;}
	}
	.btnArrow{position: absolute;right: 8px;}
}

.addBusinessSet{margin-top: 24px;
	.h6{margin-bottom: 20px;}
}
.form-space{padding-bottom: 24px;}
.delivery-distance-text{margin-top: 5px;
	label{font-style: 12px;color: #646777;display: block;}
	span{font-size: 14px;color: #2C2D34;display: block;}
}
.more-delivery-address-email{font-size: 12px;text-decoration: underline;color: #2C2D34;margin-top: -4px;display:block;
	&.remove{margin-top: -26px;}
	&:focus, &:hover{color: $hc;text-decoration: underline;}
}
.hours-title-label{font-weight: 600;}
.order-cutoff-time{margin: 25px 0;}
.weekly-hrs-outer{border: 1px solid #D9E5F3;border-radius: 6px;margin-bottom: 24px;padding: 20px;
	.weekly-hrs{display: flex;align-items:center;justify-content:space-between;
		h6{font-style: 18px;font-weight: 600;}
		.weekly-hrs-open-close{display: flex;align-items: center;
			.shop-close-open-label{display: flex;flex-direction: row;justify-content: center;align-items: center;width: 64px;height: 24px;border-radius: 4px;font-size: 14px;color:$w;margin-left: 18px;
				&.shop-close-label{background: #FF4E4E;}
				&.shop-open-label{background: #28BC75;;}
			}
		}
	}
	.per-day-time{border-top: 1px solid #D9E5F3;padding-top: 22px;margin-top: 10px;display: flex;align-items: center;justify-content:space-between;
		.add-hours{margin-left: 10px;font-size: 14px;flex: 1;color: #91939C;display: flex;align-items: center;
			span{font-size: 24px;font-weight: 300;}
			&:hover{text-decoration: underline;}
		}
		.time-separator{margin:0 35px ;}
	}
}
.hours-form{
	.MuiTextField-root {width: 160px;border: 1px solid #D9E5F3;border-radius: 4px;}
	//.MuiOutlinedInput-notchedOutline{border: 0;}
	.MuiInputBase-input{}
	.MuiOutlinedInput-root.MuiInputBase-adornedEnd{flex-direction: row-reverse;}
	.MuiButtonBase-root.MuiIconButton-root{padding: 0;padding-right: 2px;}
}

.hours-dropdown{
	.MuiSelect-icon {display: none;}
	.MuiSelect-select{font-size: 14px;display: flex;align-items: center;}
	svg{width: 20px;height: 20px;margin-right: 5px;color:#646777;}
}

.MuiMenu-root {
	.MuiPaper-root {box-shadow: 0px 4px 5px 0px rgba(0, 0, 0 ,14%), 0px 1px 10px 0px rgba(0 , 0 , 0 , 12%), 0px 2px 4px -1px rgba(0 , 0 , 0 , 20%);
		.MuiList-root  {max-height: 200px;
			li{font-size: 14px;
				svg{width: 20px;height: 20px;margin-right: 5px;color:#646777;}
			}
		}
	}
}

.nav-header-menu .MuiPaper-root .MuiList-root {
	max-height: 80vh;
}
.sideMenu{width: 200px;top:70px;padding: 0 12px;left: 0;bottom: 0;position: fixed;background-color: #fff;box-shadow: 0px 1px 1px rgba(158, 157, 157, 0.25);overflow-y:auto;scrollbar-width: thin;scrollbar-color: #c4c4c4 #ffffff;
	.sideMenuItem{height: 50px;padding: 8px 10px !important;border-bottom: 1px solid #ebebeb;
		img{margin-right: 10px;width: 18px;}
		.MuiTypography-root{font-size: 14px;}
		&.Mui-selected{color:$hc;background-color: #fff;border-bottom: 2px solid $hc;
			.MuiTypography-root{font-weight: 600;}
			img{filter: invert(57%) sepia(67%) saturate(5448%) hue-rotate(335deg) brightness(103%) contrast(114%);}
		}
		.sideMenuItemText{display: block;}
	}

	&::-webkit-scrollbar {width: 5px;}

	&::-webkit-scrollbar-track {background: #ffffff;}

	&::-webkit-scrollbar-thumb {background-color: #c4c4c4;border-radius: 10px;border: 1px none #ffffff;}
}
.contentWrap{padding-left: 200px;background: #F5F6F8;min-height: calc(100vh - 70px);
	.contentWrapInner{padding: 20px 40px 40px 20px;}
	.location-head{
		.location-head-title{padding:27px 20px;display: flex;align-items:center;background-color: $w;
			h5{font-weight: 600;color: #484848;}
			input{width: 320px;height: 32px;margin-left: 24px;border: 0.3px solid #9598A8;border-radius: 6px;background-image: url('/public/assets/images/icon-search.svg');background-repeat: no-repeat;background-position: right 8px center;font-size:12px;padding-right: 25px;
				&:focus{outline: 0;box-shadow: none;}
				&::-webkit-input-placeholder {font-size: 12px;color: #9598A8;}
				&:-moz-placeholder {font-size: 12px;color: #9598A8;}
				&::-moz-placeholder {font-size: 12px;color: #9598A8;}
				&:-ms-input-placeholder {font-size: 12px;color: #9598A8;}
			}
			&.menu-search-by-item{
				input{height: 56px;font-size: 16px;
				&::-webkit-input-placeholder {font-size: 16px;color: #9598A8;}
				&:-moz-placeholder {font-size: 16px;color: #9598A8;}
				&::-moz-placeholder {font-size: 16px;color: #9598A8;}
				&:-ms-input-placeholder {font-size: 16px;color: #9598A8;}
				}
			}
		}
	}
}
.commonTabel{box-shadow:0px 0px 8px rgba(0, 0, 0, 0.06);background:#FFFFFF;border: 1px solid #F8F8F8;
	thead{
		tr{
			th{padding: 22px 20px;font-weight: 600;border-top: 1px solid rgba(224, 224, 224, 1);}
		}
	}
	tbody{
		tr{@include transition(all 0.4s ease);
			th,td{color:#4C5A76;padding: 22px 20px;border-bottom: 0;}
			&:hover{background-color: #e8e8e8;}
		}
	}
	label{
		b{font-weight: 600; display: block;text-transform: capitalize;}
	}
	small{font-size: 12px;display: block;text-transform: capitalize;}
}
.table-pagenation{padding: 10px 20px 50px;display: flex;align-items: center;position: relative;justify-content:center;
	.rowCount{position: absolute;left: 20px;
		.MuiOutlinedInput-root {background-color: $w;}
		fieldset{border: 0;
			legend{display: none;}
		}
	}
	.tableCount{
		button{border: 0;background-color: $w;color: #646777;border-radius:0;
			&.Mui-selected{border-bottom: 2px solid $hc;color:$hc;
				&:focus{background-color: $w;}
			}
		}
	}
}

.ld-tile-outer{margin-bottom: 20px;}
.location-details-tile{background-color: #fff;padding: 20px;margin-bottom: 10px;box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);border-radius: 6px;/*height:100%;*/
	.ld-titles{margin-bottom: 20px;border-bottom: 1px solid #EFEFEF;padding-bottom: 10px;display: flex;align-items:center;justify-content:space-between;
		h4{display: flex;align-items:center;margin-bottom: 0;
			img{margin-right: 15px;}
		}
		
	}
}
.ld-edit{font-size: 14px;display: flex;align-items:center;color: $hc;
	img{margin-right: 6px;}
	&:hover{text-decoration:underline;color: $hc;}
}
.ld-contact{display: flex;align-items:flex-start;border-bottom: 1px solid #EFEFEF;padding-bottom: 5px;margin-bottom: 15px;}
	.ld-contact-img{flex-shrink: 0;position: relative;margin-right: 20px;
		img{object-fit: cover;width: 290px;height: 195px;border-radius: 5px;display: block;}
	}
	.cater-cash-tag{position: absolute;width: 74px;height: 74px;right: 10px;top: 10px;background: #FFFFFF;border: 3px solid rgba(87, 132, 187, 0.3);color:#4F4080;border-radius:50%;font-weight: 600;display: flex;flex-direction: column;align-items: center;justify-content: center;
		b{font-size: 20px;}
		label{font-size: 10px;}
	}
	.ld-contact-details{margin-left:15px;display: flex;align-items:center;color: #4C5A76;width:100%;margin-right: 15px;
		.ld-contact-details-item{margin-right: 20px;
			&:last-child{margin-right: 0;}
		}
		label{font-size: 12px;display: block;}
		span{font-size: 14px;font-weight: 600;display: block;}
	}
.ld-business-primary.ld-business-detail{
	.ld-business-detail-item{margin-bottom: 10px;}
} 
.ld-business-detail{color: #4C5A76;
	.ld-business-detail-item{margin-bottom: 25px;}
	label{font-size: 12px;display: block;}
	b{font-size: 14px;line-height: 20px;display: block;font-weight: 600;text-transform: capitalize;word-break: break-all;}
}
.ld-readmore-wrap{
	.ld-readmore-box{width: 600px;
		button{padding: 0;}
		p{font-size: 14px;line-height: 20px;margin-top: 10px;;border: 1px solid #D9E5F3;border-radius: 4px;padding: 10px;padding-bottom: 20px;}
	}
}
.ld-options-wrap{display: flex;align-items: center;flex-wrap: wrap;}
.ld-options{flex-shrink: 0;margin-right: 10px;
	.MuiTypography-root{font-size: 14px;color: #3E3C37!important;font-weight: 600;}
	&.ld-options-edit{padding-bottom: 24px;margin-bottom: 24px;border-bottom: 1px solid #efefef;
		.MuiFormControlLabel-root{padding-bottom: 0;}
	}
	&.ld-options-edit-drawer{margin-right: 0;
		.location-details-tile{padding: 0;box-shadow: none;}
	}
}
.ld-others{
	ul{
		li{margin-bottom: 30px;font-size:14px;display:flex;align-items:center;justify-content:space-between;font-weight: 600;
			span{}
			b{}
			&:last-child{margin-bottom: 0;}
		}
	}
}
.ld-delivery-status{min-width: 60px;height: 24px;padding: 0 5px;font-size: 10px;margin-left: 18px;background: rgba(0, 198, 67, 0.04);border: 0.5px solid #00C643;border-radius: 3px;color: #2C2D34;display:flex;align-items:center;justify-content:center;text-transform: uppercase;
	label{width: 12px;height: 12px;margin-right: 5px;background: #00C643;border-radius: 50px;display: block;}
	&.ld-close{border: 0.5px solid red;background: rgba(255, 0, 0, 0.04);
		label{background: red;}
	}
}
.ld-delivery-hrs{
	ul{
		li{font-size: 14px;padding-bottom: 20px;margin-bottom: 20px;color: #3E3C37;font-weight: 600;border-bottom: 1px solid #EFEFEF;
			&:last-child{padding-bottom: 0;margin-bottom: 0;border: 0;}
		}
	}
}
.ld-slider-outer{}
.ld-slider{display: flex;align-items:center;
	h5{min-width: 85px;font-size: 14px;color: #3E3C37;margin-right: 20px;font-weight: 600;}
	h6{font-size: 12px;font-weight: 600;margin-left: 30px;}
}
.upload-img-wrap{margin-bottom: 35px;display: inline-block;position: relative;
	.upload-img{width: 245px;height: 165px;margin: auto;background-color: #f0f0f0;border-radius: 5px;object-fit: cover;cursor:pointer;
		&.upload-img-square{width: 150px;height: 150px;border-radius: 50%;}
		&.upload-no-image{object-fit: none;}
	}
	.upload-icon{position: absolute;bottom: -5px;right: -5px;cursor: pointer;}
}
// Edit location & Add Business Drawer
.common-drawer{
	.MuiDrawer-paper {width: 600px;overflow-y: scroll;overflow-x: hidden;}
	.drawer-head{padding: 20px 30px;display: flex;align-items: center;justify-content: space-between;box-shadow: 0px 1px 4px rgba(118, 118, 118, 0.16);
		h4{display: flex;align-items: center;margin-bottom: 0;
			img{margin-right: 12px;}
		}
	}
	.container-fluid{min-height: 100%;}
	.create-step-form{padding: 30px 18px;height: calc(100vh - 175px);overflow-y: auto;
		&::-webkit-scrollbar {width:5px;}
		&::-webkit-scrollbar-track {background-color: #dddada;}
		&::-webkit-scrollbar-thumb {background-color: rgb(184, 175, 175);border-radius: 20px;}
	}
	.createStepBtn{margin: 0;padding: 30px;border-top: 1px solid #CFD7E3;
		.MuiButton-root{width: 145px;}
		.MuiButton-root.cancelBtn{width: 120px;border: 1px solid #D9E5F3;border-radius: 4px;background-color: #fff;font-weight: 400;color: #383A40;}
	}
	.delivery-options-edit{
		label{margin-right: 0;}
	}
	.weekly-hrs-outer .per-day-time {
		.MuiFormControl-root {min-width: 135px;}
		.time-separator{margin: 0 10px;}
		.add-hours{margin-left: 15px;}
	}
	.add-business-step-wrap{
		.create-stepper{padding: 30px 30px 20px 30px;}
	}
	.add-business-step-wrap{
		.add-business-step-head{padding: 0 30px;padding-bottom: 15px;
			.h5{margin-bottom: 5px;}
		}
		.create-step-form{height: calc(100vh - 310px);padding-top: 15px;}
		.createStepBtn {
			.MuiButton-root.saveBtn{width: 170px;padding:8px 10px;justify-content: flex-start;}
		}
	}

	&.business-drawer{
		.MuiDrawer-paper {width: 620px;}
	}
}
.custom-delivery-field-wrap{@include transition(all 0.4s ease);display:flex;align-items:center;
		.allFeild{margin-bottom: 15px;}
		.cd-flex-1{flex:1.2;margin-right: 10px;}
		.cd-flex-2{flex:2;margin-right: 10px;}
	}

button.custom-delivery-field-wrap-btn{width: 80px;font-size: 12px;margin-bottom: 20px;font-weight: 700;
	img{margin-right: 5px;}
}

// Menu

.menu-head-title{padding: 20px;background-color: #fff;border-bottom: 2px solid #F3F3F3;
	h5{font-size: 16px;font-weight: 600;}
	label{}
	.home-cater-arrow{}
}

.menu-list-img{width: 60px;height: 60px;object-fit: cover;border-radius: 5px;}
.menu-cat-box{margin-left: 25px;
	// .MuiInputLabel-formControl{font-size: 12px;height: 15px;line-height: 10px;top:-4px;
	// 	&.Mui-focused {top:4px}
	// }
	//.MuiSelect-select {padding: 6px 14px;}
}

.menu-head-btns{
	button{height: 45px;margin-right: 25px;
		img{margin-right: 7px;}
		&:last-child{margin-right: 0;}
	}
}
.menu-back{font-size: 16px;font-weight: 600;color:#FAA095;display:flex;align-items:center;
	img{margin-right: 5px;}
	span{text-decoration: underline;}
	&:hover{color:$hc;
		img{filter: invert(62%) sepia(99%) saturate(4906%) hue-rotate(337deg) brightness(102%) contrast(107%);}
	}
}
.menu-view{margin-top: 30px;margin-bottom: 80px;background-color: #fff;}
.menu-view-head{padding: 20px;display:flex;align-items:center;justify-content:space-between;
	h4{font-size: 20px;font-weight: 600;}
	.ld-edit{font-size: 14px;}
}
.menu-details{padding: 20px;display:flex;align-items:center;border-bottom: 1px solid #E7E7E7;border-top: 1px solid #E7E7E7;
	.menu-details-img{margin-right: 20px;
		img{width: 140px;height: 140px;object-fit: cover;}
	}
	h5{margin-bottom: 20px;text-transform: capitalize;}
	.menu-item-details{display:flex;align-items:center;
		li{ margin-right: 20px;display:flex;align-items:center;
			img{margin-right: 9px;}
		}
	}
}
.menu-ingredient-list{padding: 20px;}
.menu-add-on{padding: 30px 20px;display:flex;align-items:center;justify-content: space-between;background-color: #fff;border-bottom: 1px solid #E7E7E7;
	h5{color: #484848;}
}
.main-addon{
	margin: 20px 0px 20px;margin-bottom: 0;;background-color: #fff;height: 90px;display: flex;justify-content: space-between;align-items: center;padding: 0 20px 0 80px;

	.addon-list{font-size: 18px;font-weight: 700;}
	.add-action{
		display: flex;
		.ld-edit{margin-right: 40px;}
	}
}
.main-add-on-head{background-color:#fff;padding: 20px 20px 20px 110px;font-size: 16px;color: #4C5A76;font-weight: 700;
	label{font-family: $lato;}
}
// .flex-2{flex:2}
// .flex-1{flex:1}
.sort-disabled{
	img{filter: invert(84%) sepia(5%) saturate(4%) hue-rotate(28deg) brightness(86%) contrast(92%);}
}






.width-1{width: 35%;}
.width-2{width: 15%;text-align: center;}
.width-3{width: 50%;}
.sub-add-on-wrap{background: #F0F0F0;padding-left: 70px;border-bottom: 2px solid #fff;position:relative;
	.sub-addon-sort{position: absolute;left: 23px;top: 50%;transform: translateY(-50%);
		.MuiButtonBase-root{padding: 5px;
			img{width: 10px;}
			&.disabled{cursor: auto;
				img{filter: invert(84%) sepia(5%) saturate(4%) hue-rotate(28deg) brightness(86%) contrast(92%);}
			}
		}
	}
}
.sub-add-on-inner{border:20px solid #E3E3E3;padding: 20px;background-color: #fff; 
	.form-control{padding: 8px 12px;height: 50px;border: 1px solid #D9E5F3;border-radius: 4px;
		&:focus{outline: 0;box-shadow: none;}
		&.sub-add-on-price{width: 160px;padding-left: 30px;margin-left: 5px;background-image: url('/public/assets/images/dollar.svg');background-repeat: no-repeat;background-position: left 12px center;background-size: 12px;}
	}
	.sub-add-on-right{display: flex;align-items: center;justify-content: space-between;}
	.sub-add-on-btn{width: 85px;height: 50px;font-size: 16px;font-weight: 700;font-family: $lato;margin-right: 10px;
		img{width: 20px;height: 20px;}
		&:last-child{margin-right: 0;}

	}
	.veg-nonveg-switch{margin: auto;}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none;-moz-appearance: none;appearance: none;margin: 0;}
	input[type=number] {
		-moz-appearance:textfield;
	}
	&.sort-indication{animation: blink 1s;animation-iteration-count: 1;}
}
@keyframes blink { 50% { border-color:rgba(253, 78, 59 , 67%) ; }  }
.sub-add-on-type-wrap{border:20px solid #f0f0f0;margin-top: 20px;background-color: #fff;width: 60%;}
.sub-add-on-type{padding: 20px;border-bottom: 1px solid #E7E7E7;
	.sub-add-on-type-price{width: 130px;padding-left: 30px;margin: 0 90px 0 20px;background-image: url('/public/assets/images/dollar.svg');background-repeat: no-repeat;background-position: left 12px center;background-size: 12px;}

}
.menu-list-more{
	ul{box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.17);
		border-radius: 5px;
		li{border-bottom: 1px solid #EFEFEF;font-size: 14px;color: #4C5A76;text-align: center;display: block;
			&:last-child{border-bottom: 0 solid #000;}
		}
	}
}
.menu-add-on-popup{width: 420px;
	.h4{padding:30px 0 20px;margin-bottom: 0px;font-size: 22px;line-height: 22px;font-weight: 700;color: $hc;font-family: $lato;text-align: center;border-bottom: 1px solid #CFD7E3;
	}
	.menu-add-on-popup-field{padding:20px 32px 0;
		.MuiFormControl-root{margin-bottom: 20px;}
	}
	.createStepBtn{padding: 0 32px;justify-content:space-between;margin-top: 10px;margin-bottom: 30px;
		.MuiButton-root{width: 120px;font-family: $lato;font-weight: 400;
			&.cancelBtn{border: 1px solid #8C9195;color: #8C9195;}
		}
	}
}


.crop-outer {
	img {
	  max-width: 800px !important;
	  max-height: 380px !important;
	}
  }


  .sort-category{
	.location-head{margin-top: 20px;border-bottom: 1px solid #f0f0f0;}
	thead tr{
		th{background: #f5f5f5;}
	}
	tr{
		th, td{background: #fff;border-bottom: 1px solid #F0F0F0;position: relative;
		}
	}
	.sub-addon-sort {
		button{padding: 4px;}
	}
  }

// Admin-login

.admin-login{margin-top: -70px;background-image: url("/public/assets/images/adminlogin.png");
	height: 100vh;background-position: center;background-repeat: no-repeat;background-size: cover;display: flex;
    align-items: center;justify-content: center;
	.login-box{background-color: #fff;}
	.admin-login-logo{margin:0 auto 25px;display: block;}
}


// Customer Create Account

.subhead.password-msg{position: absolute;bottom: -22px;line-height: 16px;display: block}
//.textBox.password-feild{margin-bottom: 50px;}
.textBoxTopMargin{margin-top: 50px;}

.create-account-condition{margin-top: 10px;
	p{color: #FAA095;
		a{color: #FAA095;text-decoration: underline;}
	}
}


.customer-profile{max-width: 700px;min-height:320px;margin: 65px auto;padding: 26px 22px;background-color: $w;border-radius: 6px;box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);
	.customer-profile-title{margin-bottom: 20px;padding-bottom: 16px;border-bottom: 1px solid #EFEFEF;display: flex;align-items:center;justify-content:space-between;
		h4{display: flex;align-items: center;
			img{margin-right: 15px;}
		}
		.ld-edit{font-weight: 600;}
	}
	.customer-profile-details{color: #4C5A76;
		label{font-size: 12px;}
		p{margin-bottom: 20px;font-size: 14px;font-weight: 600;}
	}
}

.admin-reset-password{padding:18px;
	.textField{width: 100%;}
	.textBox .error-msg{position: initial;}
}

.create-step-form-admin-details{height: calc(100vh - 223px);}


//Cater summary

.cater-summary{
	h4{margin :20px 0 20px;font-weight: 700;}
	.status-btn.MuiButtonBase-root{color: $w;}
}

.data-overview{margin: 30px 0;box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);border-radius: 6px;display:flex; background-color: #fff;
	.do-item{padding:22px 20px ;flex-grow: 1;flex-basis: 0;text-align:center;border-right:0.1px solid rgba(0, 0, 0, 0.15);
		img{}
		h6{font-size: 16px;margin: 10px 0;line-height: 22px;font-weight: 600;color: #4C5A76;}
		label{font-size: 20px;line-height: 27px;color: #4165AC;font-weight: 700;display: block;}
		&:last-child{border-right: 0;}
	}
}

.cater-summary .location-head .location-head-title {
	h5{font-size: 14px;text-decoration-line: underline;color: #7DA3C1;}
	button{width: 95px;height: 37px;font-size: 16px;font-weight: 700;font-family: $lato;text-transform: capitalize;}
}

.dashboard{
	.row {--bs-gutter-x: 32px;
		.col-md-6{margin-bottom: 32px;}
	}
	.cater-summary{height: 100%;background-color: #fff;box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
		h4.h4{padding: 22px 20px;display: flex;font-weight: 600;margin:0;background-color: #fff;
			img{margin-right: 15px;}
		}
	}
	.commonTabel tbody tr th, .commonTabel tbody tr td{padding: 15px 20px;
		.menu-list-img{display: block;width: 56px;height: 45px;}
	}
	.customer-dashboard.commonTabel tbody tr td{height: 75.5px;}
}

//Admin Payment

.contentWrap .location-head .location-head-title .admin-lfp-date input{margin-left: 0;padding: 0 10px;}

.admin-lfp-date{position: relative;
	.admin-error-msg{position: absolute;top:-22px}
}


// .refer-friend-action-btn.MuiButtonBase-root {
// 	&.Mui-disabled { color: rgba(0, 0, 0, 0.26)!important; box-shadow: none!important;background-color: rgba(0, 0, 0, 0.12)!important;}
// }

.refer-friend-action-btn{
	&.accept-disable{color: rgb(107, 107, 107)!important;background-color: rgb(92, 219, 135)!important;}
	&.declined-disable{color: rgb(236, 236, 236)!important;background-color: rgb(167, 167, 167)!important;}
	&.pending-disable{color: rgb(220, 220, 220)!important;background-color: rgb(254, 107, 137)!important;}
}

.settled-on{font-size: 11px;padding: 3px 5px;margin-top: 5px;border-radius: 4px;background-color: #8a8a8a;color: #fff;display: inline-block;}



///////////////////////////////---Admin---/////////////////////////////
.main-admin-back-link{margin: 30px 0px 10px 20px;font-size: 12px;text-decoration: underline;color: $hc;display: inline-block;
	&:hover{color: #000;text-decoration: underline;}
}
//--------------  Carer List -------------- //
.cater-profile-img{width: 55px;height: 45px;object-fit: cover;border-radius: 4px;}
.cater-tab{background-color: $w;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.13);
	.MuiTabs-root{
		button{padding: 12px 50px;text-transform: capitalize;
			&.Mui-selected {font-weight: 600;color: $b;}
		}
	}
}

//--------------  Carer List -------------- //

///////////////////////////////---Frontend---/////////////////////////////
.pageWrap{
	h2{margin-bottom: 25px;color: #646777;}
}
	.banner{height: 500px;background-image: url('/public/assets/images/search-bg.jpg');background-repeat: no-repeat;background-size:cover;background-position: center;display: flex;align-items: center;
		h1{color: #FD4837;}
			.banner-subtext{font-size: 16px;color: $w;}
	}
	.banner-search{border-radius: 6px;height: 85px;align-items:center;
		.MuiFormControl-root {
			a{font-size: 12px;color: #FD4E3B;white-space: nowrap;}
		}
		.MuiOutlinedInput-root {padding: 10px !important;}
		fieldset{border: 1px solid #D9E5F3;border-radius: 6px;}
	}
	.banner-points{display: flex;align-items:center;
		.banner-points-item{display: flex;align-items:center;margin-right: 65px;
			img{}
			.banner-points-item-text{padding:0 15px 0px 2px;margin-left: 6px;/*background-image: url('/public/assets/images/banner-border.svg');*/background-repeat: no-repeat;background-size:contain;background-position: bottom;
				h5{font-size: 12px;font-weight: 600;color: $b;line-height: 16px;}
			}
		}
	}
	.testimonial-card {
		border-radius: 10px;
		border-top: 1px solid #FD4E3B !important;
		border-bottom: 1px solid #FD4E3B !important;
		padding: 18px 10px;
		height: 100%;

		.testimonial-name {
			font-size: 24px;
			font-weight: 600;
			color: #FD4E3B;
		}

		.testimonial-job {
			font-size: 18px;
			font-weight: 500;
			color: #646777;
		}
	}
	.banner-search-link {
		button {
			// padding: 0;
		}
	}
	.banner-content {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.95);
		padding: 70px;
	}

	.home-cater-near{padding: 60px 0;
		.home-cater-near-title{display: flex;align-items:center;justify-content:space-between;
			label{font-size: 12px;font-weight: 700;color:$b;display: inline-block;margin-right: 10px;}
			button{padding: 3px;}
		}
	}

	.homeCaterSlider{
		.slick-slider {overflow: hidden;}
		.slick-list {margin: 0 -15px;}
		.cater-list-item{ padding: 0 15px;}
	}

	
	.cater-list-item{
		.cater-list-item-img{height: 250px;width: 100%;position: relative;
			img{height: 100%;width: 100%;object-fit: cover;border-radius: 5px;}
			.cater-cash-tag{top: 20px;right: 20px;}
		}
		.cater-list-item-cnt{padding-top: 20px;
			h4{font-size: 20px;font-weight: 600;color: #2C2D34;display:flex;align-items:center;text-transform: capitalize;
				label{margin-left: 6px;display:flex;align-items:center;
					img{margin-right: 5px;}
					span{font-size: 12px;color:#646777;margin-top: 2px;
						small{font-size: 10px;color: #B8BEC1;}
					}
				}
			}
			h6{margin: 10px 0 4px;font-size: 14px;text-transform: capitalize;color: #2C2D34;}
			.cater-list-order{font-size: 12px;color: #646777;display:flex;align-items:center;font-weight:600;
				span{display:flex;align-items:center;padding-right: 8px;margin-right: 8px;border-right: 1px solid #D9E5F3;
					img{margin-right: 5px;}
					&:last-child{border-right: 0;padding-right: 0;margin-right: 0;}
				}
			}
		}
	}

	.testimonialSlider{
		.slick-list {margin: 0 -25px;}
		.testimonial-item{ padding: 0 25px;}
	}

	.testimonial-author-img {
		margin-right: 10px;
		border-radius: 72px;
		border: 2px solid $hc;
		object-fit: cover;
	}
	.list-homepage {
		font-size: 20px;
	}

	.logo-carousel {
		padding: 100px 0 !important;
	}


	.testimonials{padding-bottom: 60px;
		.testimonial-item{margin-bottom: 40px;}
		.testimonial-item-text{min-height: 175px;padding: 30px;background: #EBEBEB;border-radius: 10px;position: relative;
			.pointer-mark{bottom:-16px;left: 40px;position: absolute;}	
			p{font-size: 16px;line-height: 23px;color: #777;}
		}
		.testimonial-author{margin-top: 20px;display:flex;align-items:center;justify-content:center;
			span{
				b{font-size: 18px;line-height: 22px;color: #404040;font-weight: 700;display: block;}
				label{font-size: 14px;line-height: 17px;color: #404040;}
			}
			
		}
	}

	.home-works{padding: 60px 0;background: linear-gradient(180deg, #FF7C74 12.04%, #FFCE84 88.09%);
		h2{color:$w;}
		.home-works-item{padding: 5px;background: #FFFFFF;border-radius: 10px;
			.home-works-item-img{height: 350px;width:100%;
				img{width: 100%;height: 100%;object-fit: cover;border-radius: 10px;}
			}
			.home-works-item-cnt{padding: 20px 50px;font-size:14px;line-height:20px;color: #555;text-align:center;
				h5{margin-bottom: 0;}
				label{padding: 15px 0 12px;display: block;}
				a{color: #555;font-weight: 600;text-decoration: underline;}
			}
		}
	}

	footer{padding: 30px 0;background-color: #fff;position: relative;z-index: 1;
		.container{display: flex;align-items:center;justify-content: space-between;}
		.logo-social-link{display: flex;align-items:center;
			.footer-logo{height: 68px;padding-right: 30px;margin-right: 30px;border-right: 1px solid #D9E5F3;}
			ul{display: flex;align-items:center;
				li{margin-right: 15px;
					a{display: block;
						img{display: block;}
					}
					&:last-child{margin-right: 0;}
				}
			}
		}
		.copy-terms{font-size: 14px;font-family: $lato;
			span{
				a{color: $hc;padding-right: 5px;margin-right: 5px;border-right: 1px solid $hc;
					&:last-child{border-right: 0;}
					&:hover{text-decoration: underline;}
				}
			}

		}
	}


//-------------- Listing page -------------- //

.listing-filter-primary{padding: 15px 0px;margin-bottom: 15px;border-bottom: 1px solid #D8D8D8;
	.container-fluid{display: flex;align-items: center;justify-content: space-between;}
	.lfp-label{font-size: 10px;color: #2C2D34;display: block;margin-bottom: 2px;font-weight: 500;;}
	.lfp-delivery-address{min-width:400px;
		.location-input{width: 100%}
		input{padding: 6px 5px !important;font-weight: 600;font-size: 14px;color: #2C2D34;}
	}
	.lfp-zip{margin-left: 30px; width: 120px;
		input{padding: 15px !important; font-size: 14px;color: #2C2D34;
		&.Mui-disabled { opacity: 1; -webkit-text-fill-color: initial}}
	}
	.lfp-date{margin: 0 30px;
		input{width: 275px;height: 50px;border:1px solid rgba(0, 0, 0, 0.23);font-weight: 600;font-size: 14px;
		}
		
	}
	.lfp-head-count{width: 150px;
		.MuiSelect-select{padding: 13.5px 9px;font-weight: 600;font-size: 14px;display: flex;align-items: center;color:#2C2D34;
			img{margin-right: 4px;}
		}
		input{padding: 13.5px 4px;color:#2C2D34;font-weight: 600;}
		p{color:#2C2D34;font-weight: 600;}
		&.lfp-order-type{width: 200px;margin-right: 30px;}
	}


	.MuiInputAdornment-root {flex-shrink: 0;}
	input{
		&::-webkit-input-placeholder {font-size: 12px;color: #646777;opacity: 1;letter-spacing: 0.5px;font-weight: normal;}
		&:-moz-placeholder {font-size: 12px;color: #646777;opacity: 1;font-weight: normal;}
		&::-moz-placeholder {font-size: 12px;color: #646777;opacity: 1;font-weight: normal;}
		&:-ms-input-placeholder {font-size: 12px;color:#646777;opacity: 1;font-weight: normal;}
	}
}
.listing-filter-secondary{padding-bottom: 8px;
	.container{display: flex;align-items: center;justify-content: space-between;}
	h3{font-size: 18px;font-weight: 600;}

	.lfs-item{margin-right: 20px;background-image: url('/public/assets/images/dropdown-arrow.svg');background-repeat: no-repeat;background-position: right 8px center;
		.MuiSelect-icon {display: none;}
	}
	
	.lfs-search{border-right: 1px solid #e8e8e8;padding-right: 20px;width:230px;background-image:none
		;
		input{padding: 7.5px 8px;padding-right: 0;font-size: 12px;
			&::-webkit-input-placeholder {color: #2C2D34;opacity: 1;}
			&:-moz-placeholder {color: #2C2D34;opacity: 1;}
			&::-moz-placeholder {color: #2C2D34;opacity: 1;}
			&:-ms-input-placeholder {color:#2C2D34;opacity: 1;}
		}
	}
	.lfs-deitary{width: 150px;}
	.lfs-option{width: 180px;
		.MuiSelect-select{padding-left: 30px !important;background-image: url('/public/assets/images/Options.svg');background-repeat: no-repeat;background-position: left 10px center;}
	}
	.lfs-cuisnes{width: 150px;
		.MuiSelect-select{padding-left: 30px !important;background-image: url('/public/assets/images/cusines.svg');background-repeat: no-repeat;background-position: left 10px center;}
	}
	.lfs-sort{width: 160px;border-left: 1px solid #e8e8e8;padding-left: 20px;}

	.MuiSelect-select {padding:4.5px 8px 4.5px 8px !important;font-size:12px;
		img{margin-right: 8px;}
	}
	fieldset{border-radius: 6px;}
	.MuiInputAdornment-root {flex-shrink: 0;}
	.lfs-clear-btn{height: 32px;font-size: 16px;font-weight: 700;font-family: $lato;}

	.lfs-dropdown-arrow{}
}

.lfs-menuitem.MuiButtonBase-root {padding:4px 8px;font-size:12px !important;
	img{margin-right: 8px;}
}

.lfs-select-item{
	.MuiTypography-root {font-size: 12px;}
}

.cater-list{height:calc(100vh - 206.5px);overflow-y: scroll;padding: 8px 0 30px;
	.cater-list-item-outer{margin-bottom: 35px;height: 100%;}
}

//--------------  Listing page -------------- //


//--------------  Detail page -------------- //

.detail-title-bar{padding-bottom:20px;margin-bottom: 20px;border-bottom: 1px solid #D8D8D8;
	.detail-main-points{
		li{margin-right: 20px;font-size: 14px;line-height: 18px;display: flex;align-items: center;color: $b;
			&:last-child{margin-right: 0;}
			&.dmp-locations{max-width: 380px;}
		}
		b{font-size: 16px;margin:0 5px;font-weight: 700;display: block;}
		.dmp-bg{padding: 0 10px;min-width: 38px;height: 38px;font-size: 12px;background-color: #F0F5FA;border-radius: 2px;display: flex;align-items: center;justify-content: center;}
		span.dmp-bg{margin-right: 12px;}
	}
}

.menu-category{background-color:#D9E5F3;border-radius: 8px;margin-bottom: 60px;
	.menu-category-item{padding:15px 10px;margin-right: 10px;color: #838592;
		text-transform: capitalize;/*text-align: center;*/
		p{white-space: pre;}
		
	}
	//.slick-active .menu-category-item{color:#000;font-weight: 600;}
	.slick-track{margin-left: initial;}
	&.menu-category-new{display:flex;overflow:auto;
		ul{display: flex;overflow: scroll;
			li{margin-right: 15px;
				a{padding: 15px 12px;font-size: 16px;line-height: 22px;color: #838592;display: block;}
			}
		}
	}
}

.slick-next.menu-category-arrow{right:-24px;width: 30px;height:52px;display: flex;
    align-items: center;justify-content:center;background-color: #d9e5f3;border-radius:0 8px 8px 0;z-index: 10;
	&:before{display: none;}
	img{width: 10px;}
}

.slick-prev.menu-category-arrow{left:-24px;width: 30px;height:52px;display: flex;
    align-items: center;justify-content:center;background-color: #d9e5f3;border-radius:8px 0px 0px 8px;z-index: 10;
	&:before{display: none;}
	img{width: 10px;}
}


.menu-category-listing{margin-bottom: 30px;
	.row{}
	.menu-category-listing-col{margin-bottom: 30px;position: relative;z-index: 1;/*border-bottom: 0.6px solid #D8D8D8;*/@include transition(all 0.4s ease);
		&::after{position: absolute;content: "";right: 0px;top:0;bottom: 30px;width: 0.6px;background-color: #d8d8d8;}
		&:nth-child(even):after, &:nth-last-child(1):nth-child(odd):after{display: none;}
		// &:last-child{border: 0;}
		&:nth-last-child(1):nth-child(odd) {border-bottom: none;}
		//&:nth-last-child(1):nth-child(even) {border-bottom: none;}
		&::before{position: absolute;height: 0.6px;bottom:0;background-color: #D8D8D8;content: "";@include transition(all 0.4s ease);}
		&:nth-child(odd)::before{left: 24px;right: 0;}
		&:nth-last-child(1):nth-child(odd)::before{height: 0;}
		&:nth-child(even)::before{left: 0;right: 24px;}
		&:hover{
			&::before{background-color: $hc;height: 1px;}
			.menu-category-listing-item{
				h3{color: $hc;}
				p{color: $b;}	
			}
		}	
	.item-add-btn{margin-top: 10px;height: 30px;font-size: 14px;@include transition(all 0.4s ease)}
	&:hover{
		.item-add-btn{background-color: $hc;color: $w;}
	}
}
	
	.menu-category-listing-item{padding-bottom: 30px;display: flex;align-items:flex-start;justify-content:space-between;cursor: pointer;position: relative;
		h3{text-transform: capitalize;display: flex;font-size: 20px;font-weight: 600;@include transition(all 0.4s ease);}
		.dietry-label{font-size: 14px;margin-top: 7px;margin-left: 0;cursor: pointer;}
		.menu-category-listing-item-img{margin-left: 15px;flex-shrink:0;
			img{width: 140px;height: 150px;object-fit: cover;display: block;border-radius: 3px;}
		}
		p{font-size: 14px;line-height: 22px;color:#646777;letter-spacing: 0.8px;@include transition(all 0.4s ease);}
		
		//&::after{position: absolute;height: 1px;width: 100%;bottom:0;background-color: red;content: "";}
		//&:nth-child(odd):after{right: 25px;}

		&.skeleton{
			h3 .MuiSkeleton-root{width: 320px;height: 22px;}
			.dietry-label .MuiSkeleton-root{width: 150px;height: 22px;}
			.price-time .MuiSkeleton-root{width: 200px;height: 22px;}
			p .MuiSkeleton-root{width: 420px;height: 20px;}

		}
	}
	.price-time{padding: 4px 0 10px;font-size: 14px;font-weight: 700;color: #616161;display: flex;align-items: center;}
	
}
.cater-detail-box{padding: 20px;margin-bottom: 35px;box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);border-radius: 6px;color: #646777;font-size:14px;
	h4{display: flex; align-items: center;padding-bottom: 10px;margin-bottom: 20px;border-bottom: 1px solid #EFEFEF;color:#000;
		img{margin-right: 12px;}
	}
}

.dietry-label{font-size: 16px;margin-left: 8px;display:flex;align-items: center;font-weight: 600;
	img{margin-right: 3px}
}

.review{
	.review-title{margin-bottom: 22px;display: flex; align-items: center;
		h4{padding-bottom: 0px;margin-bottom: 0px;border-bottom: 0;}
	}
	.rating{margin-bottom: 20px;letter-spacing:0.8px;
		.rating-star{
			img{margin-right: 12px;}
		}
		h6{margin: 12px 0;font-weight: 600;}
		p{line-height: 22px;}
	}
	
}
.add-review-btn.MuiButtonBase-root{font-size: 16px;font-weight: 700;font-family: $lato;text-transform: inherit;}

.detail-tagline{
	.detail-tagline-item{margin-bottom: 20px;
		h5{margin-bottom: 5px;font-size: 20px;font-weight: 600;color: #000;}
		p{
			a{color: #FAA095;font-weight: 600;}
		}
		h6{font-size: 16px;color:#000;
			span{font-size: 12px;font-weight: 400;}
		}
	}
}

.cater-detail-box.detail-ordering-hours{padding-bottom: 40px;margin-bottom: 0;}


.cater-detail-timing{display: flex;flex-wrap:wrap;
	li{width:calc((100% - 60px)/3);padding: 15px 0;/*margin-bottom: 15px;margin-right: 20px;*/font-size: 14px;text-transform: uppercase;border-bottom: 1px solid #EFEFEF;color: #3E3C37;
		b{font-weight: 600;}
		//&:nth-last-child(-n+3) {border-bottom: 0;padding-bottom: 0;}
		&:nth-child(7),&:nth-child(8){border-bottom: 0 solid #000;}
		&:nth-child(3n+2){position: relative;margin:0 30px;
			&::before, &::after{content:"";position: absolute;width: 1px;height: 100%;background-color: #EFEFEF;}
			&::before{left: -15px;}
			&::after{right: -15px;}
				
		}
	}


	// li{width:calc((100%)/3);padding: 15px 0;/*margin-bottom: 15px;margin-right: 20px;*/font-size: 13px;text-transform: uppercase;border-bottom: 1px solid #EFEFEF;color: #3E3C37;
	// 	b{font-weight: 600;}
	// 	&:nth-last-child(-n+3) {border-bottom: 0;padding-bottom: 0;}
	// 	&:nth-child(3n+2){position: relative;padding:0 30px;
	// 		&::before, &::after{content:"";position: absolute;width: 1px;height: 100%;background-color: #EFEFEF;}
	// 		&::before{left: 0px;}
	// 		&::after{right: 0px;}
				
	// 	}
	// }



	.working-schedule{}
}

.cart-view-col{background-color: #F0EDED;height: fit-content;}

.other-charges{padding: 10px 0;
	.other-charges-item{padding: 8px 0;display: flex;align-items: center;justify-content: space-between;}
}



.common-cart-viewbox{padding: 15px 0;display: flex;align-items: center;justify-content: space-between;border-bottom: 0.6px solid #D8D8D8;border-width: 0.6px 0;
	label{margin-right: 10px;display: flex;align-items: center;flex-shrink:0;
		button{margin-left: 8px;padding:0;}
	}
	&.common-cart-viewbox-tip{align-items: flex-start;border-top: 0.6px solid #D8D8D8;}
}

.tip-btn{display: flex;
	button{min-width: 35px;padding:5px 4px;margin-right: 3px;font-size: 11px;font-weight: 700;background: #E8E8E8;border: 1px solid #9598A8;color:#9598A8;
		&.tip-select{background: rgba(250, 160, 149, 0.12);border: 1px solid #FAA095;color: $hc;}
		&:last-child{margin-right: 0;}
	}
}
.other-amount{display: block;
	a{margin-top: 8px;font-size: 12px;color:#FAA095 !important;cursor: pointer;display: block;
		&:hover{color: $hc !important;display: block;}
	}
	
	.MuiInputBase-input {padding:10px;padding-left: 0;}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none;margin: 0;}
	input[type=number] {
		-moz-appearance:textfield;
	}
}
.other-amount-feild{width: 152px !important;animation: fadein 0.5s;margin-bottom: 0 !important;
	.MuiInputBase-input {padding:10px;
	
	}
	&.money-input {
		padding-left: 0;
	}
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none;margin: 0;}
	input[type=number] {
		-moz-appearance:textfield;
	}
}

.cart-total{padding: 20px 0;font-size: 16px;font-weight: 700;border-top: 0 ;}
	.cart-price-perhead{font-size: 16px;border-top: 0 ;
		b{font-weight: 700;color:#FAA095;}
}

.cart-catercash{padding: 10px 0 20px;font-size: 12px;font-weight: 700;display: flex;align-items: center;justify-content: center;
	img{margin-right: 10px;}
	b{font-weight: 700;color:#FAA095;}
}


//--------------  Detail page -------------- //


//Caterer Menu edit screen

.caterer-menu-modal{display:flex;align-items: center; justify-content: center;
	.caterer-menu-details{max-height: 80vh;width:720px;margin: auto;background-color: $w;border-radius:5px;
		.caterer-menu-header{height: 64px;display: flex;align-items: center;justify-content:space-between;padding: 10px 20px;background-color: #F5F5F5;border-radius: 5px 5px 0px 0px;
			h5{margin-bottom: 0;font-weight: 700;text-transform: capitalize;}
			small{margin-top: 2px;font-size: 12px;line-height: 16px;display:block;}
		}
		.caterer-menu-content {
			max-height: calc(80vh - 158px);
			padding:20px;
			font-size: 12px;
			line-height: 18px;
			color:#3E3C37;
			overflow: auto;

			.h5 {
				margin-top: 20px
				;margin-bottom: 5px;
			}

			small{
				font-size: 12px;
				line-height: 13px;
				display:block;
				color: #646777;
			}

			.MuiFormGroup-root{margin-top: 20px;flex-wrap:initial;
				.MuiFormControlLabel-root {margin-left: 0;margin-right: 0;
					.MuiButtonBase-root {padding:0}
					.MuiTypography-root {font-size: 14px;padding-left: 9px;}
				}
			}

			p {
				text-transform: capitalize
			}

			p.caterer-menu-description {
				text-transform: none !important;
			}

			.extra-charges{font-size: 12px;color: $b;font-weight: 700;display: block;line-height: 16px;}


			.addon-active-row{}
			.addon-active{border: 1px solid #D9E5F3;padding:0 15px 20px;animation: fadein 1s;width:97%;margin-left: auto;margin-right: auto;
				.mainAddonCheck{margin-left: -15px; margin-right: -15px;padding:15px;border-bottom: 1px solid #D9E5F3;
					.MuiTypography-root {font-weight: 600;}
				}
				
					.MuiFormControlLabel-root {
						.MuiButtonBase-root {}
						.MuiTypography-root {}
					}
				
			}

			@keyframes fadein {
				from { opacity: 0; }
				to   { opacity: 1; }
			}



		}
		.caterer-menu-footer{height: 94px;padding: 20px;display:flex;align-items: center; justify-content: space-between;box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);
			.menu-qty{display: flex;align-items: center;
				h6{font-size: 16px;font-weight: 700;}
				.menu-qty-edit{width: 180px;height: 46px;padding: 12px;margin-left: 20px;display: flex;justify-content: space-between;align-items: center;border: 1px solid #000000;border-radius: 22px;
					button.MuiButtonBase-root{padding: 4px}
					.form-control{max-width: 40px;padding: 0px;font-size: 16px;border: 0;color: #000;font-weight: 700;text-align: center;
						&:focus{outline: 0;box-shadow: none;}
					}
					input[type=number]::-webkit-inner-spin-button, 
					input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none;margin: 0;}
					input[type=number] {
						-moz-appearance:textfield;
					}
				}
			}
			.cart-price-btn{width: 300px;height: 55px;font-size: 16px;font-weight: 700;text-transform: none;display: flex;align-items: center;justify-content:space-between;}

		}
		&:focus{outline: none;}
		&:active{outline: none;}
	}
	&:focus{outline: none;}
	&:active{outline: none;}
}

.common-view-box-wrap{padding-top: 20px;}
.common-view-box-title{min-height: 62px;margin-top: 20px;padding: 10px;background: #F5F5F5;border-radius: 5px 5px 0px 0px;display:flex;align-items: center;justify-content: space-between;
	h5{font-size: 20px;font-weight: 700;margin-bottom: 0;}
	small{margin-top: 3px;font-size: 10px;line-height: 14px;color: #646777;display: block;}
}
.common-view-box{padding: 20px;border: 1px solid #F0EDED;border-radius:0 0 5px 5px;border-top: 0;
	h6{margin-bottom: 20px;font-size: 16px;color: #000;font-weight: 600;}
	.textField{width: 100%;margin-bottom: 25px;}
	.create-account-condition{margin-top: 0;
		.MuiCheckbox-root{padding-top: 0;padding-bottom: 0;}
		p{font-size: 12px;font-weight: 600;color: #000;}
	}
}
.back-link{font-size: 12px;color: #FD4E3B;text-decoration-line: underline;
	&:hover{color: #000;text-decoration: underline;}
}


.customer-profile-wrap{padding: 50px 0;
	.customer-profile{}
}

.sepration-line{margin: 0 -22px;height: 0.6px !important;border: 0;opacity: 1;background-color:#D8D8D8;}

.review-order-datepicker {
	input{width: 100%;height: 56px;padding: 16.5px 14px 16.5px 35px;border: 1px solid #c4c4c4;border-radius: 4px;
	}
}



//Add Card, saved card start

.added-card{padding-top: 20px;
	h6{margin-bottom: 20px;font-size: 16px;font-weight: 600;}
	.saved-card-item{padding-bottom: 20px;display: flex;align-items: center;justify-content: space-between;
		.card-image-number{margin-left: 10px;
			.card-image{margin-right: 6px;
				img{width: 32px;height: 20px;object-fit: cover;display: block;}
			}
			label{font-size: 14px;color: rgba(0, 0, 0, 0.87);}

		}
		.ld-edit{padding-right: 15px;margin-right:7px;font-size: 12px;line-height: 16px;border-right: 0.6px solid #D8D8D8;
		}
		.card-delete{
			&:hover{
				img{filter: invert(44%) sepia(61%) saturate(3524%) hue-rotate(337deg) brightness(103%) contrast(107%);}
			}
		}
		&:last-child{margin-bottom: 10px;border-bottom: 0.6px solid #D8D8D8;}
		.MuiFormControlLabel-root{margin-right: 0;}
	}
}
.add-card-btn.MuiButtonBase-root{margin-top: 15px;margin-bottom: 12px;;font-size: 12px;font-weight: 600;color: #646777;border: 1px solid #d0d0d0;
	img{margin-right: 5px;}
}

.add-new-card{padding-top: 15px;
	h6{margin-top: 20px;}
	p{font-size: 12px;color: #2C2D34;}
	
}

.cancel-save-btn{margin-top: 10px;text-align: right;
	button{width: 170px;height: 42px;font-size: 14px;color: #646777;font-weight: 600;border-radius: 6px;box-shadow: none;
		&.cancelBtn{margin-right: 25px;background-color: #f8f8f8;}
		&.saveBtn{color: $w;}
	}
}

//Add Card, saved card end



//Review order item start
.review-order-item{margin-top: 20px;
	.roi-single{margin-bottom: 12px;padding-bottom: 12px;border-bottom: 0.6px solid #D8D8D8;}
	.roi-details{padding-right: 50px;
		h6{margin-bottom: 0;}
		.roi-subaddon-wrap{margin-top: 10px;}
		p{font-size: 14px;line-height: 18px;color: #646777;}
	}
	.roi-count-price{}
	.roi-sp-instr{padding:12px 0;padding-right: 30px;
		h6{font-size: 14px;margin-bottom: 10px;}
		p{font-size: 14px;line-height: 16px;color: #646777;}
	}
	.roi-edit{font-size: 12px;color: #FAA095;display: inline-block;line-height: 14px;}
	.roi-count-edit{width: 85px;height: 30px;padding: 2px 4px;margin-right: 20px;
		display: flex;justify-content: space-between;align-items: center;border: 1px solid #646777;border-radius: 3px;font-size:14px;font-weight: 600;color:$b;
		button{padding: 4px;
			img{width: 9px;}
		}
	}
	.roi-price{font-size: 16px;color: $b;font-weight: 600;}
}
.roi-subaddon{
	span:last-child b{
		display: none;
	}
}

.review-order-price{margin-top: 10px;
	.common-cart-viewbox{padding: 10px 0;}
	.other-charges{padding: 0px 0;border-top: 0.6px solid #D8D8D8;
		.other-charges-item{padding: 5px 0;
			label{font-size: 12px;
				button{margin-left: 8px;padding:0;}
			}
			b{font-size: 16px;font-weight: 600;}
		}
	}
	.other-amount{
		a{margin-top: 0;}
	}
	.common-cart-viewbox{
		label{font-size: 12px;}
	}
	.common-cart-viewbox.common-cart-viewbox-tip{align-items: center;
		b{font-weight: 600;}
	}
	.tip-btn button{width: 45px;height: 35px;margin-right: 8px;}
	.cart-price-perhead b{font-size: 12px;color: #646777;}
	.cart-catercash{padding-bottom: 0;justify-content: flex-end;
		b{color: #646777;}
	}

	.add-promo-code{
		.MuiInputBase-root{height: 35px;width:150px;font-size:12px;
			&:focus{outline: 0;box-shadow: none;border-color: $hc;}
		}
		button{height: 35px;
			&.promo-code{margin-right: 8px;font-size: 12px;color: $hc;background: rgba(250, 160, 149, 0.12);border: 1px solid #FAA095;font-weight: 700;}
		}

	}
}

.review-order .review-order-price .cart-total.common-cart-viewbox 
label{font-size: 14px;}

.confirm-place-btn.MuiButtonBase-root{height: 55px;margin-top: 20px;margin-bottom: 10px;font-size: 16px;font-weight: 700;padding: 6px 30px;

	img{margin-right: 10px;}
}



//Review order item end



// Order confirm page start
.order-confirm-screen{height: calc(100vh - 203px);
	.ocs-img{text-align: center;
		img{margin: auto;display: block;}
		h5{margin-top: 45px;font-weight: 700;}
	}
	.ocs-text{justify-content: center;width: 60%;
		h1{margin-bottom: 15px;color:$hc;}
		p{color: #646777;
			b{font-weight: 700;}
			a{color: $hc;font-weight: 700;text-decoration: underline;}
		}
		.saveBtn{min-width: 160px;}
	}

	.valign{
		display: flex;align-items: center;flex-wrap: wrap;height: 100%;align-content: center;justify-content: center;
		
	}
}
// Order confirm page end


//Customer Login

.customer-login-outer{display: flex;flex-direction: column;justify-content: center;align-items: center;
	.ld-edit{font-size: 12px;text-decoration: underline;
		&:hover{font-weight: 600;}
	}
}
.customer-login{width: 400px;margin-top: 20px;;filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.04));
	.customer-login-title{height: 70px;padding: 0 20px;background: #F5F5F5;border-radius: 5px 5px 0px 0px;display: flex;flex-direction: column;align-items: flex-start;justify-content:center;
		h5{font-weight: 700;margin-bottom: 0;}
		small{font-size: 10px;line-height: 12px;display: block;}
	}
	.login-box.create-step-form{background: #FFFFFF;border: 1px solid #F5F5F5;border-radius: 0px 0px 5px 5px;}
}

.common-dialog-box{
	.dialog-box-title{padding-top: 10px;padding-bottom: 14px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CFD7E3;
		h5{color: $hc;margin-bottom: 0;font-weight: 700;}
	}
	.dialog-box-text{width: 85%;margin:10px auto;text-align:center;
		h5{margin-bottom: 0;color: #2C2D34;font-weight: 600;}
	}
	.MuiDialogActions-root {padding: 0 24px 30px;display:flex; align-items: center;gap:30px;
		button{width: 100%;height: 45px;font-size: 18px;font-weight: 600;
			&.dailog-cancel-btn{border-color: #9598A8;color: #646777;}
		}
	}
	&.vouceher-box{
		//.MuiPaper-root {background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F4F2F2 100%);}
		h2{padding: 0;}
		.dialog-box-title{padding:20px 0;margin-bottom: 20px;}
		.amazon-gift-img{height: 40px;}
		.h6{margin:10px 0 15px ;}
		button{}
		i{margin-top: 20px;font-size: 14px;color: #4b4b4b;font-style: italic;display: block;}
		.MuiDialogActions-root{padding-top: 30px;border-top: 1px solid #CFD7E3;}
	}

	&.review-box{
		.MuiPaper-root {width: 600px;}
		.dialog-box-title{
			h5{text-align: left;font-size: 22px;}
		}
		.review-star{margin-top: 10px;}
		.review-textbox{margin-top: 30px;margin-bottom: 10px;}
	}
}

.review-star-item span{margin-right: 5px;}



// Order View
.contentWrap-order-view{padding-top: 20px;padding-bottom: 50px;}
.back-link-order{margin-top: 0px;margin-bottom: 18px;font-size: 16px;color: #FAA095;font-weight: 600;display:inline-flex;align-items:center;
	img{margin-right: 5px;}
	&:hover{color: $hc;
		img{filter: invert(47%) sepia(85%) saturate(2998%) hue-rotate(335deg) brightness(96%) contrast(108%);}
	}
}
.order-view-title{min-height: 65px;padding: 14px 10px;background: #F5F5F5;border-radius: 5px 5px 0px 0px;display: flex;align-items: center;justify-content: space-between;
	h5{font-weight: 700;color:#2d2c34;margin-bottom: 0;
		span{padding-left: 12px;margin-left: 12px;border-left: 0.6px solid #B4B4B4;display: inline-block;}
	}
}
.status-btn.MuiButtonBase-root{width: 100px;height: 35px;border-radius: 8px;font-size: 14px;color: #323232;font-family: $lato;box-shadow: none !important;}
.order-view{padding: 20px;background: #FFFFFF;border: 1px solid #F0EDED;border-radius:0 0 5px 5px;
	.order-view-address-wrap{padding-bottom: 30px;border-bottom: 0.6px solid #D8D8D8;
		h6{font-size: 16px;font-weight: 600;padding-bottom: 12px;}
	}
	.order-view-address{display: flex;align-items: flex-start;
		.MuiAvatar-root{width: 65px;height: 65px;margin-right: 16px;object-fit: cover;font-size: 24px;}
		
		p{font-size: 12px;line-height:16px;
			label{color: #646777;display: inline-block;min-width:45px;}
		}
	}
	.od-common-box{padding: 12px 0;border-bottom: 0.6px solid #D8D8D8;
		h6{font-size: 16px;color: #000000;margin-bottom: 12px;}
		p{font-size: 12px;color: #646777;}
	}
	.review-order-item{
		h6{font-size: 16px;margin-bottom: 20px;font-weight: 600;}
	}
	.roi-count-price{font-weight: 600;}

	.review-order-price{
		h6{font-size: 16px;margin-bottom: 20px;}
		.other-charges .other-charges-item b{font-size: 14px;font-weight: 700;}
		.common-cart-viewbox{
			label{font-size: 14px;}
			b{font-size: 14px;font-weight: 700;}
			&.common-cart-viewbox-tip{
				label{font-size: 12px;}
			}
			&.cart-price-perhead{
				label{font-size: 12px;}
				b{font-size: 12px;}
			
			}
		}
	}
	.order-view-add-custom-item{margin-bottom: 20px;
		h6{font-size: 14px;margin-bottom: 0;padding-bottom: 12px;border-bottom: 1px solid #d8d8d8;color:#646777;  animation: fadein 1s;font-weight: normal;}
		.order-view-add-custom-item-btn{display: flex;align-items: center;cursor: pointer;animation: fadein 1s;@include transition(all 0.4s ease);
			img{}
			&:hover{color: $hc;}
		}
		form{animation: fadein 1s;}
		.create-step-form{padding-top: 15px;
			h6{font-size: 16px;color:$b;border: 0;}
			.allFeild{margin-bottom: 30px;}
			.textBox .error-msg {bottom: 10px;}
		}
		.add-custom-btn{margin-top: -10px;padding-top:15px;text-align:end;border-top: 1px solid #d8d8d8;
			button{height: 40px;width: 100px;font-size: 14px;border-radius: 4px;font-weight: 400;
				&.cancel-btn{margin-right: 35px;border: 1px solid #D9E5F3;color: #383A40;}
			}
		}
	}
	.review-order-item .roi-sp-instr h6{font-size: 14px;}
	
}
.order-status-wrap{margin-top: 40px;border: 1px solid #E7E7E7;border-radius: 5px;
	&.order-status-wrap-bottom{margin-top: 40px;}
}
.order-status{background-color: $w;padding: 20px 18px;
	.os-update{padding-bottom: 15px;display: flex;align-items:center;border-bottom: 1px solid #F0EDED;
		button{width: 135px;height: 56px;margin-left: 15px;background:$hc;border-radius: 6px;box-shadow: none;flex-shrink: 0;font-weight: 600;color:#fff;
			&.Mui-disabled{background: #FAA095;}
		}
	}
	fieldset{border-color:#D9E5F3;}
	.MuiTextField-root{width: 100%;}
	h6{font-size: 16px;margin-bottom: 8px;}
	.os-note{padding-top: 16px;
		.os-add-note-btn{width: 100px;height: 40px;margin-top: 20px;font-size: 14px;font-weight: 600;color: $w;border-radius: 6px;}
	}
	.os-notes-history{margin-top: 20px;padding-top: 15px;border-top: 1px solid #F0EDED;
		.os-notes-history-cnt{padding: 16px;height: 78px;background: #FAFAFB;
			p{font-size: 15px;line-height: 20px;color: #000000;}
			time{font-size: 12px;letter-spacing: 0.15px;color: rgba(0, 0, 0, 0.87);}
		}
	}
}

.order-invoice{
	.order-view{border-radius: 0;}
	.review-order-item{
		.roi-single{
			&:last-child{margin-bottom: 0;}
		}
	}
	.common-cart-viewbox.tableware{padding: 15px 0;margin-bottom: 10px;
		b{font-weight: 600;}
	}
	.review-order-price {margin-top: 0;
		.other-charges {border-top: 0;
			.other-charges-item{padding: 8px 0;
				label{font-size: 14px;}
				b{font-weight: 600;}
			}
		}
	}

	.roi-sp-instr{
		h6{font-size: 14px;margin-bottom: 8px;}
	}

	.review-order-price .common-cart-viewbox.cart-total{padding: 15px 0;margin-top: 10px;}

	.caterer-menu-footer{gap:20px;
		button{height: 55px;width: 100%;font-size: 14px;border-radius: 4px;
			&.cancel-btn{border: 1px solid #D9E5F3;color: #383A40;}
		}
	}
	
}

.issue-refund{
	button{background: #FD982F;border-radius: 6px;font-weight: 600;}
}
//Refund modal
.caterer-menu-details.refund-modal{width: 520px;
	.caterer-menu-content{max-height: calc(70vh - 213px);
		.MuiFormGroup-root {margin-top: 10px;margin-bottom: 18px;}
		.MuiFormGroup-root{
			.MuiFormControlLabel-root{margin-right: 30px;}
		}
	}
	.refund-tabs{
		button{width: 33%;height: 55px;font-family:$lato;font-size: 14px;font-weight: 400;    text-transform: inherit;color: $b;}
		.MuiTabs-indicator{height: 3px;}
	}
	.refund-tabs-content{
		.h6{margin-bottom: 5px;font-size: 16px;line-height: 22px;font-weight: 600;color: #000;}
		p{margin-bottom: 5px;font-size: 10px;line-height: 14px;font-weight: 400;color: #646777;}
		.input-group{margin-bottom: 20px;width: 200px;
			.form-control{font-size: 12px;color: #2C2D34;}
		}
		.form-control{border: 1px solid #D9E5F3;
			border-radius: 4px;height: 42px;
			&:focus{outline: 0;box-shadow: none;}
			&::-webkit-input-placeholder {font-size: 10px;color: #ACAEB8;}
			&::-moz-placeholder {font-size: 10px;color: #ACAEB8;}
			&:-ms-input-placeholder {font-size: 10px;color: #ACAEB8;}
			&:-moz-placeholder { font-size: 10px;color: #ACAEB8;}
		}
		.refund-price{margin-top: 20px;padding-top: 25px;border-top: 0.6px solid #D8D8D8;
			.refund-price-item{color: $b;border-bottom: 0.6px solid #D8D8D8;padding-bottom: 14px;margin-bottom: 14px;
				b{font-size: 14px;line-height: 20px;font-weight: 700;display: block;}
				span{font-size: 12px;line-height: 16px;display: block;}
				.refund-price-item-child{margin-bottom: 20px;}
			}
		}
	}
	.caterer-menu-footer{gap:20px;
		button{height: 54px;width: 50%;border-radius: 6px;}
		.cancelBtn{color: #383A40;border: 1px solid #D9E5F3;}
		.saveBtn{color: #fff;font-weight: 600;}
	}
}

//Refund modal

//Cater cash
.cater-summary .location-head.catercash-head .location-head-title{
	h5{font-size: 20px;font-weight: 600;color: $b;text-decoration: none;}
}

.gift-code{padding: 20px;gap:20px;background: #FFFFFF;border-radius: 5px;display: flex;align-items:center;
	b{margin-right: 20px;font-weight: 700;display: inline-block;}
}
.catercash-overview{gap:20px;padding: 20px;margin-top: 22px;display: flex;background-color: #fff;
		.cc-item{height: 140px;text-align: center;border: 1px solid;border-radius: 5px;flex-grow: 1;flex-basis: 0;display: flex;flex-direction: column;align-items: center;justify-content: center;
			h2{font-size: 44px;line-height: 60px;margin-bottom: 5px;font-weight: 700;}
			label{font-size: 14px;margin-left: 5px;display: block;font-weight: 700;}
			&.cc-total{background: #F4F9FF;border-color: #5784BB;color: #2D71C5;}
			&.cc-redeemed{background: rgba(253, 157, 57, 0.12);border-color: #FD9D39;color: #FD9D39;
				// img{filter: invert(66%) sepia(97%) saturate(569%) hue-rotate(329deg) brightness(98%) contrast(102%);}
			}
			&.cc-current{background: #F4F9FF;border-color: #6BBB57;color: #6BBB57;
				// img{filter: invert(68%) sepia(26%) saturate(834%) hue-rotate(62deg) brightness(92%) contrast(86%);}
			}
			&.cc-expired{
				background: #bb577714;
				border-color: #bb5777;
				color: #bb5777;
		}
			&.cc-gift{background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F4F2F2 100%);border-color:#9598A8;color: #4C5A76;
				h2{}
				small{font-size: 10px;color: #4C5A76;display: block;margin: 0px 0 10px;}
				.amazon-btn{display: flex;gap:8px;
					button{width: 55px;min-width: 32px;background: #DEDEDE;
					border-radius: 5px;font-size: 16px;color: #4C5A76;font-weight: 700;}
				}
			}
		}
}

//Cater cash

.catercash-editor{border:1px solid #d9e5f3;padding:15px;
	.MuiFormLabel-root{margin-bottom: 10px;}
}

//Refer a freind

.refer-friend{max-width: 680px;margin: 0px auto;padding: 50px 0;
	.refer-friend-inner{padding:20px;margin-top: 10px;background: #FFFFFF;box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);border-radius: 5px;
		h1{margin-bottom: 30px;color: $hc;text-align: center;}
		p{margin-bottom: 30px;font-size: 18px;line-height: 25px;text-align: center;color: #000000;}
		h6{font-size: 16px;display: flex;align-items:center;padding: 10px;margin-bottom: 20px;border-bottom: 2px solid #000;
			img{margin-right: 5px;}
		}
		b{margin-bottom:15px;font-size: 14px;letter-spacing: 0.4px;color: rgba(0, 0, 0, 0.38);display: block;}

		.login-box.create-step-form {width: 100%;padding: 0;margin: 0;border: 0;border-radius: 0;}
		.invite-btn{width: 151px;height: 42px;margin-top: 10px;background: #FD4E3B;border-radius: 6px;font-weight: 600;font-size: 14px;color: #fff;}
	}
}

//Refer a freind

//Adress customer
.address-wrap{
	.address-wt-box{padding: 20px;background-color: #fff;margin-bottom: 20px;}
	.address-map{height: 400px;width: 100%;}
	.address-title-bar{margin: 20px 0;padding-bottom: 10px;border-bottom: 1px solid #EFEFEF;
		h4 {
			img{margin-right: 10px;}
		}
		.ld-edit{font-weight: 600;}
	}
	.address-detail{
		label{font-size: 12px;padding-bottom: 4px;letter-spacing: 0.4px;color: #646777;}
		p{margin-bottom: 20px;font-size: 14px;color: #4C5A76;font-weight: 600;}
	}
	hr{height: 0.6px;background-color:#D8D8D8;opacity: 1;margin-left: -20px;margin-right: -20px;}
	button{height: 42px;font-weight: 600;font-size: 14px;text-transform: none;}
	.commonTabel {box-shadow: none;border:0;
		thead tr th{font-size: 16px};
	}
}

.access-page{min-height: calc(100vh - 203px);display: flex;align-items: center;justify-content: center;
	.access-page-inner{text-align: center;
		img{width: 150px;}
		h2{color: #b7b7b7;}
		h4{color: #b7b7b7;}
	}
}

//About
.about-banner{height: 360px;background-image: url('/public/assets/images/about-banner.jpg');background-size:cover;background-repeat:no-repeat;display:flex;align-items:center;
	.about-banner-cnt{padding: 40px;max-width: 620px;border-radius: 5px;color: #fff;position: relative;z-index: 1;
		h2{color: $w;}
		p{margin: 20px 0;font-size: 16px;line-height: 22px;letter-spacing: 0.02em;font-family: $lato;}
		button{width: 300px;height: 50px;font-size: 14px;font-weight: 600;border-radius: 6px;}
	}
}
.overlay{position: relative;
	&:after{position: absolute;top:0;bottom: 0;left: 0;right: 0;background-color: rgba(0 , 0 , 0 , 0.5);content:'';}
}
.why-cp{padding: 60px 0;
	h3{margin-bottom: 40px;text-align: center;}
	.row{}
	.why-cp-img{
		img{display: block;}
	}
	.why-cp-cnt{letter-spacing: 0.02em;
		ul{list-style: disc;padding-left: 30px;
			li{margin-bottom: 20px;font-size: 18px;line-height: 24px;color: #646777;font-family: $lato;font-weight: 400;
				&:last-child{margin-bottom: 0;}
			}
		}
	}
	&.convinced{background-color: #fff;
		h1{font-weight: 600;}
		h4{margin-bottom: 20px;}
		.why-cp-cnt ul {padding-left: 16px;
			li{
				font{color: $hc;font-weight: 700;}
			}
		}
	}
}
.action-btn{margin-top: 30px;gap:30px;display:flex;align-items:center;justify-content:center;
	button{width: 230px;height: 50px;font-weight: 600;font-size: 14px;color: #fff;border-radius: 6px;
		&.call-btn{background: #2C2D34;}
	}
}
.curate{margin-top: 60px;padding: 30px;background: #D9E5F3;border-radius: 13px;display: flex;justify-content: center;
	img{margin-right: 50px;}
	h4{margin-bottom: 20px;}
	p{font-size: 14px;line-height:20px;letter-spacing: 0.02em;color: #646777;font-family: $lato;}
	.curate-inner{max-width: 60%;}
}
.trusted-restaurants{padding: 100px 0;
	h4{margin-bottom:40px;}
}
.trustedSlider2 {margin-top: 20px;
	img{margin-left: 0;}
}
.trustedSlider{
	.slick-slide img{margin: auto;}
}

//About

// Caterer page
.about-banner.caterer-banner{height: 425px;background-image: url('/public/assets/images/caterer-bg.jpg');}
.how-it-work{padding: 40px 0 50px;background-color: rgba(217 , 217 , 217 , 0.1);
	h3{margin-bottom: 40px;text-align: center;}
	.row {--bs-gutter-x: 180px;}
	.hw-item{
		img{margin:auto ;display: block;}
		label.count{margin: 28px 0 16px;width: 38px;height: 38px;background: #FD4E3B;font-weight: 600;font-size: 18px;color: #fff;display:inline-flex ;align-items: center;justify-content: center;border-radius: 50%;}
		p{font-weight: 600;font-size: 16px;line-height: 22px;color: #555555;}
	}
}
// Caterer page

// FAQ
.about-banner.faq-banner{background-image: url('/public/assets/images/faq-banner.jpg');
	h1{color: $w;position: relative;z-index: 1;}
}
.faq{padding: 50px 0;
	.container{max-width: 900px;}
	.faq-item{
		h4{padding-bottom: 10px;margin-bottom: 5px;border-bottom: 1px solid #E8E8E8;font-weight: 700;}
		.faq-sub-item{margin-bottom: 40px;
			
		}
		.faq-sub-item-title{padding: 12px 0;font-size: 20px;font-weight: 700;border-bottom: 1px solid #E8E8E8;display: block;}
	}
	
	.accordion{
	 .accordion-item{padding: 12px 0;border-bottom: 1px solid #E8E8E8;background:none;
	   
	 }
	}

	.accordion-header{
		button{padding:10px 0;font-size: 18px;line-height: 25px;background:none!important; display: flex; justify-content: space-between; color: #404040!important;  box-shadow: none; border-radius: 0;   font-weight: 600;
		  &:after{display: none}
		  img{width: 30px;}
		  &.accordion-button{color: #2C2D34;flex-shrink:0;
			.accordion-action{margin-left: 15px;}
				.accordion-plus{display: none}
				.accordion-minus{display: block}
			&.collapsed{color:#404040;
				.accordion-plus{display: block}
				.accordion-minus{display: none}
			}
		  }
		}
		.accordion-button:not(.collapsed){color: #2C2D34!important}
	  }

	  
	.accordion-body{padding: 20px 35px 20px 0;
		p{margin-bottom: 20px;
			&:last-child{margin-bottom: 0;}
		}
	 }
}
// FAQ

// Contact
.contact{min-height: calc(100vh - 203px);
	.container-fluid{--bs-gutter-x:0}
	.row{--bs-gutter-x:0}
}
.contact-img{background-color: #C1B2B0;height: 100%;background-image: url('/public/assets/images/contact-graphics.svg');padding-top: 40px;background-repeat: no-repeat;background-size:cover;background-position-y:120px;
	h2{font-size: 60px;line-height: 80px;font-weight: 700;color: #fff;text-align: center;}
}
.contact-form-wrap{}
.contact-form{max-width: 540px;margin: auto;margin-top: 50px;
	p{margin-bottom: 30px;
		a{color: $hc;text-decoration: underline;}
	}
	h5{margin-bottom: 30px;font-weight: 700;}
	.create-step-form .allFeild{margin-bottom: 30px;}
	.submit-btn{height: 50px;width: 100%;font-weight: 600;font-size: 14px;border-radius: 6px;}
}
// Contact

.blog-wrap{min-height: calc(100vh - 70px);overflow: hidden;
	iframe{width: 100%;height: calc(100vh - 75px)}
}

.general-page{padding: 80px 0;
	h2.h2{margin-bottom: 20px;}
	h2{font-size: 20px;text-transform: uppercase;margin-bottom: 10px;}
	p{margin-bottom: 15px;}
	.h4{font-weight: 700;}
	b{font-weight: 600;}
	.main_ul{padding-left: 20px;list-style: disc;
		li{margin-bottom: 15px;}
	}
	.p_left {margin-left: 30px;}
	.terms_of_use_p {
		font-weight: 600;
		font-size: 17px;
	}
}

.rdw-link-modal{min-height: 205px !important;height: auto !important;}


.MuiSkeleton-root{background-color: rgba(0,0,0,0.05) !important;}

.circle-loader{outline: 0;
	&:focus-visible{outline: 0;}
}

.fade{animation: fadein 1s;}
@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

///////////////////////////////---Frontend---/////////////////////////////


@media (max-width: 575.98px) {
	body{padding-top: 55px;font-size: 12px;line-height: 16px;}
	.h1{font-size: 26px;line-height: 32px;}
	.h2{font-size: 22px;line-height: 28px;}
	.h3{font-size: 20px;line-height: 24px;}
	.h4{font-size: 16px;line-height: 20px;}
	.h5{font-size: 14px;line-height: 18px;}
	.h6{font-size: 12px;}

	.sideMenu {width: 50px;padding: 0;display:none;
		.sideMenuItem {height: 40px;
			img{margin: auto;margin-right: 0;}
			.sideMenuItemText{display: none;}
		}
		
	}
	header{height: 55px;
		.logo{
			img{height: 35px;}
		}
		.header-menu{
			ul{display: none;}
			.signin-btn {font-size: 10px;padding: 4px 8px;}
			.profile-avatar{width: 25px;height: 25px;font-size: 12px;}
			.header-user-name {font-size: 12px;margin-left: 5px;}
		}
	 &.LoggedInHeader {
		.container-fluid{padding-left: 12px;}
		.loggedLogo{width: 100px;
			img{width: 80px;}
		}
	 }
	}

	.m-menu-drawer{
		.MuiDrawer-paper {width:200px;}
		.m-menu-close{margin-top: 5px;margin-left: 8px;
			img{width: 15px;}
		}
		ul{margin-top: 10px;
			li{border-bottom: 1px solid #d8d8d8;margin: 0 10px;
				a{padding:14px 10px;display: block;color: $b;}
				&:hover, &:focus{border-color: $hc;
					a{color: $hc;}
				}
			}
		}
		&.m-menu-drawer-myaccount{
			.sideMenu {width: 200px;margin-top: 10px;padding: 0;display:block;position: inherit;box-shadow:none;
				.sideMenuItem {height: auto;margin:0 10px;
					img{margin: 0;margin-right: 6px;width: 15px;}
					.MuiTypography-root {font-size: 12px;}
					.sideMenuItemText{display: block;}
					&.Mui-selected {border-bottom: 1px solid #FD4E3B;}
				}
				ul{margin-top: 0;
					li{margin: 0;border-bottom: 0;}
				}
				
			}
			
		}
	}

	.contentWrap {padding-left: 0px;min-height: calc(100vh - 55px);
		.contentWrapInner{padding: 20px 20px 30px 15px;}
	}
	.width-175{min-width: 175px;}

	.wrap-height {height: calc(100vh - 55px);}

	.login-box.create-step-form{width:100%;padding: 20px;margin: auto 30px;
		.h4{margin-bottom: 20px;padding-bottom: 15px;}
		.login-btn.MuiButton-root {height: 40px;margin-top: 20px;font-size: 12px;}
	}
	.createOneLink{text-align: center;}

	// Create
	.create-form-left{display: none;}
	.create-step-wrap{padding: 20px;padding-top: 0;}
	.create-stepper{background-color: #f5f5f5;padding:10px 20px;margin:0 -20px;
		
		.MuiStep-root{padding:0;
			&:first-child{padding-left: 0;}
		}
		.MuiStepLabel-label{font-size: 12px;display:none;
			&.Mui-active{color: $b;display: block;font-size:18px;font-weight: 700;}
		}
		.MuiStepLabel-iconContainer{display:none;font-size:18px;
			svg{}
		}
		.MuiStepIcon-root{color: #646777;
			&.Mui-completed{color:#FAA095;
				&.MuiStepLabel-label {}
			}
			&.Mui-active{color:#FAA095;}
		}
		.MuiStepLabel-label.Mui-completed{color: $hc;font-weight: 600;}
	}
	.textBoxTopMargin{margin-top: 0;}
	.create-step-form {padding-top: 15px;
		.MuiFormControlLabel-root{padding-bottom: 15px;
			.MuiFormControlLabel-label{font-size: 12px}
		
		}
	.allFeild{margin-bottom: 20px;}
	.textField input{font-size: 14px;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			font-size: 12px;
		  }
		&::-moz-placeholder { /* Firefox 19+ */
			font-size: 12px;
		  }
		&:-ms-input-placeholder { /* IE 10+ */
			font-size: 12px;
		  }
		&:-moz-placeholder { /* Firefox 18- */
			font-size: 12px;
		  }
	}
	}
	.textBox .error-msg {font-size: 8px;bottom: 6px;margin-left: 2px;}
	.form-space {
		padding-bottom: 20px;
	}
	.create-account-condition{margin-top: 0;}

	.createStepBtn {justify-content: space-between;
		.MuiButton-root {width: 165px;height: 35px;font-size: 12px;
			&.cancelBtn {width: 90px;margin-right: 15px;}
		}
		.btnArrow {width: 15px;}
	}

	.more-delivery-address-email{margin-top: 5px;
		&.remove {
			margin-top: -20px;
			font-size: 10px;
		}
	}

	.crop-outer img {
		max-width: 100% !important;
		max-height: 380px !important;
	}

	.weekly-hrs-outer {margin-bottom: 18px;padding: 15px 10px;
		.per-day-time {
			.MuiFormControl-root {min-width: 100px;width: 100px;}
			.MuiSelect-select {padding: 10px;}
			.MuiFormLabel-root {font-size: 12px;line-height: 12px;
				&.Mui-focused.MuiInputLabel-shrink{line-height: 18px;}
			}
			.time-separator {margin: 0 5px;
				width: 8px;
			}
			.add-hours {margin-left: 5px;font-size: 11px;
				img{width: 15px;height: 15px;}
			}
		}
	}

	.hours-dropdown .MuiSelect-select{font-size: 12px;
		svg {width: 15px;height: 15px;margin-right: 3px;}
	}

	//Create

	//Login mobile
	.login-box-frontend{width: 100%;margin:auto;
		.h4{font-size: 16px;}
		.login-box.create-step-form{padding: 30px 20px;
			.textField {
				input{font-size: 14px;}		
			}
			.login-separator{margin: 18px 0 12px;
				label{font-size: 14px;
					&:after, &:before{}
					&::before{left: -20px;}
					&::after{right: -20px;}
	
				}
			}
			.login-create{
				h4{font-size: 16px;}
				
			}
			.new-account-btn{margin-top: 15px;gap:12px;
				a{}
				button{height: 40px;font-size: 12px;}
			}
		}
	}
	//Login

	//Login Customer
	.customer-login{width: auto;margin-top: 20px;
		.customer-login-title{height: 70px;padding: 0 20px;background: #F5F5F5;border-radius: 5px 5px 0px 0px;display: flex;flex-direction: column;align-items: flex-start;justify-content:center;
			h5{font-weight: 700;margin-bottom: 0;}
			small{font-size: 10px;line-height: 12px;display: block;}
		}
		.login-box.create-step-form{width: auto;padding: 20px;margin: auto;}
	}
	//Login Customer

	.modal-close{width: 15px;}
	.contentWrap-customer-profile{padding-top: 10px;}
	.customer-profile-wrap{padding-top: 0;padding-bottom: 30px;}
	.customer-profile {padding: 20px;margin :20px 10px !important;
		.customer-profile-title {margin-bottom: 15px;padding-bottom: 10px;
			h4 img {margin-right: 5px;max-width: 20px;}
		}
	}
	.back-link-order {margin-bottom: 12px;font-size: 12px;}
	.address-wrap {
		.address-wt-box{padding: 15px;}
		.address-map {height: 250px;}
		.address-title-bar {margin: 10px 0;padding-bottom: 10px;
			h4{margin-bottom: 0;}
		}
	}
	.address-wrap .commonTabel thead tr th{font-size: 14px;	}


	//Catercash

	.catercash-overview{gap:10px;padding:00px;margin-top: 10px;display: flex;flex-wrap: wrap;background-color: #F5F6F8;margin-bottom: 25px;
		.cc-item{height: 135px;width: 48%;flex-grow: inherit;flex-basis: auto;padding: 10px;line-height:16px;
			h2{font-size: 20px;line-height: 30px;margin-bottom: 5px;}
			img{width: 25px;}
			label{font-size: 12px;margin-left: 0px;}
			
			&.cc-gift{
				h2{}
				img{width: 70%;}
				small{font-size: 10px;margin: 0px 0 5px;}
				.amazon-btn{gap:4px;justify-content:center;
					button{width: 28%;min-width: 28%;font-size: 9px;color: #4C5A76;font-weight: 700;}
				}
			}
		}
	}
	//Catercash
	.status-btn.MuiButtonBase-root {width: 80px;height: 28px;border-radius: 6px;font-size: 11px;}
	.table-pagenation{
		 .rowCount{
			.MuiSelect-select {padding: 8px 22px 8px 8px !important;
				
			}
			.MuiSvgIcon-root{right: 0px;}
		 }
		 &.inside{
			.rowCount{left: 0;}
		 }
		}
	// Location details

	.location-details-tile{padding: 15px;
		.ld-titles {margin-bottom: 10px;
			h4 img {margin-right: 5px;max-width: 20px;}
			
		}
		
	}

	.ld-edit {font-size: 10px;
		img{width: 12px;margin-right: 2px;}
	}

	.ld-contact{display: block;
		.ld-contact-img{flex-shrink: 0;position: relative;
			img{object-fit: cover;width: 100%;height: 150px;}
			
		}
		.ld-contact-details{margin-left:0px;display: block;margin-top: 10px;
			.ld-contact-details-item{width: 100%;margin-bottom: 10px;
				&:last-child{margin-bottom: 0;}
			}
			label{font-size: 10px;}
			span{font-size: 12px;}
		}
	}
	.cater-cash-tag{width: 50px;height: 50px;right: 10px;top: 10px;border-width: 1px;
		b{font-size: 12px;line-height: 16px;}
		label{font-size: 8px;line-height: 10px;}
	}
	.ld-business-detail{
		.ld-business-detail-item{margin-bottom: 10px;}
		 label{font-size: 10px;}
		 b{font-size: 12px;}
	}

	.ld-others ul li {margin-bottom: 20px;font-size: 12px;}
	.ld-slider-outer{}
	.ld-slider{display: flex;align-items:center;
		h5{font-size: 14px;color: #3E3C37;margin-right: 12px;font-weight: 600;}
		h6{font-size: 12px;font-weight: 600;margin-left: 12px;}
	}


	.common-drawer{
		.MuiDrawer-paper {width: 100%;}
	}

	.commonTabel{
		thead{
			tr{
				th{padding: 14px 10px;font-size: 12px}
			}
		}
		tbody{
			tr{
				th,td{padding: 12px 10px;font-size: 12px}
			}
		}
	}


	.menu-head-btns button {height: 30px;margin-right: 10px;}
	.menu-sort{width: 90% !important;}


	///////////////////////////////---Frontend---/////////////////////////////
	
	
	.pageWrap{
		h2{margin-bottom: 25px;color: #646777;}
	}
	.banner{height: auto;background-image: url('/public/assets/images/search-bg.jpg');
		h1{font-size: 20px;line-height: 28px;}
			.banner-subtext{font-size: 12px;}
	}
	.banner-content{padding: 35px; margin: 15px 0;}
	.banner-search-point{margin-top: 10px;padding: 10px;border-radius: 10px;}
	.logo-carousel {
		padding: 50px 0 !important;
	}
	.banner-search{padding: 0px;width: 100%;height: auto;margin: 0;display:block;
		.MuiFormControl-root {width: 100%;
			a{font-size: 10px;}
		}
		.MuiOutlinedInput-root {padding: 10px !important;}
		fieldset{border: 1px solid #D9E5F3;border-radius: 6px;}
		.mobile-search{height: 40px;margin: 10px 0;}
	}
	.banner-points{    justify-content: space-evenly;
		.banner-points-item{margin:0 5px;display:block;text-align:center;
			img{max-height: 15px;}
			.banner-points-item-text{padding:0;margin-left: 0px;padding-bottom: 8px;margin-bottom: 5px;
				h5{font-size: 10px;}
			}
		}
	}
	.list-homepage {
		padding: 10px 20px;
		font-size: 14px;

	}

	.home-cater-near{padding: 60px 0;
		.home-cater-near-title{display: flex;align-items:center;justify-content:space-between;
			label{font-size: 12px;font-weight: 700;color:$b;display: inline-block;margin-right: 10px;}
			button{padding: 3px;}
		}
	}

	.homeCaterSlider{
		.slick-slider {overflow: hidden;}
		.slick-list {margin: 0 -15px;}
		.cater-list-item{ padding: 0 15px;}
	}

	.testimonialSlider{overflow: hidden;
		.slick-list {margin: 0 -20px;}
		.testimonial-item{ padding: 0 20px;}
	}

	.testimonials{padding-bottom: 40px;
		.testimonial-item{margin-bottom:0;}
		.testimonial-item-text{
			.pointer-mark{bottom:-16px;left: 40px;position: absolute;}	
		}
		.testimonial-author{margin-top: 20px;display:flex;align-items:center;justify-content:center;
			.testimonial-author-img{width: 50px;height:50px;margin-right: 10px;border-radius: 72px;border:2px solid $hc;object-fit: cover;}
			span{
				b{font-size: 18px;line-height: 22px;color: #404040;font-weight: 700;display: block;}
				label{font-size: 14px;line-height: 17px;color: #404040;}
			}
			
		}
	}

	.home-works{padding: 30px 15px;
		h2{margin-bottom: 30px;color:$w;}
		.home-works-item{margin-bottom: 20px;}
	}

	footer{padding: 20px 0;
		.container{display:block;}
		.logo-social-link{margin-bottom: 18px;display: flex;align-items:center;justify-content:space-between;
			.footer-logo{height: 25px;padding-right: 0px;margin-right: 0px;border-right: 0px;}
			ul{
				li{margin-right: 15px;
					a{
						img{width: 20px;height: 20px;}
					}
				}
			}
		}
		.copy-terms{font-size: 12px;text-align:center;
			span{
				a{color: $hc;padding-right: 5px;margin-right: 5px;border-right: 1px solid $hc;
					&:last-child{border-right: 0;}
					&:hover{text-decoration: underline;}
				}
			}

		}
	}

	.menu-back {font-size: 12px;
		img{height: 10px;margin-top: 1px;}
	}
	.listing-filter-primary{padding: 10px 0px;margin-bottom: 10px;
		.container-fluid{display:block;}
		.lfp-label{font-size: 10px;color: #2C2D34;display: block;margin-bottom: 2px;font-weight: 500;;}
		.lfp-delivery-address{min-width:100%;margin: 10px 0;
			input{padding: 2px 5px !important;font-size: 12px;}
			.lfp-zip {
				width: 100px;
				input { padding: 10px 16px !important;}
			}
		}
		.lfp-date{margin: 0 10px 0 0 ;
			input{width: 220px;height: 40px;font-size: 12px;color:#2C2D34;}	
		}
		.lfp-head-count{width: 150px;
			.MuiSelect-select{padding:13.5px 9px;font-size:12px;color:#2C2D34;}
			input{padding: 8.5px 4px;color:#2C2D34;}
			p{color:#2C2D34;font-weight: 600;}
			&.lfp-order-type{width: 200px;margin-right: 30px;}
		}
	}

	
	.detail-title-bar {padding-bottom: 0;margin-bottom: 10px;
		.detail-main-points{flex-wrap: wrap;
			li{margin-bottom: 10px;margin-right: 10px;font-size: 12px;
				&.dmp-bg{font-size: 12px;}
			}
		}
	}
	.filter-mobile-title{padding: 10px;margin-bottom: 10px;display: flex;align-items:center;justify-content:space-between;border-bottom: 1px solid #F2F2F2;
		h5{font-size: 16px;margin-left: 6px;font-weight: 600;}
		button{
			img{width: 18px;}
		}
	}

	.listing-filter-secondary{padding:0px;
		&.listing-filter-secondary-mob{padding:15px 10px;}
		h3{font-size: 16px;}
		.cater-list-filter-btn{padding: 5px;
			img{height: 15px;}
		}
		
		.lfs-item{margin-right: 0;margin-bottom: 20px;}
		.lfs-search{width:100%;margin-right: 0;padding-right: 0;border-right: 0;
			.MuiInputBase-root.MuiOutlinedInput-root {height: 48px;}
		}
		.lfs-deitary{width: 100%;}
		.lfs-option{width: 100%;
			.MuiSelect-select{padding-left: 30px !important;background-image: url('/public/assets/images/Options.svg');background-repeat: no-repeat;background-position: left 10px center;}
		}
		.lfs-cuisnes{width: 100%;
			.MuiSelect-select{padding-left: 30px !important;background-image: url('/public/assets/images/cusines.svg');background-repeat: no-repeat;background-position: left 10px center;}
		}
		.lfs-sort{width: 100%;border-left: 0px;padding-left: 0px;}

		.MuiSelect-select {padding:12.5px 8px 12.5px 8px !important;font-size:12px;
			img{margin-right: 8px;}
		}
		fieldset{border-radius: 6px;}
		.MuiInputAdornment-root {flex-shrink: 0;}
		.lfs-clear-btn{height: 32px;font-size: 16px;font-weight: 700;font-family: $lato;}

		.lfs-dropdown-arrow{}
		}

		.lfs-menuitem.MuiButtonBase-root {padding:4px 8px;font-size:12px !important;
			img{margin-right: 8px;}
	}

	.cater-list {height: calc(100vh - 230px);padding: 8px 0 10px;
		.cater-list-item-outer{margin-bottom: 20px;}
	}
	.cater-list-item{
		.cater-list-item-img{height: 220px;width: 100%;position: relative;
			img{height: 100%;width: 100%;object-fit: cover;border-radius: 5px;}
			.cater-cash-tag{top: 20px;right: 20px;}
		}
		.cater-list-item-cnt{padding-top: 10px;
			h4{font-size: 16px;
				label{margin-left: 6px;display:flex;align-items:center;
					img{margin-right: 5px;}
					span{font-size: 12px;color:#646777;margin-top: 2px;
						small{font-size: 10px;color: #B8BEC1;}
					}
				}
			}
			h6{margin: 6px 0 4px;font-size: 12px;text-transform: capitalize;color: #2C2D34;}
			.cater-list-order{font-size: 12px;color: #646777;display:flex;align-items:center;
				span{display:flex;align-items:center;padding-right: 8px;margin-right: 8px;border-right: 1px solid #D9E5F3;
					img{margin-right: 6px;}
					&:last-child{border-right: 0;}
				}
			}
		}
	}

	.table-pagenation {padding: 10px 20px 10px;}

	//.caterer-menu-list-wrap{padding-bottom: 95px;}
	.padding-0{padding: 0;}
	.menu-category-listing{margin-bottom: 0;}
	.menu-category{border-radius: 0px;padding:0 10px;
		 .menu-category-item {padding: 15px 10px;margin-right: 5px;}
	}

	.slick-next.menu-category-arrow {right: 2px;width: 15px;height: 46px;
		img {width: 11px;}
	}
	.slick-prev.menu-category-arrow {left: 2px;width: 15px;height: 46px;
		img {width:11px;}
	}
	
	.confirm-place-btn.MuiButtonBase-root {height: 45px;width: 100%;font-size: 14px;
		img{width: 20px;}
	}
	.add-card-btn.MuiButtonBase-root{display: flex;margin-left: auto;margin-right: auto;}

	.menu-category{margin-bottom: 20px;}
	.menu-category-listing {
		.menu-category-listing-col{margin-bottom: 12px;
			&::before{display: none;}
			&::after{display: none;}
		}
		.menu-category-listing-item{flex-direction: row-reverse;padding-bottom: 12px;border-bottom: 0.6px solid #D8D8D8;

			h3{font-size: 16px;margin-bottom: 10px;}
			p{font-size: 10px;line-height: 16px;}
			.menu-category-listing-item-img{margin-left: 0;margin-right: 15px;
				img{width: 120px;height: 130px;}
			}
			.menu-category-listing-item-details{width: 100%;}
			&.skeleton{
				h3 .MuiSkeleton-root{width: 250px;height: 16px;}
				.dietry-label .MuiSkeleton-root{width: 150px;height: 16px;}
				.price-time .MuiSkeleton-root{width: 200px;height: 16px;}
				p .MuiSkeleton-root{width: 320px;height: 14px;}
	
			}
		}
		.price-time{padding: 5px 0;font-size: 12px;}
	}

	.cater-detail-box h4{font-size: 18px;line-height: 24px;}
	.cater-detail-box.detail-ordering-hours{padding-bottom: 20px;margin-bottom: 0px;}
	.detail-tagline .detail-tagline-item {
		h5 {font-size: 16px;}
		p{font-size: 14px;line-height: 18px;}
	}

	.cater-detail-timing{
		li{width:100%;padding: 15px 0;font-size: 14px;
			
			&:nth-child(7),&:nth-child(8){border-bottom: 0 solid #000;}
			&:nth-child(3n+2){position: relative;margin:0 0px;
				&::before, &::after{display: none;}}
		}
	}
	.cart-view{margin: 30px 0;
		.cart-view-list .cart-view-list-item .cart-count-name h6{font-size: 12px;}
		.review-cart-btn {height: 45px;font-size: 14px;
			img{width: 20px;}
		}
	}
	.cart-total{font-size: 14px;}
	.common-view-box h6{font-size: 14px;}
	.review-order-price .other-charges .other-charges-item b{font-size: 14px;}
	.review-add-update-btn.MuiButtonBase-root{margin:auto;display: flex;}
	.review-order-price .other-amount a{margin-top: 8px;}
	.add-promo-code{
		.promo-box{margin-top: 10px;}
	}
	

	//Caterer Menu edit screen

	.dietry-label {font-size: 12px;margin-left: 4px;
		img{width: 10px;}
	}

	.caterer-menu-modal{display:flex;align-items: center; justify-content: center;
		.caterer-menu-details{max-height: 100vh;height: 100vh;width:100%;border-radius:0px;display:flex;flex-direction:column;
			.caterer-menu-header{height: 70px;
				h5{margin-bottom: 0;font-weight: 700;text-transform: capitalize;}
				small{margin-top: 2px;font-size: 12px;line-height: 16px;display:block;}
				.dietry-label{margin-left: 0;font-size: 10px;margin-top: 3px;}
			}
			.caterer-menu-content{max-height: calc(100vh - 230px);padding:20px;font-size: 12px;line-height: 18px;color:#3E3C37;overflow: auto;}
			.caterer-menu-footer {height: 122px;padding:12px 20px;display: block;border-radius:0;margin-top: auto;position:absolute;bottom:0;width:100%;z-index: 10;background-color:#fff;
				.menu-qty{justify-content: space-between;}
				.cart-price-btn{width: 100%;margin-top: 14px;height: 38px;}
			}
		}
	}


	.mob-cart-button-area{padding: 20px;position: fixed;left: 0;right: 0;bottom: 0;background: #FFFFFF;box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);width: 100%;z-index: 10;
		button{height: 54px;font-weight: 700;font-size: 16px;color:#fff;display: flex;align-items: center;justify-content: space-between;border-radius: 4px;width: 100%;}

	}

	//.common-dialog-box .MuiDialogActions-root button{font-size: 14px;height: 35px;}
	.review-star-item span{font-size: 17px !important;}
	.common-dialog-box.review-box {
		.review-star-item span{font-size: 17px !important;}
		.review-textbox {margin-top: 20px;margin-bottom: 0;
			.MuiFormLabel-root {font-size: 14px;}
		}
		
	}

	
	// Order confirm page start
	.order-confirm-screen{height: calc(100vh - 158px);overflow: auto;
		.row{height: auto !important;}
		.ocs-img{padding: 20px 0;
			img{width: 50%;}
			h5{margin-top: 10px;}
		}
		.ocs-text{width: 100%;padding: 20px 15px;text-align:center;
			h1{margin-bottom: 15px;font-size: 20px;}
			p{margin-bottom: 10px;
				b{display: block;}
			}
			.saveBtn{min-width: 120px;font-size: 13px;margin-top: 15px !important;}
		}
		.valign{height: auto;}
	}
	// Order confirm page end



	//About
	.about-banner{height: 360px;
		.about-banner-cnt{padding: 20px;
			h2{color: $hc;}
			p{margin: 10px 0 20px;font-size: 14px;line-height: 20px;}
			button{width: 200px;height: 40px;font-size: 14px;font-weight: 600;border-radius: 6px;}
		}
	}
	.why-cp{padding: 30px 0;
		h3{margin-bottom: 20px;}
		
		.why-cp-cnt{
			ul{padding-left: 16px;margin-top: 20px;
				li{margin-bottom: 15px;}
			}
		}
		&.convinced{background-color: #fff;
			h1{font-weight: 600;}
			h4{margin-bottom: 20px;}
			.why-cp-cnt ul {padding-left: 16px;
				li{
					font{color: $hc;font-weight: 700;}
				}
			}
		}
	}
	.action-btn{margin: 20px 0;gap:20px;
		button{width: 150px;height: 35px;}
	}
	.curate{margin-top: 30px;padding:30px 20px;
		img{margin: auto;}
		h4{margin: 10px 0;}
		p{}
		.curate-inner{max-width: 80%;text-align: center;}
	}
	.trusted-restaurants{padding: 30px 0;
		h4{margin-bottom:20px;}
	}
	.trustedSlider2 {margin-top: 20px;
		img{margin-left: 0;}
	}

	//About

	// Caterer page
	.about-banner.caterer-banner{height: 350px;background-image: url('/public/assets/images/caterer-bg.jpg');}
	.how-it-work{padding: 30px 0 40px;
		h3{margin-bottom: 30px;}
		.row {--bs-gutter-x: auto;}
		.hw-item{
			img{}
			label.count{margin: 20px 0 10px;width: 30px;height: 30px;font-size: 14px;}
			p{font-size: 14px;}
		}
	}
	// Caterer page


	//FAQ

	.about-banner.faq-banner{height: 200px;}
	
	.faq {padding: 30px 0;
		.faq-item {
			.faq-sub-item {margin-bottom: 20px;}
			.faq-sub-item-title {padding: 10px 0;font-size: 14px;}
		}
		.accordion-header button {font-size: 14px;line-height: 18px;
			span{flex-shrink: 0;}
			img{width: 15px;}
		}
		.accordion .accordion-item {padding: 8px 0;}
		.accordion-body {padding: 10px 25px 10px 0;}
	}
	//FAQ


	// Contact
	.contact{min-height: auto;
		.container-fluid{--bs-gutter-x:15px}
		.row{--bs-gutter-x:0}
	}
	.contact-img{background: none;padding-top: 30px;
		h2{font-size: 30px;line-height: 35px;font-weight: 700;color: #000;text-align: left;}
	}
	.contact-form-wrap{}
	.contact-form{max-width: 540px;margin: auto;margin: 20px 0 30px;
		p{margin-bottom: 20px;}
		h5{margin-bottom: 20px;font-weight: 700;}
		.login-box.create-step-form{width: 100%;margin:0;padding:0;
			.allFeild{margin-bottom: 30px;}
		}
		.submit-btn{height: 50px;}
	}
	// Contact

	.contentWrap .location-head .location-head-title {padding: 20px 10px;
		button{padding: 4px 10px;font-size: 12px;
			img{width: 13px;}
		}
	}

	

	.cater-tab .MuiTabs-root button {padding: 8px 15px;font-size: 12px;}
	

	//Refer a freind
	.refer-friend-contentWrap{background-color: #fff;}
	.cater-tab .MuiTabs-root button.refer-friend-tab{margin: auto;color: #000;font-weight: 600;}
	.refer-friend{max-width: 680px;margin: 50px auto;
		.refer-friend-inner{padding:00px;margin-top: 10px;box-shadow:none; 
			h1{font-size: 20px;line-height: 28px;}
			p{margin-bottom: 20px;font-size: 12px;line-height: 18px;}
			h6{font-size: 16px;padding: 10px;margin-bottom: 20px;border-bottom: 2px solid #000;
				img{margin-right: 5px;}
			}
			b{margin-bottom:15px;font-size: 12px;}

			.login-box.create-step-form {}
			.invite-btn{width: 80%;height: 40px;margin:0 auto 40px;display: flex;}
		}
	}

	//Refer a freind
	.common-drawer .drawer-head {padding: 15px 15px;
		h4 img {margin-right: 8px;max-width: 20px;}
	}	
	.cancel-save-btn {display: flex;align-items:center;justify-content:space-between;
		button{width: 130px;height: 40px;}
	}

	.order-view-title h5 span{padding-left: 5px;margin-left: 5px;}
	.order-view {padding: 20px 12px;
		.order-view-address{
			&.order-view-address-mob{margin-bottom: 20px;}
			 .MuiAvatar-root {width: 50px;height: 50px;margin-right: 10px;font-size: 15px;}
		}
		.order-view-address-wrap {padding-bottom: 20px;
			h6 {font-size: 14px;padding-bottom: 5px;}
		}
		.review-order-item{
			h6 {font-size: 14px;}
		}
	}

	.common-dialog-box {
		.dialog-box-title {padding-top: 0;padding-bottom: 10px;margin-bottom: 4px;}
		.dialog-box-text {width: 100%;margin: 5px auto;}
		.dialog-box-text h5 {font-size: 12px;}
		.MuiDialogActions-root button {font-size: 12px;height: 30px;
			//&.headcount-btn{width: 100% !important;}
		}
		&.headcount-dialog-box{
			.MuiDialogActions-root{display: block;
				button{width: 100% !important;
					&.dailog-cancel-btn {margin-bottom: 10px !important;}
					&.headcount-btn{margin-left: 0;}
				}
			}
		}
	}
	
}


@media (max-width: 300px) {
	.login-box.create-step-form{padding: 10px;margin: auto 10px;
		.h4{margin-bottom: 20px;padding-bottom: 15px;}
		.login-btn.MuiButton-root {height: 40px;margin-top: 20px;font-size: 12px;}
	}
}

@media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {

	body{padding-top: 55px;font-size: 12px;line-height: 16px;}
	.h1{font-size: 26px;line-height: 32px;}
	.h2{font-size: 22px;line-height: 28px;}
	.h3{font-size: 20px;line-height: 24px;}
	.h4{font-size: 16px;line-height: 20px;}
	.h5{font-size: 14px;line-height: 18px;}
	.h6{font-size: 12px;}

	.wrap-height {height: calc(100vh - 55px);}
	.create-form-right{overflow: auto;padding: 30px 0 !important;}

	header{height: 55px;
		.logo{
			img{height: 35px;}
		}
		.header-menu{
			ul{
				li{
					a{}
				}
			}
			.signin-btn {font-size: 10px;padding: 4px 8px;}
		}
	}

	.login-box.create-step-form{width:300px;padding: 20px;
		.h4{margin-bottom: 20px;padding-bottom: 15px;}
		.login-btn.MuiButton-root {height: 40px;margin-top: 20px;font-size: 12px;}
	}

}

@media (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {

	.h1{font-size: 32px;line-height: 40px;font-weight: 700;}
	.h2{font-size: 28px;line-height: 36px;font-weight: 700;}
	.h3{font-size: 24px;line-height: 32px;font-weight: 600;}
	.h4{font-size: 20px;line-height: 26px;font-weight: 600;}
	.h5{font-size: 18px;line-height: 24px;font-weight: 600;}
	.h6{font-size: 14px;font-weight: 600;}

	header .header-menu ul{display: none;}
	.hamburger-icon{margin-left: 7px;}

	.sideMenu{display: none;}
	.contentWrap {padding-left: 0px;
		.contentWrapInner{padding: 20px;}
	}
	.m-menu-drawer{
		.MuiDrawer-paper {width:200px;}
		.m-menu-close{margin-top: 5px;margin-left: 8px;
			img{width: 15px;}
		}
		ul{margin-top: 10px;
			li{border-bottom: 1px solid #d8d8d8;margin: 0 10px;
				a{padding:14px 10px;display: block;color: $b;}
				&:hover, &:focus{border-color: $hc;
					a{color: $hc;}
				}
			}
		}
		&.m-menu-drawer-myaccount{
			.sideMenu {width: 200px;margin-top: 10px;padding: 0;display:block;position: inherit;box-shadow:none;
				.sideMenuItem {height: auto;margin:0 10px;
					img{margin: 0;margin-right: 6px;width: 15px;}
					.MuiTypography-root {font-size: 12px;}
					.sideMenuItemText{display: block;}
					&.Mui-selected {border-bottom: 1px solid #FD4E3B;}
				}
				ul{margin-top: 0;
					li{margin: 0;border-bottom: 0;}
				}
				
			}
			
		}
	}

	header .header-menu ul li a {padding: 5px 7px;font-size: 13px;}
	header .logo img{height: 45px;}
	.banner-points .banner-points-item {margin-right: 15px;}
	.testimonialSlider .testimonial-item{padding: 0 15px;}
	.testimonials {padding-bottom: 30px;
		.testimonial-item-text{padding: 20px;}
	}
	.home-works {padding: 40px 0;
		.row {--bs-gutter-x:10px;}
		.home-works-item {
			.home-works-item-img{height: 200px;}
			.home-works-item-cnt{padding: 15px 5px;
				label{padding: 5px 0 5px;}
			}
		}
	}

	footer{padding: 20px 0;
		.logo-social-link {
			.footer-logo{height:40px;padding-right: 15px;margin-right: 15px;}
			ul{
				li{margin-right: 5px;
					a{
						img{width: 24px;}
					}
				}
			}
		}
		 .copy-terms {font-size: 12px;}
	}
	
	//About
	.about-banner{height: 280px;
		.about-banner-cnt{padding: 25px;
			button{width: 250px;height: 40px;}
		}
		&.caterer-banner{height: 280px;}
	}
	.why-cp{padding: 40px 0;
		h3{margin-bottom: 30px;}
		.row{align-items: self-start !important;}
		
		.why-cp-cnt{
			ul{list-style: disc;padding-left: 20px;
				li{margin-bottom: 15px;}
			}
		}
		&.convinced{
			h4{margin-bottom: 20px;}
			.why-cp-cnt ul {
				
			}
		}
	}
	.action-btn{margin-top: 20px;gap:20px;
		button{width: 150px;height: 40px;font-weight: 600;font-size: 14px;color: #fff;border-radius: 6px;
			&.call-btn{background: #2C2D34;}
		}
	}
	.curate{margin-top: 30px;padding: 30px;
		
		
		.curate-inner{max-width: 80%;}
	}
	.trusted-restaurants{padding: 30px 0;
		h4{margin-bottom:20px;}
	}
	.trustedSlider2 {margin-top: 20px;
		img{margin-left: 0;}
	}

	//About

	.how-it-work{
		 .row {--bs-gutter-x:50px;}
		 .hw-item {
			img{height: 135px;}
			label.count {margin: 20px 0 10px;}
		 }
	}

	// Contact
	.contact{min-height:auto;
		.container-fluid{--bs-gutter-x:0}
		.row{--bs-gutter-x:0}
	}
	.contact-img{background-color: #C1B2B0;height: 350px;padding-top: 30px;background-position-y:80px;
		h2{font-size: 30px;line-height: 40px;}
	}
	.contact-form-wrap{}
	.contact-form{margin-bottom: 30px;
		p{margin-bottom: 20px;}
		h5{margin-bottom: 20px;}
		.create-step-form .allFeild{margin-bottom: 20px;}
		.submit-btn{height: 50px;width: 100%;}
	}
	// Contact

	.faq{padding: 40px 20px;}

	.general-page {padding: 40px 0;}
	

	//Caterlist
	.menu-back{font-size: 14px;
		img{height: 10px;}
	}
	.listing-filter-primary {
		.lfp-delivery-address {min-width: 250px;}
		.lfp-date input {width: 238px;}
		.lfp-date {margin: 0 10px;}
		.lfp-head-count{width: 110px;}
	}
		.filter-mobile-title{padding: 10px;margin-bottom: 10px;display: flex;align-items:center;justify-content:space-between;border-bottom: 1px solid #F2F2F2;
			h5{font-size: 20px;margin-left: 6px;font-weight: 600;}
			button{
				img{width: 18px;}
			}
		}
	.listing-filter-secondary{padding:0px;
		&.listing-filter-secondary-mob{padding:15px 10px;}
		h3{font-size: 16px;}
		.cater-list-filter-btn{padding: 5px;
			img{height: 15px;}
		}
		.lfs-item{margin-right: 0;margin-bottom: 20px;}
		.lfs-search{width:100%;margin-right: 0;padding-right: 0;border-right: 0;
			.MuiInputBase-root.MuiOutlinedInput-root {height: 48px;}
		}
		.lfs-deitary{width: 100%;}
		.lfs-option{width: 100%;
			.MuiSelect-select{padding-left: 30px !important;background-image: url('/public/assets/images/Options.svg');background-repeat: no-repeat;background-position: left 10px center;}
		}
		.lfs-cuisnes{width: 100%;
			.MuiSelect-select{padding-left: 30px !important;background-image: url('/public/assets/images/cusines.svg');background-repeat: no-repeat;background-position: left 10px center;}
		}
		.lfs-sort{width: 100%;border-left: 0px;padding-left: 0px;}

		.MuiSelect-select {padding:12.5px 8px 12.5px 8px !important;font-size:12px;
			img{margin-right: 8px;}
		}
		fieldset{border-radius: 6px;}
		.MuiInputAdornment-root {flex-shrink: 0;}
		.lfs-clear-btn{height: 32px;font-size: 16px;font-weight: 700;font-family: $lato;}

		.lfs-dropdown-arrow{}
		}

		.lfs-menuitem.MuiButtonBase-root {padding:4px 8px;font-size:12px !important;
			img{margin-right: 8px;}
		}
	.cater-list {height: calc(100vh - 198px);
		.cater-list-item-outer{margin-bottom: 20px;}
	}
	.cater-list-item {
		.cater-list-item-img{height: 200px;}
		.cater-list-item-cnt{padding-top: 10px;
			h4{font-size: 16px;}
			h6 {margin: 4px 0 2px;}
			.cater-list-order {font-size: 10px;
				span{padding-right: 4px;margin-right: 4px;line-height:initial;
					img{margin-right: 2px;}
				}
			}
		}
	}
	.detail-main-points{flex-wrap: wrap;}
	.detail-title-bar {padding-bottom: 0px;margin-bottom: 15px;
		.detail-main-points li{margin-right: 10px;margin-bottom: 15px;}
	}
	.detail-title-bar .detail-main-points li.dmp-locations{max-width: fit-content;}
	.menu-category{margin-bottom: 30px;
		.menu-category-item {padding: 15px 5px;margin-right: 5px;}
	}
	
	.menu-category-listing {
		.menu-category-listing-col{margin-bottom: 20px;
			&::after{display: none;}
		}
		.menu-category-listing-item{padding-bottom: 20px;
			&.skeleton{
				h3 .MuiSkeleton-root{width: 300px;height: 20px;}
				.dietry-label .MuiSkeleton-root{width: 180px;height: 20px;}
				.price-time .MuiSkeleton-root{width: 240px;height: 20px;}
				p .MuiSkeleton-root{width: 370px;height: 18px;}
	
			}
		}
	}

	.caterer-menu-modal .caterer-menu-details {max-height: 100vh;height: 100vh;width: 100%;border-radius: 0px;display: flex;flex-direction: column;
		.caterer-menu-content {max-height: calc(100vh - 158px);padding: 20px;}
		.caterer-menu-footer {padding: 20px;border-radius: 0;
		margin-top: auto;position: absolute;bottom: 0;width: 100%;}
	}

	.mob-cart-button-area{padding: 20px;position: fixed;left: 0;right: 0;bottom: 0;background: #FFFFFF;box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);width: 100%;z-index: 10;
		button{height: 54px;font-weight: 700;font-size: 16px;color:#fff;display: flex;align-items: center;justify-content: space-between;border-radius: 4px;width: 60%;margin: auto;}
	}

	.cart-view-col {height: calc(100vh - 70px);overflow: scroll;display: flex;flex-direction: column;justify-content: center;background-color: #fff;
	}
	.cart-view {padding: 10px;margin: 25px 0px;padding-top: 0;border: 1px solid #e3e3e3;
		 .cart-view-title{margin: 0 -10px;padding: 10px 12px;}
		 .cart-review-text p{text-align: center;margin-bottom: 15px;}
	}
	
	.cater-detail-timing li{width: calc((100%) / 2);font-size: 12px;}
	.cater-detail-timing li:nth-child(3n+2){margin: 0;}
	.cater-detail-timing li:nth-child(3n+2)::before, .cater-detail-timing li:nth-child(3n+2)::after{display: none;}
	.cater-detail-timing li:nth-child(odd){border-right: 0.6px solid #efefef;padding-right: 10px;}
	.cater-detail-timing li:nth-child(even){padding-left: 10px;}

	.order-confirm-screen{height: calc(100vh - 155px);
		.ocs-text{width: 100%;padding: 0 10px;}
	}


	.common-drawer .MuiDrawer-paper {width: 500px;}

	//Login and register
	.create-form-left{display: none;}
	.create-stepper .MuiStepLabel-label {font-size: 15px;}

	.width-175{min-width: 220px;}

	.catercash-overview {gap: 2%;flex-wrap:wrap;
		 .cc-item{width: 48%;flex-basis: inherit;margin-bottom: 18px;}
	}
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1080px) and (orientation: landscape) {

	.h1{font-size: 32px;line-height: 42px;}
	.h2{font-size: 28px;line-height: 38px;}
	.h3{font-size: 24px;line-height: 34px;}
	.h4{font-size: 20px;line-height: 28px;}
	.h5{font-size: 18px;line-height: 24px;}
	.h6{font-size: 14px;}


	header .header-menu {
		ul li a {padding: 5px 10px;font-size: 14px;}
		.MuiAvatar-root{width: 30px;height: 30px;font-size: 14px;}
		.header-user-name {font-size: 14px;margin-left: 8px;}
	}
	
	.testimonials .testimonial-item-text{padding: 20px;
		p{font-size: 14px;}
	}


	.home-works .home-works-item {
		.home-works-item-cnt {padding: 20px 30px;
	 		label {padding: 10px 0 12px;}
		}
		.home-works-item-img {height: 250px;}
	}

	footer {padding: 20px 0;
		.logo-social-link .footer-logo{height: 50px;}
	}

	.listing-filter-primary {
		.lfp-delivery-address {min-width: 320px;}
		.lfp-date {margin: 0 15px;
			input {width: 250px;}
		}
		.lfp-head-count{width: 130px;}
	}
	.listing-filter-secondary{padding-bottom: 8px;
		.container{}
		h3{font-size: 12px;flex-shrink: 0;}
	
		.lfs-item{margin-right: 10px;background-size: 12px;}
		
		.lfs-search{padding-right: 10px;width:160px;}
		.lfs-deitary{width: 120px;}
		.lfs-option{width: 130px;
			.MuiSelect-select{padding-left: 25px !important;background-size: 12px;}
		}
		.lfs-cuisnes{width: 120px;
			.MuiSelect-select{padding-left: 25px !important;background-size: 12px;}
		}
		.lfs-sort{width: 150px;padding-left: 10px;}
	
		.MuiSelect-select {font-size:12px;
			img{margin-right: 4px;}
		}
		fieldset{border-radius: 6px;}
		.MuiInputAdornment-root {flex-shrink: 0;}
		.lfs-clear-btn{height: 32px;font-size: 12px;}
	}

	.cater-list .cater-list-item-outer {margin-bottom: 25px;}
	.cater-list-item {
		.cater-list-item-img{height: 200px;
			.cater-cash-tag {top: 10px;right: 10px;}
		}
		.cater-list-item-cnt{padding-top: 10px;
			h6{margin-top: 5px;}
			.cater-list-order {flex-wrap: wrap;
				span {padding-right: 3px;margin-right: 3px;width:47%;border-right:0;
					img {margin-right: 5px;}
				}
			}
		}
	}
	.cater-cash-tag {width: 65px;height: 65px;
		b{font-size: 16px;line-height: 18px;}
		label{line-height: 12px;}
	}

	.detail-title-bar{padding-bottom: 10px;
		.detail-main-points{flex-wrap: wrap;
			li{margin-bottom: 10px;}
		}
	}
	.menu-category{margin-bottom: 30px;}
	.menu-category-listing .menu-category-listing-item{
		.menu-category-listing-item-img img {width: 75px;height: 55px;}
		&.skeleton{
			h3 .MuiSkeleton-root{width: 250px;height: 22px;}
			.dietry-label .MuiSkeleton-root{width: 150px;height: 22px;}
			.price-time .MuiSkeleton-root{width: 200px;height: 22px;}
			p .MuiSkeleton-root{width: 270px;height: 20px;}
		}
	}

	.cart-total, .cart-price-perhead{font-size: 14px;}
	.order-confirm-screen {height: calc(100vh - 165px);}
	.catercash-overview {gap:5px;padding:20px 10px;
		.cc-item {
			h2 {font-size: 35px;line-height: 45px;}
			label{line-height: 20px;font-size: 12px;margin-left: 0;}
			.cc-item-inner{display: block !important;}
			&.cc-gift {
				small {line-height: 14px;}
				.amazon-btn button {width: 40px;font-size: 12px;}
			}
			
		}
	}
	.width-175{min-width: 220px;}

	.mob-cart-button-area{padding:14px 20px;position: fixed;left: 0;right: 0;bottom: 0;background: #FFFFFF;box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);width: 100%;z-index: 10;
		button{height: 50px;font-weight: 700;font-size: 16px;color:#fff;display: flex;align-items: center;justify-content: space-between;border-radius: 4px;width: 45%;margin: auto;}
	}

	.cart-view-col {height: auto;overflow: scroll;display: flex;flex-direction: column;justify-content: center;background-color: #fff;
	}
	.cart-view{padding: 0px 10px 0 10px;width: 70%;margin:20px auto;border: 1px solid #e3e3e3;
		.cart-view-title {margin: 0 -10px;padding: 10px 12px;}
	}
	
}
// IPAD PRO
/* Portrait and Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
	.detail-main-points {flex-wrap: wrap;}
	.detail-title-bar .detail-main-points li {margin-bottom: 15px;}		
}

// IPAD PRO



@media (min-width: 1700px) {
	.order-confirm-screen .ocs-text{width: 48%;}
	.catercash-overview .cc-item {height: 170px;}
}

@media (min-width: 2300px) {
	.order-confirm-screen .ocs-text{width: 40%;}
	.catercash-overview .cc-item {height: 200px;}
}


.slick-track {
	display: flex;
}

.slick-track .slick-slide {
	display: flex;
	height: auto;
	align-items: center;
	justify-content: center;
}

#hs_form_target_widget_1730901540411-182432056907 label {display: none;}
#hsForm_c6c9ed17-ed8b-47da-9e8a-519b7633b6a8_719 { display: flex; justify-content: center; align-items: center; flex-direction: row; }