html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  padding-top: 70px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.h1 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
}

.h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
}

.h3 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

.h4 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.h5 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}

.h6 {
  font-size: 14px;
  font-weight: 600;
}

a, a:hover, button {
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

button.MuiButton-root {
  text-transform: capitalize;
}

header {
  height: 70px;
  display: flex;
  border-bottom: 1px solid #EEEEEE;
  background-color: #fff;
}
header .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .logo img {
  display: block;
}
header .header-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
header .header-menu ul li {
  display: inline-flex;
}
header .header-menu ul li a {
  color: #2C2D34;
  text-decoration: none;
  padding: 5px 15px;
  position: relative;
}
header .header-menu ul li a:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 10px;
  background-color: #2C2D34;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}
header .header-menu ul li a:hover {
  color: #FD4E3B;
}
header .header-menu ul li:last-child a:after {
  display: none;
}
header .header-menu .signin-btn {
  background-color: #FD4E3B;
  color: #fff;
  text-transform: capitalize;
  padding: 5px 20px;
}
header .header-menu .header-user-name {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  color: #383A40;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header .header-menu .header-user-name:hover {
  color: #FD4E3B;
}

header.LoggedInHeader .container-fluid {
  padding-left: 0;
}
header.LoggedInHeader .loggedLogo {
  width: 200px;
  height: 100%;
  margin-right: 20px;
  background-color: #FD4E3B;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
header.LoggedInHeader h3 {
  font-size: 22px;
  font-weight: 700;
}
header.LoggedInHeader .header-loaction-field-wrap {
  display: flex;
  align-items: center;
}
header.LoggedInHeader .header-loaction-field .header-loaction-data h4 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
header.LoggedInHeader .header-loaction-field .header-loaction-data label {
  font-size: 12px;
  display: block;
}
header.LoggedInHeader .header-loaction-field fieldset legend span {
  display: none;
}
header.LoggedInHeader .header-loaction-field .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}
header.LoggedInHeader .header-loaction-field .MuiInputBase-input {
  padding: 5px 10px;
  min-height: 40px;
}
header.LoggedInHeader .headerAddBusinessBtn {
  height: 44px;
  margin-left: 24px;
  position: relative;
}
header.LoggedInHeader .headerAddBusinessBtn img {
  margin-right: 8px;
}
header.LoggedInHeader .headerAddBusinessBtn::after {
  content: "";
  position: absolute;
  height: 51px;
  width: 1px;
  background-color: #ebebeb;
  left: -12px;
}

.MuiSnackbar-root {
  max-width: 500px;
}

.wrap-height {
  height: calc(100vh - 70px);
  overflow: hidden;
}

.wrap-height-no-scroll {
  height: calc(100vh - 76px);
  overflow: hidden;
}

.overflow {
  overflow: scroll;
  height: 100%;
}

.login-img {
  background-color: #0C0B09;
  height: 100%;
  position: relative;
}
.login-img img {
  width: 100%;
}
.login-img h3 {
  width: 70%;
  margin: auto;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px; /*top: 50%;transform: translateY(-50%);*/
}

.login-left {
  display: flex;
}

.textBox {
  position: relative;
}
.textBox .error-msg {
  position: absolute;
  bottom: 3px;
}
.textBox .error-msg.invalid-error-msg {
  top: -4px;
  left: 27px;
  z-index: 1;
}

.login-box.create-step-form {
  width: 400px;
  padding: 30px;
  margin: auto;
  border: 1px solid #CFD7E3;
  border-radius: 13px;
}
.login-box.create-step-form .h4 {
  padding-bottom: 22px;
  color: #FD4E3B;
  text-align: center;
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 30px;
}
.login-box.create-step-form .formGroup {
  margin-top: 20px;
}
.login-box.create-step-form .form-control {
  height: 45px;
  color: #526495;
  border-color: #CFD7E3;
  padding: 10px;
  border-radius: 5px;
}
.login-box.create-step-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.login-box.create-step-form .login-btn.MuiButton-root {
  height: 50px;
  margin-top: 20px;
  font-size: 14px;
  border-radius: 6px;
  background-color: #FD4E3B;
  color: #fff;
  width: 100%;
  text-transform: capitalize;
}
.login-box.create-step-form .MuiFormControlLabel-root {
  padding-bottom: 0px;
}

.login-box-frontend {
  width: 400px;
  margin: auto;
}
.login-box-frontend .h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2C2D34;
  text-align: center;
}
.login-box-frontend .login-box.create-step-form {
  width: auto;
  padding: 40px 30px;
}
.login-box-frontend .login-box.create-step-form .textField input {
  color: #526495;
  font-size: 16px;
}
.login-box-frontend .login-box.create-step-form .textField .MuiOutlinedInput-notchedOutline {
  border-color: #CFD7E3 !important;
}
.login-box-frontend .login-box.create-step-form .textField .MuiInputLabel-root.Mui-focused {
  color: #000 !important;
}
.login-box-frontend .login-box.create-step-form .textField .MuiInputLabel-root {
  text-transform: capitalize;
}
.login-box-frontend .login-box.create-step-form .login-btn.MuiButton-root {
  font-weight: 600;
}
.login-box-frontend .login-box.create-step-form .login-separator {
  margin: 25px 0 22px;
  position: relative;
  text-align: center;
}
.login-box-frontend .login-box.create-step-form .login-separator label {
  font-size: 16px;
  color: #9598A8;
}
.login-box-frontend .login-box.create-step-form .login-separator label:after, .login-box-frontend .login-box.create-step-form .login-separator label:before {
  position: absolute;
  height: 1px;
  width: 50%;
  background-color: #CFD7E3;
  content: "";
  top: 50%;
  transform: translateY(-50%);
}
.login-box-frontend .login-box.create-step-form .login-separator label::before {
  left: -30px;
}
.login-box-frontend .login-box.create-step-form .login-separator label::after {
  right: -30px;
}
.login-box-frontend .login-box.create-step-form .login-create h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2C2D34;
  text-align: center;
}
.login-box-frontend .login-box.create-step-form .login-create p {
  margin: 12px 0 20px;
  font-size: 12px;
  color: #9598A8;
}
.login-box-frontend .login-box.create-step-form .new-account-btn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.login-box-frontend .login-box.create-step-form .new-account-btn a {
  width: 50%;
  display: block;
}
.login-box-frontend .login-box.create-step-form .new-account-btn button {
  width: 100%;
  height: 50px;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #787878;
}

.login-check {
  margin: 5px 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #000;
}
.login-check .MuiTypography-root {
  font-size: 12px;
}
.login-check a {
  color: #000;
}
.login-check a:hover {
  text-decoration: underline;
}

.createOneLink {
  font-size: 12px;
  margin-top: 15px;
}
.createOneLink a {
  color: #FD4E3B;
}
.createOneLink a:hover {
  text-decoration: underline;
}

.create-step-wrap {
  padding: 30px 50px;
}

.create-stepper .MuiStepConnector-root {
  display: none;
}
.create-stepper .MuiStep-root {
  padding: 0 8px;
}
.create-stepper .MuiStep-root:first-child {
  padding-left: 0;
}
.create-stepper .MuiStepLabel-label {
  font-size: 16px;
}
.create-stepper .MuiStepLabel-label.Mui-active {
  color: #FD4E3B;
  font-weight: 600;
  text-transform: capitalize;
}
.create-stepper .MuiStepLabel-iconContainer {
  font-weight: 600;
}
.create-stepper .MuiStepIcon-root {
  color: #646777;
}
.create-stepper .MuiStepIcon-root.Mui-completed {
  color: #FAA095;
}
.create-stepper .MuiStepIcon-root.Mui-active {
  color: #FAA095;
}
.create-stepper .MuiStepLabel-label.Mui-completed {
  color: #FD4E3B;
  font-weight: 600;
}

.subhead {
  font-size: 12px;
  line-height: 14px;
  color: #3d3d3d;
}

.subheadSmall {
  font-size: 8px;
  color: #9598A8;
}

.error-msg {
  font-size: 10px;
  display: block;
  margin-top: 3px;
  color: red;
}
.error-msg.inst-msg {
  margin-top: -20px;
  margin-bottom: 15px;
}

.create-step-form {
  padding-top: 24px;
}
.create-step-form .allFeild {
  margin-bottom: 24px;
}
.create-step-form .textField {
  width: 100%;
}
.create-step-form .textField .MuiInputLabel-root {
  color: #646777; /*font-size: 14px;letter-spacing: 0.4px;/*color: #646777;*/
}
.create-step-form .textField .MuiFilledInput-root {
  border-radius: 0;
}
.create-step-form .textField input {
  color: #2C2D34; /*height: 15px;font-size: 16px;*/
}
.create-step-form .textField .MuiSelect-select {
  color: #2C2D34; /*height: 15px;min-height: 15px;font-size: 12px;line-height: 12px;*/
}
.create-step-form .textField .MuiOutlinedInput-notchedOutline {
  border-color: #D9E5F3 !important;
}
.create-step-form .MuiFormControlLabel-root {
  padding-bottom: 24px;
  margin-right: 0;
}
.create-step-form .MuiFormControlLabel-root .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}
.create-step-form .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px;
}
.create-step-form .MuiFormControlLabel-root.custom-delivery-fee-label .MuiFormControlLabel-label {
  font-size: 16px;
  font-weight: 600;
}
.create-step-form hr {
  margin: 0;
  background-color: #D9E5F3;
  opacity: 1;
}
.create-step-form input[type=number]::-webkit-inner-spin-button,
.create-step-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.create-step-form input[type=number] {
  -moz-appearance: textfield;
}
.create-step-form .MuiOutlinedInput-notchedOutline {
  border-color: #D9E5F3 !important;
}
.create-step-form .MuiInputLabel-root {
  color: #646777 !important;
}

.loginAgreeText {
  margin-top: 20px;
}
.loginAgreeText .MuiCheckbox-root {
  padding: 0;
  padding-right: 10px;
  padding-left: 9px;
}
.loginAgreeText .MuiTypography-root {
  font-size: 12px;
  color: #000;
}
.loginAgreeText .MuiTypography-root a {
  color: #FD4E3B;
  text-decoration: underline;
}
.loginAgreeText .MuiTypography-root a:hover {
  color: #000;
}

.createStepBtn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.createStepBtn .MuiButton-root {
  width: 200px;
  height: 42px;
  border-radius: 6px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
}
.createStepBtn .MuiButton-root.cancelBtn {
  margin-right: 24px;
}
.createStepBtn .btnArrow {
  position: absolute;
  right: 8px;
}

.addBusinessSet {
  margin-top: 24px;
}
.addBusinessSet .h6 {
  margin-bottom: 20px;
}

.form-space {
  padding-bottom: 24px;
}

.delivery-distance-text {
  margin-top: 5px;
}
.delivery-distance-text label {
  font-style: 12px;
  color: #646777;
  display: block;
}
.delivery-distance-text span {
  font-size: 14px;
  color: #2C2D34;
  display: block;
}

.more-delivery-address-email {
  font-size: 12px;
  text-decoration: underline;
  color: #2C2D34;
  margin-top: -4px;
  display: block;
}
.more-delivery-address-email.remove {
  margin-top: -26px;
}
.more-delivery-address-email:focus, .more-delivery-address-email:hover {
  color: #FD4E3B;
  text-decoration: underline;
}

.hours-title-label {
  font-weight: 600;
}

.order-cutoff-time {
  margin: 25px 0;
}

.weekly-hrs-outer {
  border: 1px solid #D9E5F3;
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 20px;
}
.weekly-hrs-outer .weekly-hrs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.weekly-hrs-outer .weekly-hrs h6 {
  font-style: 18px;
  font-weight: 600;
}
.weekly-hrs-outer .weekly-hrs .weekly-hrs-open-close {
  display: flex;
  align-items: center;
}
.weekly-hrs-outer .weekly-hrs .weekly-hrs-open-close .shop-close-open-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 24px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  margin-left: 18px;
}
.weekly-hrs-outer .weekly-hrs .weekly-hrs-open-close .shop-close-open-label.shop-close-label {
  background: #FF4E4E;
}
.weekly-hrs-outer .weekly-hrs .weekly-hrs-open-close .shop-close-open-label.shop-open-label {
  background: #28BC75;
}
.weekly-hrs-outer .per-day-time {
  border-top: 1px solid #D9E5F3;
  padding-top: 22px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.weekly-hrs-outer .per-day-time .add-hours {
  margin-left: 10px;
  font-size: 14px;
  flex: 1;
  color: #91939C;
  display: flex;
  align-items: center;
}
.weekly-hrs-outer .per-day-time .add-hours span {
  font-size: 24px;
  font-weight: 300;
}
.weekly-hrs-outer .per-day-time .add-hours:hover {
  text-decoration: underline;
}
.weekly-hrs-outer .per-day-time .time-separator {
  margin: 0 35px;
}

.hours-form .MuiTextField-root {
  width: 160px;
  border: 1px solid #D9E5F3;
  border-radius: 4px;
}
.hours-form .MuiOutlinedInput-root.MuiInputBase-adornedEnd {
  flex-direction: row-reverse;
}
.hours-form .MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
  padding-right: 2px;
}

.hours-dropdown .MuiSelect-icon {
  display: none;
}
.hours-dropdown .MuiSelect-select {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.hours-dropdown svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: #646777;
}

.MuiMenu-root .MuiPaper-root {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.MuiMenu-root .MuiPaper-root .MuiList-root {
  max-height: 200px;
}
.MuiMenu-root .MuiPaper-root .MuiList-root li {
  font-size: 14px;
}
.MuiMenu-root .MuiPaper-root .MuiList-root li svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: #646777;
}

.nav-header-menu .MuiPaper-root .MuiList-root {
  max-height: 80vh;
}

.sideMenu {
  width: 200px;
  top: 70px;
  padding: 0 12px;
  left: 0;
  bottom: 0;
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(158, 157, 157, 0.25);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #ffffff;
}
.sideMenu .sideMenuItem {
  height: 50px;
  padding: 8px 10px !important;
  border-bottom: 1px solid #ebebeb;
}
.sideMenu .sideMenuItem img {
  margin-right: 10px;
  width: 18px;
}
.sideMenu .sideMenuItem .MuiTypography-root {
  font-size: 14px;
}
.sideMenu .sideMenuItem.Mui-selected {
  color: #FD4E3B;
  background-color: #fff;
  border-bottom: 2px solid #FD4E3B;
}
.sideMenu .sideMenuItem.Mui-selected .MuiTypography-root {
  font-weight: 600;
}
.sideMenu .sideMenuItem.Mui-selected img {
  filter: invert(57%) sepia(67%) saturate(5448%) hue-rotate(335deg) brightness(103%) contrast(114%);
}
.sideMenu .sideMenuItem .sideMenuItemText {
  display: block;
}
.sideMenu::-webkit-scrollbar {
  width: 5px;
}
.sideMenu::-webkit-scrollbar-track {
  background: #ffffff;
}
.sideMenu::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
  border: 1px none #ffffff;
}

.contentWrap {
  padding-left: 200px;
  background: #F5F6F8;
  min-height: calc(100vh - 70px);
}
.contentWrap .contentWrapInner {
  padding: 20px 40px 40px 20px;
}
.contentWrap .location-head .location-head-title {
  padding: 27px 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.contentWrap .location-head .location-head-title h5 {
  font-weight: 600;
  color: #484848;
}
.contentWrap .location-head .location-head-title input {
  width: 320px;
  height: 32px;
  margin-left: 24px;
  border: 0.3px solid #9598A8;
  border-radius: 6px;
  background-image: url("/public/assets/images/icon-search.svg");
  background-repeat: no-repeat;
  background-position: right 8px center;
  font-size: 12px;
  padding-right: 25px;
}
.contentWrap .location-head .location-head-title input:focus {
  outline: 0;
  box-shadow: none;
}
.contentWrap .location-head .location-head-title input::-webkit-input-placeholder {
  font-size: 12px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title input:-moz-placeholder {
  font-size: 12px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title input::-moz-placeholder {
  font-size: 12px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title input:-ms-input-placeholder {
  font-size: 12px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title.menu-search-by-item input {
  height: 56px;
  font-size: 16px;
}
.contentWrap .location-head .location-head-title.menu-search-by-item input::-webkit-input-placeholder {
  font-size: 16px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title.menu-search-by-item input:-moz-placeholder {
  font-size: 16px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title.menu-search-by-item input::-moz-placeholder {
  font-size: 16px;
  color: #9598A8;
}
.contentWrap .location-head .location-head-title.menu-search-by-item input:-ms-input-placeholder {
  font-size: 16px;
  color: #9598A8;
}

.commonTabel {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
  background: #FFFFFF;
  border: 1px solid #F8F8F8;
}
.commonTabel thead tr th {
  padding: 22px 20px;
  font-weight: 600;
  border-top: 1px solid rgb(224, 224, 224);
}
.commonTabel tbody tr {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.commonTabel tbody tr th, .commonTabel tbody tr td {
  color: #4C5A76;
  padding: 22px 20px;
  border-bottom: 0;
}
.commonTabel tbody tr:hover {
  background-color: #e8e8e8;
}
.commonTabel label b {
  font-weight: 600;
  display: block;
  text-transform: capitalize;
}
.commonTabel small {
  font-size: 12px;
  display: block;
  text-transform: capitalize;
}

.table-pagenation {
  padding: 10px 20px 50px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.table-pagenation .rowCount {
  position: absolute;
  left: 20px;
}
.table-pagenation .rowCount .MuiOutlinedInput-root {
  background-color: #fff;
}
.table-pagenation .rowCount fieldset {
  border: 0;
}
.table-pagenation .rowCount fieldset legend {
  display: none;
}
.table-pagenation .tableCount button {
  border: 0;
  background-color: #fff;
  color: #646777;
  border-radius: 0;
}
.table-pagenation .tableCount button.Mui-selected {
  border-bottom: 2px solid #FD4E3B;
  color: #FD4E3B;
}
.table-pagenation .tableCount button.Mui-selected:focus {
  background-color: #fff;
}

.ld-tile-outer {
  margin-bottom: 20px;
}

.location-details-tile {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);
  border-radius: 6px; /*height:100%;*/
}
.location-details-tile .ld-titles {
  margin-bottom: 20px;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location-details-tile .ld-titles h4 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.location-details-tile .ld-titles h4 img {
  margin-right: 15px;
}

.ld-edit {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #FD4E3B;
}
.ld-edit img {
  margin-right: 6px;
}
.ld-edit:hover {
  text-decoration: underline;
  color: #FD4E3B;
}

.ld-contact {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.ld-contact-img {
  flex-shrink: 0;
  position: relative;
  margin-right: 20px;
}
.ld-contact-img img {
  object-fit: cover;
  width: 290px;
  height: 195px;
  border-radius: 5px;
  display: block;
}

.cater-cash-tag {
  position: absolute;
  width: 74px;
  height: 74px;
  right: 10px;
  top: 10px;
  background: #FFFFFF;
  border: 3px solid rgba(87, 132, 187, 0.3);
  color: #4F4080;
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cater-cash-tag b {
  font-size: 20px;
}
.cater-cash-tag label {
  font-size: 10px;
}

.ld-contact-details {
  margin-left: 15px;
  display: flex;
  align-items: center;
  color: #4C5A76;
  width: 100%;
  margin-right: 15px;
}
.ld-contact-details .ld-contact-details-item {
  margin-right: 20px;
}
.ld-contact-details .ld-contact-details-item:last-child {
  margin-right: 0;
}
.ld-contact-details label {
  font-size: 12px;
  display: block;
}
.ld-contact-details span {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.ld-business-primary.ld-business-detail .ld-business-detail-item {
  margin-bottom: 10px;
}

.ld-business-detail {
  color: #4C5A76;
}
.ld-business-detail .ld-business-detail-item {
  margin-bottom: 25px;
}
.ld-business-detail label {
  font-size: 12px;
  display: block;
}
.ld-business-detail b {
  font-size: 14px;
  line-height: 20px;
  display: block;
  font-weight: 600;
  text-transform: capitalize;
  word-break: break-all;
}

.ld-readmore-wrap .ld-readmore-box {
  width: 600px;
}
.ld-readmore-wrap .ld-readmore-box button {
  padding: 0;
}
.ld-readmore-wrap .ld-readmore-box p {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  border: 1px solid #D9E5F3;
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 20px;
}

.ld-options-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ld-options {
  flex-shrink: 0;
  margin-right: 10px;
}
.ld-options .MuiTypography-root {
  font-size: 14px;
  color: #3E3C37 !important;
  font-weight: 600;
}
.ld-options.ld-options-edit {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #efefef;
}
.ld-options.ld-options-edit .MuiFormControlLabel-root {
  padding-bottom: 0;
}
.ld-options.ld-options-edit-drawer {
  margin-right: 0;
}
.ld-options.ld-options-edit-drawer .location-details-tile {
  padding: 0;
  box-shadow: none;
}

.ld-others ul li {
  margin-bottom: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.ld-others ul li:last-child {
  margin-bottom: 0;
}

.ld-delivery-status {
  min-width: 60px;
  height: 24px;
  padding: 0 5px;
  font-size: 10px;
  margin-left: 18px;
  background: rgba(0, 198, 67, 0.04);
  border: 0.5px solid #00C643;
  border-radius: 3px;
  color: #2C2D34;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.ld-delivery-status label {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background: #00C643;
  border-radius: 50px;
  display: block;
}
.ld-delivery-status.ld-close {
  border: 0.5px solid red;
  background: rgba(255, 0, 0, 0.04);
}
.ld-delivery-status.ld-close label {
  background: red;
}

.ld-delivery-hrs ul li {
  font-size: 14px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  color: #3E3C37;
  font-weight: 600;
  border-bottom: 1px solid #EFEFEF;
}
.ld-delivery-hrs ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
}

.ld-slider {
  display: flex;
  align-items: center;
}
.ld-slider h5 {
  min-width: 85px;
  font-size: 14px;
  color: #3E3C37;
  margin-right: 20px;
  font-weight: 600;
}
.ld-slider h6 {
  font-size: 12px;
  font-weight: 600;
  margin-left: 30px;
}

.upload-img-wrap {
  margin-bottom: 35px;
  display: inline-block;
  position: relative;
}
.upload-img-wrap .upload-img {
  width: 245px;
  height: 165px;
  margin: auto;
  background-color: #f0f0f0;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}
.upload-img-wrap .upload-img.upload-img-square {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.upload-img-wrap .upload-img.upload-no-image {
  object-fit: none;
}
.upload-img-wrap .upload-icon {
  position: absolute;
  bottom: -5px;
  right: -5px;
  cursor: pointer;
}

.common-drawer .MuiDrawer-paper {
  width: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.common-drawer .drawer-head {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 4px rgba(118, 118, 118, 0.16);
}
.common-drawer .drawer-head h4 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.common-drawer .drawer-head h4 img {
  margin-right: 12px;
}
.common-drawer .container-fluid {
  min-height: 100%;
}
.common-drawer .create-step-form {
  padding: 30px 18px;
  height: calc(100vh - 175px);
  overflow-y: auto;
}
.common-drawer .create-step-form::-webkit-scrollbar {
  width: 5px;
}
.common-drawer .create-step-form::-webkit-scrollbar-track {
  background-color: #dddada;
}
.common-drawer .create-step-form::-webkit-scrollbar-thumb {
  background-color: rgb(184, 175, 175);
  border-radius: 20px;
}
.common-drawer .createStepBtn {
  margin: 0;
  padding: 30px;
  border-top: 1px solid #CFD7E3;
}
.common-drawer .createStepBtn .MuiButton-root {
  width: 145px;
}
.common-drawer .createStepBtn .MuiButton-root.cancelBtn {
  width: 120px;
  border: 1px solid #D9E5F3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 400;
  color: #383A40;
}
.common-drawer .delivery-options-edit label {
  margin-right: 0;
}
.common-drawer .weekly-hrs-outer .per-day-time .MuiFormControl-root {
  min-width: 135px;
}
.common-drawer .weekly-hrs-outer .per-day-time .time-separator {
  margin: 0 10px;
}
.common-drawer .weekly-hrs-outer .per-day-time .add-hours {
  margin-left: 15px;
}
.common-drawer .add-business-step-wrap .create-stepper {
  padding: 30px 30px 20px 30px;
}
.common-drawer .add-business-step-wrap .add-business-step-head {
  padding: 0 30px;
  padding-bottom: 15px;
}
.common-drawer .add-business-step-wrap .add-business-step-head .h5 {
  margin-bottom: 5px;
}
.common-drawer .add-business-step-wrap .create-step-form {
  height: calc(100vh - 310px);
  padding-top: 15px;
}
.common-drawer .add-business-step-wrap .createStepBtn .MuiButton-root.saveBtn {
  width: 170px;
  padding: 8px 10px;
  justify-content: flex-start;
}
.common-drawer.business-drawer .MuiDrawer-paper {
  width: 620px;
}

.custom-delivery-field-wrap {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
}
.custom-delivery-field-wrap .allFeild {
  margin-bottom: 15px;
}
.custom-delivery-field-wrap .cd-flex-1 {
  flex: 1.2;
  margin-right: 10px;
}
.custom-delivery-field-wrap .cd-flex-2 {
  flex: 2;
  margin-right: 10px;
}

button.custom-delivery-field-wrap-btn {
  width: 80px;
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 700;
}
button.custom-delivery-field-wrap-btn img {
  margin-right: 5px;
}

.menu-head-title {
  padding: 20px;
  background-color: #fff;
  border-bottom: 2px solid #F3F3F3;
}
.menu-head-title h5 {
  font-size: 16px;
  font-weight: 600;
}
.menu-list-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.menu-cat-box {
  margin-left: 25px;
}

.menu-head-btns button {
  height: 45px;
  margin-right: 25px;
}
.menu-head-btns button img {
  margin-right: 7px;
}
.menu-head-btns button:last-child {
  margin-right: 0;
}

.menu-back {
  font-size: 16px;
  font-weight: 600;
  color: #FAA095;
  display: flex;
  align-items: center;
}
.menu-back img {
  margin-right: 5px;
}
.menu-back span {
  text-decoration: underline;
}
.menu-back:hover {
  color: #FD4E3B;
}
.menu-back:hover img {
  filter: invert(62%) sepia(99%) saturate(4906%) hue-rotate(337deg) brightness(102%) contrast(107%);
}

.menu-view {
  margin-top: 30px;
  margin-bottom: 80px;
  background-color: #fff;
}

.menu-view-head {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-view-head h4 {
  font-size: 20px;
  font-weight: 600;
}
.menu-view-head .ld-edit {
  font-size: 14px;
}

.menu-details {
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E7E7E7;
  border-top: 1px solid #E7E7E7;
}
.menu-details .menu-details-img {
  margin-right: 20px;
}
.menu-details .menu-details-img img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
.menu-details h5 {
  margin-bottom: 20px;
  text-transform: capitalize;
}
.menu-details .menu-item-details {
  display: flex;
  align-items: center;
}
.menu-details .menu-item-details li {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.menu-details .menu-item-details li img {
  margin-right: 9px;
}

.menu-ingredient-list {
  padding: 20px;
}

.menu-add-on {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #E7E7E7;
}
.menu-add-on h5 {
  color: #484848;
}

.main-addon {
  margin: 20px 0px 20px;
  margin-bottom: 0;
  background-color: #fff;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 80px;
}
.main-addon .addon-list {
  font-size: 18px;
  font-weight: 700;
}
.main-addon .add-action {
  display: flex;
}
.main-addon .add-action .ld-edit {
  margin-right: 40px;
}

.main-add-on-head {
  background-color: #fff;
  padding: 20px 20px 20px 110px;
  font-size: 16px;
  color: #4C5A76;
  font-weight: 700;
}
.main-add-on-head label {
  font-family: "Lato", sans-serif;
}

.sort-disabled img {
  filter: invert(84%) sepia(5%) saturate(4%) hue-rotate(28deg) brightness(86%) contrast(92%);
}

.width-1 {
  width: 35%;
}

.width-2 {
  width: 15%;
  text-align: center;
}

.width-3 {
  width: 50%;
}

.sub-add-on-wrap {
  background: #F0F0F0;
  padding-left: 70px;
  border-bottom: 2px solid #fff;
  position: relative;
}
.sub-add-on-wrap .sub-addon-sort {
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
}
.sub-add-on-wrap .sub-addon-sort .MuiButtonBase-root {
  padding: 5px;
}
.sub-add-on-wrap .sub-addon-sort .MuiButtonBase-root img {
  width: 10px;
}
.sub-add-on-wrap .sub-addon-sort .MuiButtonBase-root.disabled {
  cursor: auto;
}
.sub-add-on-wrap .sub-addon-sort .MuiButtonBase-root.disabled img {
  filter: invert(84%) sepia(5%) saturate(4%) hue-rotate(28deg) brightness(86%) contrast(92%);
}

.sub-add-on-inner {
  border: 20px solid #E3E3E3;
  padding: 20px;
  background-color: #fff;
}
.sub-add-on-inner .form-control {
  padding: 8px 12px;
  height: 50px;
  border: 1px solid #D9E5F3;
  border-radius: 4px;
}
.sub-add-on-inner .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.sub-add-on-inner .form-control.sub-add-on-price {
  width: 160px;
  padding-left: 30px;
  margin-left: 5px;
  background-image: url("/public/assets/images/dollar.svg");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 12px;
}
.sub-add-on-inner .sub-add-on-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub-add-on-inner .sub-add-on-btn {
  width: 85px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}
.sub-add-on-inner .sub-add-on-btn img {
  width: 20px;
  height: 20px;
}
.sub-add-on-inner .sub-add-on-btn:last-child {
  margin-right: 0;
}
.sub-add-on-inner .veg-nonveg-switch {
  margin: auto;
}
.sub-add-on-inner input[type=number]::-webkit-inner-spin-button,
.sub-add-on-inner input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.sub-add-on-inner input[type=number] {
  -moz-appearance: textfield;
}
.sub-add-on-inner.sort-indication {
  animation: blink 1s;
  animation-iteration-count: 1;
}

@keyframes blink {
  50% {
    border-color: rgba(253, 78, 59, 0.67);
  }
}
.sub-add-on-type-wrap {
  border: 20px solid #f0f0f0;
  margin-top: 20px;
  background-color: #fff;
  width: 60%;
}

.sub-add-on-type {
  padding: 20px;
  border-bottom: 1px solid #E7E7E7;
}
.sub-add-on-type .sub-add-on-type-price {
  width: 130px;
  padding-left: 30px;
  margin: 0 90px 0 20px;
  background-image: url("/public/assets/images/dollar.svg");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 12px;
}

.menu-list-more ul {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.17);
  border-radius: 5px;
}
.menu-list-more ul li {
  border-bottom: 1px solid #EFEFEF;
  font-size: 14px;
  color: #4C5A76;
  text-align: center;
  display: block;
}
.menu-list-more ul li:last-child {
  border-bottom: 0 solid #000;
}

.menu-add-on-popup {
  width: 420px;
}
.menu-add-on-popup .h4 {
  padding: 30px 0 20px;
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  color: #FD4E3B;
  font-family: "Lato", sans-serif;
  text-align: center;
  border-bottom: 1px solid #CFD7E3;
}
.menu-add-on-popup .menu-add-on-popup-field {
  padding: 20px 32px 0;
}
.menu-add-on-popup .menu-add-on-popup-field .MuiFormControl-root {
  margin-bottom: 20px;
}
.menu-add-on-popup .createStepBtn {
  padding: 0 32px;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px;
}
.menu-add-on-popup .createStepBtn .MuiButton-root {
  width: 120px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
.menu-add-on-popup .createStepBtn .MuiButton-root.cancelBtn {
  border: 1px solid #8C9195;
  color: #8C9195;
}

.crop-outer img {
  max-width: 800px !important;
  max-height: 380px !important;
}

.sort-category .location-head {
  margin-top: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.sort-category thead tr th {
  background: #f5f5f5;
}
.sort-category tr th, .sort-category tr td {
  background: #fff;
  border-bottom: 1px solid #F0F0F0;
  position: relative;
}
.sort-category .sub-addon-sort button {
  padding: 4px;
}

.admin-login {
  margin-top: -70px;
  background-image: url("/public/assets/images/adminlogin.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-login .login-box {
  background-color: #fff;
}
.admin-login .admin-login-logo {
  margin: 0 auto 25px;
  display: block;
}

.subhead.password-msg {
  position: absolute;
  bottom: -22px;
  line-height: 16px;
  display: block;
}

.textBoxTopMargin {
  margin-top: 50px;
}

.create-account-condition {
  margin-top: 10px;
}
.create-account-condition p {
  color: #FAA095;
}
.create-account-condition p a {
  color: #FAA095;
  text-decoration: underline;
}

.customer-profile {
  max-width: 700px;
  min-height: 320px;
  margin: 65px auto;
  padding: 26px 22px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);
}
.customer-profile .customer-profile-title {
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customer-profile .customer-profile-title h4 {
  display: flex;
  align-items: center;
}
.customer-profile .customer-profile-title h4 img {
  margin-right: 15px;
}
.customer-profile .customer-profile-title .ld-edit {
  font-weight: 600;
}
.customer-profile .customer-profile-details {
  color: #4C5A76;
}
.customer-profile .customer-profile-details label {
  font-size: 12px;
}
.customer-profile .customer-profile-details p {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
}

.admin-reset-password {
  padding: 18px;
}
.admin-reset-password .textField {
  width: 100%;
}
.admin-reset-password .textBox .error-msg {
  position: initial;
}

.create-step-form-admin-details {
  height: calc(100vh - 223px);
}

.cater-summary h4 {
  margin: 20px 0 20px;
  font-weight: 700;
}
.cater-summary .status-btn.MuiButtonBase-root {
  color: #fff;
}

.data-overview {
  margin: 30px 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: flex;
  background-color: #fff;
}
.data-overview .do-item {
  padding: 22px 20px;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  border-right: 0.1px solid rgba(0, 0, 0, 0.15);
}
.data-overview .do-item h6 {
  font-size: 16px;
  margin: 10px 0;
  line-height: 22px;
  font-weight: 600;
  color: #4C5A76;
}
.data-overview .do-item label {
  font-size: 20px;
  line-height: 27px;
  color: #4165AC;
  font-weight: 700;
  display: block;
}
.data-overview .do-item:last-child {
  border-right: 0;
}

.cater-summary .location-head .location-head-title h5 {
  font-size: 14px;
  text-decoration-line: underline;
  color: #7DA3C1;
}
.cater-summary .location-head .location-head-title button {
  width: 95px;
  height: 37px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  text-transform: capitalize;
}

.dashboard .row {
  --bs-gutter-x: 32px;
}
.dashboard .row .col-md-6 {
  margin-bottom: 32px;
}
.dashboard .cater-summary {
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
}
.dashboard .cater-summary h4.h4 {
  padding: 22px 20px;
  display: flex;
  font-weight: 600;
  margin: 0;
  background-color: #fff;
}
.dashboard .cater-summary h4.h4 img {
  margin-right: 15px;
}
.dashboard .commonTabel tbody tr th, .dashboard .commonTabel tbody tr td {
  padding: 15px 20px;
}
.dashboard .commonTabel tbody tr th .menu-list-img, .dashboard .commonTabel tbody tr td .menu-list-img {
  display: block;
  width: 56px;
  height: 45px;
}
.dashboard .customer-dashboard.commonTabel tbody tr td {
  height: 75.5px;
}

.contentWrap .location-head .location-head-title .admin-lfp-date input {
  margin-left: 0;
  padding: 0 10px;
}

.admin-lfp-date {
  position: relative;
}
.admin-lfp-date .admin-error-msg {
  position: absolute;
  top: -22px;
}

.refer-friend-action-btn.accept-disable {
  color: rgb(107, 107, 107) !important;
  background-color: rgb(92, 219, 135) !important;
}
.refer-friend-action-btn.declined-disable {
  color: rgb(236, 236, 236) !important;
  background-color: rgb(167, 167, 167) !important;
}
.refer-friend-action-btn.pending-disable {
  color: rgb(220, 220, 220) !important;
  background-color: rgb(254, 107, 137) !important;
}

.settled-on {
  font-size: 11px;
  padding: 3px 5px;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #8a8a8a;
  color: #fff;
  display: inline-block;
}

.main-admin-back-link {
  margin: 30px 0px 10px 20px;
  font-size: 12px;
  text-decoration: underline;
  color: #FD4E3B;
  display: inline-block;
}
.main-admin-back-link:hover {
  color: #000;
  text-decoration: underline;
}

.cater-profile-img {
  width: 55px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
}

.cater-tab {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.13);
}
.cater-tab .MuiTabs-root button {
  padding: 12px 50px;
  text-transform: capitalize;
}
.cater-tab .MuiTabs-root button.Mui-selected {
  font-weight: 600;
  color: #000;
}

.pageWrap h2 {
  margin-bottom: 25px;
  color: #646777;
}

.banner {
  height: 500px;
  background-image: url("/public/assets/images/search-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
.banner h1 {
  color: #FD4837;
}
.banner .banner-subtext {
  font-size: 16px;
  color: #fff;
}

.banner-search {
  border-radius: 6px;
  height: 85px;
  align-items: center;
}
.banner-search .MuiFormControl-root a {
  font-size: 12px;
  color: #FD4E3B;
  white-space: nowrap;
}
.banner-search .MuiOutlinedInput-root {
  padding: 10px !important;
}
.banner-search fieldset {
  border: 1px solid #D9E5F3;
  border-radius: 6px;
}

.banner-points {
  display: flex;
  align-items: center;
}
.banner-points .banner-points-item {
  display: flex;
  align-items: center;
  margin-right: 65px;
}
.banner-points .banner-points-item .banner-points-item-text {
  padding: 0 15px 0px 2px;
  margin-left: 6px; /*background-image: url('/public/assets/images/banner-border.svg');*/
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.banner-points .banner-points-item .banner-points-item-text h5 {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  line-height: 16px;
}

.testimonial-card {
  border-radius: 10px;
  border-top: 1px solid #FD4E3B !important;
  border-bottom: 1px solid #FD4E3B !important;
  padding: 18px 10px;
  height: 100%;
}
.testimonial-card .testimonial-name {
  font-size: 24px;
  font-weight: 600;
  color: #FD4E3B;
}
.testimonial-card .testimonial-job {
  font-size: 18px;
  font-weight: 500;
  color: #646777;
}

.banner-content {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.95);
  padding: 70px;
}

.home-cater-near {
  padding: 60px 0;
}
.home-cater-near .home-cater-near-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-cater-near .home-cater-near-title label {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  display: inline-block;
  margin-right: 10px;
}
.home-cater-near .home-cater-near-title button {
  padding: 3px;
}

.homeCaterSlider .slick-slider {
  overflow: hidden;
}
.homeCaterSlider .slick-list {
  margin: 0 -15px;
}
.homeCaterSlider .cater-list-item {
  padding: 0 15px;
}

.cater-list-item .cater-list-item-img {
  height: 250px;
  width: 100%;
  position: relative;
}
.cater-list-item .cater-list-item-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.cater-list-item .cater-list-item-img .cater-cash-tag {
  top: 20px;
  right: 20px;
}
.cater-list-item .cater-list-item-cnt {
  padding-top: 20px;
}
.cater-list-item .cater-list-item-cnt h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2C2D34;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.cater-list-item .cater-list-item-cnt h4 label {
  margin-left: 6px;
  display: flex;
  align-items: center;
}
.cater-list-item .cater-list-item-cnt h4 label img {
  margin-right: 5px;
}
.cater-list-item .cater-list-item-cnt h4 label span {
  font-size: 12px;
  color: #646777;
  margin-top: 2px;
}
.cater-list-item .cater-list-item-cnt h4 label span small {
  font-size: 10px;
  color: #B8BEC1;
}
.cater-list-item .cater-list-item-cnt h6 {
  margin: 10px 0 4px;
  font-size: 14px;
  text-transform: capitalize;
  color: #2C2D34;
}
.cater-list-item .cater-list-item-cnt .cater-list-order {
  font-size: 12px;
  color: #646777;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.cater-list-item .cater-list-item-cnt .cater-list-order span {
  display: flex;
  align-items: center;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #D9E5F3;
}
.cater-list-item .cater-list-item-cnt .cater-list-order span img {
  margin-right: 5px;
}
.cater-list-item .cater-list-item-cnt .cater-list-order span:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.testimonialSlider .slick-list {
  margin: 0 -25px;
}
.testimonialSlider .testimonial-item {
  padding: 0 25px;
}

.testimonial-author-img {
  margin-right: 10px;
  border-radius: 72px;
  border: 2px solid #FD4E3B;
  object-fit: cover;
}

.list-homepage {
  font-size: 20px;
}

.logo-carousel {
  padding: 100px 0 !important;
}

.testimonials {
  padding-bottom: 60px;
}
.testimonials .testimonial-item {
  margin-bottom: 40px;
}
.testimonials .testimonial-item-text {
  min-height: 175px;
  padding: 30px;
  background: #EBEBEB;
  border-radius: 10px;
  position: relative;
}
.testimonials .testimonial-item-text .pointer-mark {
  bottom: -16px;
  left: 40px;
  position: absolute;
}
.testimonials .testimonial-item-text p {
  font-size: 16px;
  line-height: 23px;
  color: #777;
}
.testimonials .testimonial-author {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonials .testimonial-author span b {
  font-size: 18px;
  line-height: 22px;
  color: #404040;
  font-weight: 700;
  display: block;
}
.testimonials .testimonial-author span label {
  font-size: 14px;
  line-height: 17px;
  color: #404040;
}

.home-works {
  padding: 60px 0;
  background: linear-gradient(180deg, #FF7C74 12.04%, #FFCE84 88.09%);
}
.home-works h2 {
  color: #fff;
}
.home-works .home-works-item {
  padding: 5px;
  background: #FFFFFF;
  border-radius: 10px;
}
.home-works .home-works-item .home-works-item-img {
  height: 350px;
  width: 100%;
}
.home-works .home-works-item .home-works-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.home-works .home-works-item .home-works-item-cnt {
  padding: 20px 50px;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  text-align: center;
}
.home-works .home-works-item .home-works-item-cnt h5 {
  margin-bottom: 0;
}
.home-works .home-works-item .home-works-item-cnt label {
  padding: 15px 0 12px;
  display: block;
}
.home-works .home-works-item .home-works-item-cnt a {
  color: #555;
  font-weight: 600;
  text-decoration: underline;
}

footer {
  padding: 30px 0;
  background-color: #fff;
  position: relative;
  z-index: 1;
}
footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .logo-social-link {
  display: flex;
  align-items: center;
}
footer .logo-social-link .footer-logo {
  height: 68px;
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid #D9E5F3;
}
footer .logo-social-link ul {
  display: flex;
  align-items: center;
}
footer .logo-social-link ul li {
  margin-right: 15px;
}
footer .logo-social-link ul li a {
  display: block;
}
footer .logo-social-link ul li a img {
  display: block;
}
footer .logo-social-link ul li:last-child {
  margin-right: 0;
}
footer .copy-terms {
  font-size: 14px;
  font-family: "Lato", sans-serif;
}
footer .copy-terms span a {
  color: #FD4E3B;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #FD4E3B;
}
footer .copy-terms span a:last-child {
  border-right: 0;
}
footer .copy-terms span a:hover {
  text-decoration: underline;
}

.listing-filter-primary {
  padding: 15px 0px;
  margin-bottom: 15px;
  border-bottom: 1px solid #D8D8D8;
}
.listing-filter-primary .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listing-filter-primary .lfp-label {
  font-size: 10px;
  color: #2C2D34;
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
}
.listing-filter-primary .lfp-delivery-address {
  min-width: 400px;
}
.listing-filter-primary .lfp-delivery-address .location-input {
  width: 100%;
}
.listing-filter-primary .lfp-delivery-address input {
  padding: 6px 5px !important;
  font-weight: 600;
  font-size: 14px;
  color: #2C2D34;
}
.listing-filter-primary .lfp-zip {
  margin-left: 30px;
  width: 120px;
}
.listing-filter-primary .lfp-zip input {
  padding: 15px !important;
  font-size: 14px;
  color: #2C2D34;
}
.listing-filter-primary .lfp-zip input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: initial;
}
.listing-filter-primary .lfp-date {
  margin: 0 30px;
}
.listing-filter-primary .lfp-date input {
  width: 275px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-weight: 600;
  font-size: 14px;
}
.listing-filter-primary .lfp-head-count {
  width: 150px;
}
.listing-filter-primary .lfp-head-count .MuiSelect-select {
  padding: 13.5px 9px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #2C2D34;
}
.listing-filter-primary .lfp-head-count .MuiSelect-select img {
  margin-right: 4px;
}
.listing-filter-primary .lfp-head-count input {
  padding: 13.5px 4px;
  color: #2C2D34;
  font-weight: 600;
}
.listing-filter-primary .lfp-head-count p {
  color: #2C2D34;
  font-weight: 600;
}
.listing-filter-primary .lfp-head-count.lfp-order-type {
  width: 200px;
  margin-right: 30px;
}
.listing-filter-primary .MuiInputAdornment-root {
  flex-shrink: 0;
}
.listing-filter-primary input::-webkit-input-placeholder {
  font-size: 12px;
  color: #646777;
  opacity: 1;
  letter-spacing: 0.5px;
  font-weight: normal;
}
.listing-filter-primary input:-moz-placeholder {
  font-size: 12px;
  color: #646777;
  opacity: 1;
  font-weight: normal;
}
.listing-filter-primary input::-moz-placeholder {
  font-size: 12px;
  color: #646777;
  opacity: 1;
  font-weight: normal;
}
.listing-filter-primary input:-ms-input-placeholder {
  font-size: 12px;
  color: #646777;
  opacity: 1;
  font-weight: normal;
}

.listing-filter-secondary {
  padding-bottom: 8px;
}
.listing-filter-secondary .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listing-filter-secondary h3 {
  font-size: 18px;
  font-weight: 600;
}
.listing-filter-secondary .lfs-item {
  margin-right: 20px;
  background-image: url("/public/assets/images/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 8px center;
}
.listing-filter-secondary .lfs-item .MuiSelect-icon {
  display: none;
}
.listing-filter-secondary .lfs-search {
  border-right: 1px solid #e8e8e8;
  padding-right: 20px;
  width: 230px;
  background-image: none;
}
.listing-filter-secondary .lfs-search input {
  padding: 7.5px 8px;
  padding-right: 0;
  font-size: 12px;
}
.listing-filter-secondary .lfs-search input::-webkit-input-placeholder {
  color: #2C2D34;
  opacity: 1;
}
.listing-filter-secondary .lfs-search input:-moz-placeholder {
  color: #2C2D34;
  opacity: 1;
}
.listing-filter-secondary .lfs-search input::-moz-placeholder {
  color: #2C2D34;
  opacity: 1;
}
.listing-filter-secondary .lfs-search input:-ms-input-placeholder {
  color: #2C2D34;
  opacity: 1;
}
.listing-filter-secondary .lfs-deitary {
  width: 150px;
}
.listing-filter-secondary .lfs-option {
  width: 180px;
}
.listing-filter-secondary .lfs-option .MuiSelect-select {
  padding-left: 30px !important;
  background-image: url("/public/assets/images/Options.svg");
  background-repeat: no-repeat;
  background-position: left 10px center;
}
.listing-filter-secondary .lfs-cuisnes {
  width: 150px;
}
.listing-filter-secondary .lfs-cuisnes .MuiSelect-select {
  padding-left: 30px !important;
  background-image: url("/public/assets/images/cusines.svg");
  background-repeat: no-repeat;
  background-position: left 10px center;
}
.listing-filter-secondary .lfs-sort {
  width: 160px;
  border-left: 1px solid #e8e8e8;
  padding-left: 20px;
}
.listing-filter-secondary .MuiSelect-select {
  padding: 4.5px 8px 4.5px 8px !important;
  font-size: 12px;
}
.listing-filter-secondary .MuiSelect-select img {
  margin-right: 8px;
}
.listing-filter-secondary fieldset {
  border-radius: 6px;
}
.listing-filter-secondary .MuiInputAdornment-root {
  flex-shrink: 0;
}
.listing-filter-secondary .lfs-clear-btn {
  height: 32px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}
.lfs-menuitem.MuiButtonBase-root {
  padding: 4px 8px;
  font-size: 12px !important;
}
.lfs-menuitem.MuiButtonBase-root img {
  margin-right: 8px;
}

.lfs-select-item .MuiTypography-root {
  font-size: 12px;
}

.cater-list {
  height: calc(100vh - 206.5px);
  overflow-y: scroll;
  padding: 8px 0 30px;
}
.cater-list .cater-list-item-outer {
  margin-bottom: 35px;
  height: 100%;
}

.detail-title-bar {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #D8D8D8;
}
.detail-title-bar .detail-main-points li {
  margin-right: 20px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000;
}
.detail-title-bar .detail-main-points li:last-child {
  margin-right: 0;
}
.detail-title-bar .detail-main-points li.dmp-locations {
  max-width: 380px;
}
.detail-title-bar .detail-main-points b {
  font-size: 16px;
  margin: 0 5px;
  font-weight: 700;
  display: block;
}
.detail-title-bar .detail-main-points .dmp-bg {
  padding: 0 10px;
  min-width: 38px;
  height: 38px;
  font-size: 12px;
  background-color: #F0F5FA;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detail-title-bar .detail-main-points span.dmp-bg {
  margin-right: 12px;
}

.menu-category {
  background-color: #D9E5F3;
  border-radius: 8px;
  margin-bottom: 60px;
}
.menu-category .menu-category-item {
  padding: 15px 10px;
  margin-right: 10px;
  color: #838592;
  text-transform: capitalize; /*text-align: center;*/
}
.menu-category .menu-category-item p {
  white-space: pre;
}
.menu-category .slick-track {
  margin-left: initial;
}
.menu-category.menu-category-new {
  display: flex;
  overflow: auto;
}
.menu-category.menu-category-new ul {
  display: flex;
  overflow: scroll;
}
.menu-category.menu-category-new ul li {
  margin-right: 15px;
}
.menu-category.menu-category-new ul li a {
  padding: 15px 12px;
  font-size: 16px;
  line-height: 22px;
  color: #838592;
  display: block;
}

.slick-next.menu-category-arrow {
  right: -24px;
  width: 30px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9e5f3;
  border-radius: 0 8px 8px 0;
  z-index: 10;
}
.slick-next.menu-category-arrow:before {
  display: none;
}
.slick-next.menu-category-arrow img {
  width: 10px;
}

.slick-prev.menu-category-arrow {
  left: -24px;
  width: 30px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9e5f3;
  border-radius: 8px 0px 0px 8px;
  z-index: 10;
}
.slick-prev.menu-category-arrow:before {
  display: none;
}
.slick-prev.menu-category-arrow img {
  width: 10px;
}

.menu-category-listing {
  margin-bottom: 30px;
}
.menu-category-listing .menu-category-listing-col {
  margin-bottom: 30px;
  position: relative;
  z-index: 1; /*border-bottom: 0.6px solid #D8D8D8;*/
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu-category-listing .menu-category-listing-col::after {
  position: absolute;
  content: "";
  right: 0px;
  top: 0;
  bottom: 30px;
  width: 0.6px;
  background-color: #d8d8d8;
}
.menu-category-listing .menu-category-listing-col:nth-child(even):after, .menu-category-listing .menu-category-listing-col:nth-last-child(1):nth-child(odd):after {
  display: none;
}
.menu-category-listing .menu-category-listing-col:nth-last-child(1):nth-child(odd) {
  border-bottom: none;
}
.menu-category-listing .menu-category-listing-col::before {
  position: absolute;
  height: 0.6px;
  bottom: 0;
  background-color: #D8D8D8;
  content: "";
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu-category-listing .menu-category-listing-col:nth-child(odd)::before {
  left: 24px;
  right: 0;
}
.menu-category-listing .menu-category-listing-col:nth-last-child(1):nth-child(odd)::before {
  height: 0;
}
.menu-category-listing .menu-category-listing-col:nth-child(even)::before {
  left: 0;
  right: 24px;
}
.menu-category-listing .menu-category-listing-col:hover::before {
  background-color: #FD4E3B;
  height: 1px;
}
.menu-category-listing .menu-category-listing-col:hover .menu-category-listing-item h3 {
  color: #FD4E3B;
}
.menu-category-listing .menu-category-listing-col:hover .menu-category-listing-item p {
  color: #000;
}
.menu-category-listing .menu-category-listing-col .item-add-btn {
  margin-top: 10px;
  height: 30px;
  font-size: 14px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu-category-listing .menu-category-listing-col:hover .item-add-btn {
  background-color: #FD4E3B;
  color: #fff;
}
.menu-category-listing .menu-category-listing-item {
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}
.menu-category-listing .menu-category-listing-item h3 {
  text-transform: capitalize;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu-category-listing .menu-category-listing-item .dietry-label {
  font-size: 14px;
  margin-top: 7px;
  margin-left: 0;
  cursor: pointer;
}
.menu-category-listing .menu-category-listing-item .menu-category-listing-item-img {
  margin-left: 15px;
  flex-shrink: 0;
}
.menu-category-listing .menu-category-listing-item .menu-category-listing-item-img img {
  width: 140px;
  height: 150px;
  object-fit: cover;
  display: block;
  border-radius: 3px;
}
.menu-category-listing .menu-category-listing-item p {
  font-size: 14px;
  line-height: 22px;
  color: #646777;
  letter-spacing: 0.8px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.menu-category-listing .menu-category-listing-item.skeleton h3 .MuiSkeleton-root {
  width: 320px;
  height: 22px;
}
.menu-category-listing .menu-category-listing-item.skeleton .dietry-label .MuiSkeleton-root {
  width: 150px;
  height: 22px;
}
.menu-category-listing .menu-category-listing-item.skeleton .price-time .MuiSkeleton-root {
  width: 200px;
  height: 22px;
}
.menu-category-listing .menu-category-listing-item.skeleton p .MuiSkeleton-root {
  width: 420px;
  height: 20px;
}
.menu-category-listing .price-time {
  padding: 4px 0 10px;
  font-size: 14px;
  font-weight: 700;
  color: #616161;
  display: flex;
  align-items: center;
}

.cater-detail-box {
  padding: 20px;
  margin-bottom: 35px;
  box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #646777;
  font-size: 14px;
}
.cater-detail-box h4 {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EFEFEF;
  color: #000;
}
.cater-detail-box h4 img {
  margin-right: 12px;
}

.dietry-label {
  font-size: 16px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.dietry-label img {
  margin-right: 3px;
}

.review .review-title {
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}
.review .review-title h4 {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 0;
}
.review .rating {
  margin-bottom: 20px;
  letter-spacing: 0.8px;
}
.review .rating .rating-star img {
  margin-right: 12px;
}
.review .rating h6 {
  margin: 12px 0;
  font-weight: 600;
}
.review .rating p {
  line-height: 22px;
}

.add-review-btn.MuiButtonBase-root {
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  text-transform: inherit;
}

.detail-tagline .detail-tagline-item {
  margin-bottom: 20px;
}
.detail-tagline .detail-tagline-item h5 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.detail-tagline .detail-tagline-item p a {
  color: #FAA095;
  font-weight: 600;
}
.detail-tagline .detail-tagline-item h6 {
  font-size: 16px;
  color: #000;
}
.detail-tagline .detail-tagline-item h6 span {
  font-size: 12px;
  font-weight: 400;
}

.cater-detail-box.detail-ordering-hours {
  padding-bottom: 40px;
  margin-bottom: 0;
}

.cater-detail-timing {
  display: flex;
  flex-wrap: wrap;
}
.cater-detail-timing li {
  width: calc((100% - 60px) / 3);
  padding: 15px 0; /*margin-bottom: 15px;margin-right: 20px;*/
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #EFEFEF;
  color: #3E3C37;
}
.cater-detail-timing li b {
  font-weight: 600;
}
.cater-detail-timing li:nth-child(7), .cater-detail-timing li:nth-child(8) {
  border-bottom: 0 solid #000;
}
.cater-detail-timing li:nth-child(3n+2) {
  position: relative;
  margin: 0 30px;
}
.cater-detail-timing li:nth-child(3n+2)::before, .cater-detail-timing li:nth-child(3n+2)::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #EFEFEF;
}
.cater-detail-timing li:nth-child(3n+2)::before {
  left: -15px;
}
.cater-detail-timing li:nth-child(3n+2)::after {
  right: -15px;
}
.cart-view-col {
  background-color: #F0EDED;
  height: fit-content;
}

.other-charges {
  padding: 10px 0;
}
.other-charges .other-charges-item {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.common-cart-viewbox {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.6px solid #D8D8D8;
  border-width: 0.6px 0;
}
.common-cart-viewbox label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.common-cart-viewbox label button {
  margin-left: 8px;
  padding: 0;
}
.common-cart-viewbox.common-cart-viewbox-tip {
  align-items: flex-start;
  border-top: 0.6px solid #D8D8D8;
}

.tip-btn {
  display: flex;
}
.tip-btn button {
  min-width: 35px;
  padding: 5px 4px;
  margin-right: 3px;
  font-size: 11px;
  font-weight: 700;
  background: #E8E8E8;
  border: 1px solid #9598A8;
  color: #9598A8;
}
.tip-btn button.tip-select {
  background: rgba(250, 160, 149, 0.12);
  border: 1px solid #FAA095;
  color: #FD4E3B;
}
.tip-btn button:last-child {
  margin-right: 0;
}

.other-amount {
  display: block;
}
.other-amount a {
  margin-top: 8px;
  font-size: 12px;
  color: #FAA095 !important;
  cursor: pointer;
  display: block;
}
.other-amount a:hover {
  color: #FD4E3B !important;
  display: block;
}
.other-amount .MuiInputBase-input {
  padding: 10px;
  padding-left: 0;
}
.other-amount input[type=number]::-webkit-inner-spin-button,
.other-amount input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.other-amount input[type=number] {
  -moz-appearance: textfield;
}

.other-amount-feild {
  width: 152px !important;
  animation: fadein 0.5s;
  margin-bottom: 0 !important;
}
.other-amount-feild .MuiInputBase-input {
  padding: 10px;
}
.other-amount-feild.money-input {
  padding-left: 0;
}
.other-amount-feild input[type=number]::-webkit-inner-spin-button,
.other-amount-feild input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.other-amount-feild input[type=number] {
  -moz-appearance: textfield;
}

.cart-total {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 700;
  border-top: 0;
}

.cart-price-perhead {
  font-size: 16px;
  border-top: 0;
}
.cart-price-perhead b {
  font-weight: 700;
  color: #FAA095;
}

.cart-catercash {
  padding: 10px 0 20px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-catercash img {
  margin-right: 10px;
}
.cart-catercash b {
  font-weight: 700;
  color: #FAA095;
}

.caterer-menu-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.caterer-menu-modal .caterer-menu-details {
  max-height: 80vh;
  width: 720px;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #F5F5F5;
  border-radius: 5px 5px 0px 0px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-header h5 {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: capitalize;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-header small {
  margin-top: 2px;
  font-size: 12px;
  line-height: 16px;
  display: block;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content {
  max-height: calc(80vh - 158px);
  padding: 20px;
  font-size: 12px;
  line-height: 18px;
  color: #3E3C37;
  overflow: auto;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .h5 {
  margin-top: 20px;
  margin-bottom: 5px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content small {
  font-size: 12px;
  line-height: 13px;
  display: block;
  color: #646777;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .MuiFormGroup-root {
  margin-top: 20px;
  flex-wrap: initial;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .MuiFormGroup-root .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .MuiFormGroup-root .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  padding-left: 9px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content p {
  text-transform: capitalize;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content p.caterer-menu-description {
  text-transform: none !important;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .extra-charges {
  font-size: 12px;
  color: #000;
  font-weight: 700;
  display: block;
  line-height: 16px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .addon-active {
  border: 1px solid #D9E5F3;
  padding: 0 15px 20px;
  animation: fadein 1s;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .addon-active .mainAddonCheck {
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
  border-bottom: 1px solid #D9E5F3;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-content .addon-active .mainAddonCheck .MuiTypography-root {
  font-weight: 600;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer {
  height: 94px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty {
  display: flex;
  align-items: center;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty h6 {
  font-size: 16px;
  font-weight: 700;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit {
  width: 180px;
  height: 46px;
  padding: 12px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #000000;
  border-radius: 22px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit button.MuiButtonBase-root {
  padding: 4px;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit .form-control {
  max-width: 40px;
  padding: 0px;
  font-size: 16px;
  border: 0;
  color: #000;
  font-weight: 700;
  text-align: center;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit input[type=number]::-webkit-inner-spin-button,
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty .menu-qty-edit input[type=number] {
  -moz-appearance: textfield;
}
.caterer-menu-modal .caterer-menu-details .caterer-menu-footer .cart-price-btn {
  width: 300px;
  height: 55px;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.caterer-menu-modal .caterer-menu-details:focus {
  outline: none;
}
.caterer-menu-modal .caterer-menu-details:active {
  outline: none;
}
.caterer-menu-modal:focus {
  outline: none;
}
.caterer-menu-modal:active {
  outline: none;
}

.common-view-box-wrap {
  padding-top: 20px;
}

.common-view-box-title {
  min-height: 62px;
  margin-top: 20px;
  padding: 10px;
  background: #F5F5F5;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.common-view-box-title h5 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.common-view-box-title small {
  margin-top: 3px;
  font-size: 10px;
  line-height: 14px;
  color: #646777;
  display: block;
}

.common-view-box {
  padding: 20px;
  border: 1px solid #F0EDED;
  border-radius: 0 0 5px 5px;
  border-top: 0;
}
.common-view-box h6 {
  margin-bottom: 20px;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.common-view-box .textField {
  width: 100%;
  margin-bottom: 25px;
}
.common-view-box .create-account-condition {
  margin-top: 0;
}
.common-view-box .create-account-condition .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}
.common-view-box .create-account-condition p {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.back-link {
  font-size: 12px;
  color: #FD4E3B;
  text-decoration-line: underline;
}
.back-link:hover {
  color: #000;
  text-decoration: underline;
}

.customer-profile-wrap {
  padding: 50px 0;
}
.sepration-line {
  margin: 0 -22px;
  height: 0.6px !important;
  border: 0;
  opacity: 1;
  background-color: #D8D8D8;
}

.review-order-datepicker input {
  width: 100%;
  height: 56px;
  padding: 16.5px 14px 16.5px 35px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

.added-card {
  padding-top: 20px;
}
.added-card h6 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}
.added-card .saved-card-item {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.added-card .saved-card-item .card-image-number {
  margin-left: 10px;
}
.added-card .saved-card-item .card-image-number .card-image {
  margin-right: 6px;
}
.added-card .saved-card-item .card-image-number .card-image img {
  width: 32px;
  height: 20px;
  object-fit: cover;
  display: block;
}
.added-card .saved-card-item .card-image-number label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.added-card .saved-card-item .ld-edit {
  padding-right: 15px;
  margin-right: 7px;
  font-size: 12px;
  line-height: 16px;
  border-right: 0.6px solid #D8D8D8;
}
.added-card .saved-card-item .card-delete:hover img {
  filter: invert(44%) sepia(61%) saturate(3524%) hue-rotate(337deg) brightness(103%) contrast(107%);
}
.added-card .saved-card-item:last-child {
  margin-bottom: 10px;
  border-bottom: 0.6px solid #D8D8D8;
}
.added-card .saved-card-item .MuiFormControlLabel-root {
  margin-right: 0;
}

.add-card-btn.MuiButtonBase-root {
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #646777;
  border: 1px solid #d0d0d0;
}
.add-card-btn.MuiButtonBase-root img {
  margin-right: 5px;
}

.add-new-card {
  padding-top: 15px;
}
.add-new-card h6 {
  margin-top: 20px;
}
.add-new-card p {
  font-size: 12px;
  color: #2C2D34;
}

.cancel-save-btn {
  margin-top: 10px;
  text-align: right;
}
.cancel-save-btn button {
  width: 170px;
  height: 42px;
  font-size: 14px;
  color: #646777;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: none;
}
.cancel-save-btn button.cancelBtn {
  margin-right: 25px;
  background-color: #f8f8f8;
}
.cancel-save-btn button.saveBtn {
  color: #fff;
}

.review-order-item {
  margin-top: 20px;
}
.review-order-item .roi-single {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 0.6px solid #D8D8D8;
}
.review-order-item .roi-details {
  padding-right: 50px;
}
.review-order-item .roi-details h6 {
  margin-bottom: 0;
}
.review-order-item .roi-details .roi-subaddon-wrap {
  margin-top: 10px;
}
.review-order-item .roi-details p {
  font-size: 14px;
  line-height: 18px;
  color: #646777;
}
.review-order-item .roi-sp-instr {
  padding: 12px 0;
  padding-right: 30px;
}
.review-order-item .roi-sp-instr h6 {
  font-size: 14px;
  margin-bottom: 10px;
}
.review-order-item .roi-sp-instr p {
  font-size: 14px;
  line-height: 16px;
  color: #646777;
}
.review-order-item .roi-edit {
  font-size: 12px;
  color: #FAA095;
  display: inline-block;
  line-height: 14px;
}
.review-order-item .roi-count-edit {
  width: 85px;
  height: 30px;
  padding: 2px 4px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #646777;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.review-order-item .roi-count-edit button {
  padding: 4px;
}
.review-order-item .roi-count-edit button img {
  width: 9px;
}
.review-order-item .roi-price {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.roi-subaddon span:last-child b {
  display: none;
}

.review-order-price {
  margin-top: 10px;
}
.review-order-price .common-cart-viewbox {
  padding: 10px 0;
}
.review-order-price .other-charges {
  padding: 0px 0;
  border-top: 0.6px solid #D8D8D8;
}
.review-order-price .other-charges .other-charges-item {
  padding: 5px 0;
}
.review-order-price .other-charges .other-charges-item label {
  font-size: 12px;
}
.review-order-price .other-charges .other-charges-item label button {
  margin-left: 8px;
  padding: 0;
}
.review-order-price .other-charges .other-charges-item b {
  font-size: 16px;
  font-weight: 600;
}
.review-order-price .other-amount a {
  margin-top: 0;
}
.review-order-price .common-cart-viewbox label {
  font-size: 12px;
}
.review-order-price .common-cart-viewbox.common-cart-viewbox-tip {
  align-items: center;
}
.review-order-price .common-cart-viewbox.common-cart-viewbox-tip b {
  font-weight: 600;
}
.review-order-price .tip-btn button {
  width: 45px;
  height: 35px;
  margin-right: 8px;
}
.review-order-price .cart-price-perhead b {
  font-size: 12px;
  color: #646777;
}
.review-order-price .cart-catercash {
  padding-bottom: 0;
  justify-content: flex-end;
}
.review-order-price .cart-catercash b {
  color: #646777;
}
.review-order-price .add-promo-code .MuiInputBase-root {
  height: 35px;
  width: 150px;
  font-size: 12px;
}
.review-order-price .add-promo-code .MuiInputBase-root:focus {
  outline: 0;
  box-shadow: none;
  border-color: #FD4E3B;
}
.review-order-price .add-promo-code button {
  height: 35px;
}
.review-order-price .add-promo-code button.promo-code {
  margin-right: 8px;
  font-size: 12px;
  color: #FD4E3B;
  background: rgba(250, 160, 149, 0.12);
  border: 1px solid #FAA095;
  font-weight: 700;
}

.review-order .review-order-price .cart-total.common-cart-viewbox label {
  font-size: 14px;
}

.confirm-place-btn.MuiButtonBase-root {
  height: 55px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  padding: 6px 30px;
}
.confirm-place-btn.MuiButtonBase-root img {
  margin-right: 10px;
}

.order-confirm-screen {
  height: calc(100vh - 203px);
}
.order-confirm-screen .ocs-img {
  text-align: center;
}
.order-confirm-screen .ocs-img img {
  margin: auto;
  display: block;
}
.order-confirm-screen .ocs-img h5 {
  margin-top: 45px;
  font-weight: 700;
}
.order-confirm-screen .ocs-text {
  justify-content: center;
  width: 60%;
}
.order-confirm-screen .ocs-text h1 {
  margin-bottom: 15px;
  color: #FD4E3B;
}
.order-confirm-screen .ocs-text p {
  color: #646777;
}
.order-confirm-screen .ocs-text p b {
  font-weight: 700;
}
.order-confirm-screen .ocs-text p a {
  color: #FD4E3B;
  font-weight: 700;
  text-decoration: underline;
}
.order-confirm-screen .ocs-text .saveBtn {
  min-width: 160px;
}
.order-confirm-screen .valign {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
  justify-content: center;
}

.customer-login-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customer-login-outer .ld-edit {
  font-size: 12px;
  text-decoration: underline;
}
.customer-login-outer .ld-edit:hover {
  font-weight: 600;
}

.customer-login {
  width: 400px;
  margin-top: 20px;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.04));
}
.customer-login .customer-login-title {
  height: 70px;
  padding: 0 20px;
  background: #F5F5F5;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.customer-login .customer-login-title h5 {
  font-weight: 700;
  margin-bottom: 0;
}
.customer-login .customer-login-title small {
  font-size: 10px;
  line-height: 12px;
  display: block;
}
.customer-login .login-box.create-step-form {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  border-radius: 0px 0px 5px 5px;
}

.common-dialog-box .dialog-box-title {
  padding-top: 10px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #CFD7E3;
}
.common-dialog-box .dialog-box-title h5 {
  color: #FD4E3B;
  margin-bottom: 0;
  font-weight: 700;
}
.common-dialog-box .dialog-box-text {
  width: 85%;
  margin: 10px auto;
  text-align: center;
}
.common-dialog-box .dialog-box-text h5 {
  margin-bottom: 0;
  color: #2C2D34;
  font-weight: 600;
}
.common-dialog-box .MuiDialogActions-root {
  padding: 0 24px 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.common-dialog-box .MuiDialogActions-root button {
  width: 100%;
  height: 45px;
  font-size: 18px;
  font-weight: 600;
}
.common-dialog-box .MuiDialogActions-root button.dailog-cancel-btn {
  border-color: #9598A8;
  color: #646777;
}
.common-dialog-box.vouceher-box h2 {
  padding: 0;
}
.common-dialog-box.vouceher-box .dialog-box-title {
  padding: 20px 0;
  margin-bottom: 20px;
}
.common-dialog-box.vouceher-box .amazon-gift-img {
  height: 40px;
}
.common-dialog-box.vouceher-box .h6 {
  margin: 10px 0 15px;
}
.common-dialog-box.vouceher-box i {
  margin-top: 20px;
  font-size: 14px;
  color: #4b4b4b;
  font-style: italic;
  display: block;
}
.common-dialog-box.vouceher-box .MuiDialogActions-root {
  padding-top: 30px;
  border-top: 1px solid #CFD7E3;
}
.common-dialog-box.review-box .MuiPaper-root {
  width: 600px;
}
.common-dialog-box.review-box .dialog-box-title h5 {
  text-align: left;
  font-size: 22px;
}
.common-dialog-box.review-box .review-star {
  margin-top: 10px;
}
.common-dialog-box.review-box .review-textbox {
  margin-top: 30px;
  margin-bottom: 10px;
}

.review-star-item span {
  margin-right: 5px;
}

.contentWrap-order-view {
  padding-top: 20px;
  padding-bottom: 50px;
}

.back-link-order {
  margin-top: 0px;
  margin-bottom: 18px;
  font-size: 16px;
  color: #FAA095;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.back-link-order img {
  margin-right: 5px;
}
.back-link-order:hover {
  color: #FD4E3B;
}
.back-link-order:hover img {
  filter: invert(47%) sepia(85%) saturate(2998%) hue-rotate(335deg) brightness(96%) contrast(108%);
}

.order-view-title {
  min-height: 65px;
  padding: 14px 10px;
  background: #F5F5F5;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-view-title h5 {
  font-weight: 700;
  color: #2d2c34;
  margin-bottom: 0;
}
.order-view-title h5 span {
  padding-left: 12px;
  margin-left: 12px;
  border-left: 0.6px solid #B4B4B4;
  display: inline-block;
}

.status-btn.MuiButtonBase-root {
  width: 100px;
  height: 35px;
  border-radius: 8px;
  font-size: 14px;
  color: #323232;
  font-family: "Lato", sans-serif;
  box-shadow: none !important;
}

.order-view {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #F0EDED;
  border-radius: 0 0 5px 5px;
}
.order-view .order-view-address-wrap {
  padding-bottom: 30px;
  border-bottom: 0.6px solid #D8D8D8;
}
.order-view .order-view-address-wrap h6 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 12px;
}
.order-view .order-view-address {
  display: flex;
  align-items: flex-start;
}
.order-view .order-view-address .MuiAvatar-root {
  width: 65px;
  height: 65px;
  margin-right: 16px;
  object-fit: cover;
  font-size: 24px;
}
.order-view .order-view-address p {
  font-size: 12px;
  line-height: 16px;
}
.order-view .order-view-address p label {
  color: #646777;
  display: inline-block;
  min-width: 45px;
}
.order-view .od-common-box {
  padding: 12px 0;
  border-bottom: 0.6px solid #D8D8D8;
}
.order-view .od-common-box h6 {
  font-size: 16px;
  color: #000000;
  margin-bottom: 12px;
}
.order-view .od-common-box p {
  font-size: 12px;
  color: #646777;
}
.order-view .review-order-item h6 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}
.order-view .roi-count-price {
  font-weight: 600;
}
.order-view .review-order-price h6 {
  font-size: 16px;
  margin-bottom: 20px;
}
.order-view .review-order-price .other-charges .other-charges-item b {
  font-size: 14px;
  font-weight: 700;
}
.order-view .review-order-price .common-cart-viewbox label {
  font-size: 14px;
}
.order-view .review-order-price .common-cart-viewbox b {
  font-size: 14px;
  font-weight: 700;
}
.order-view .review-order-price .common-cart-viewbox.common-cart-viewbox-tip label {
  font-size: 12px;
}
.order-view .review-order-price .common-cart-viewbox.cart-price-perhead label {
  font-size: 12px;
}
.order-view .review-order-price .common-cart-viewbox.cart-price-perhead b {
  font-size: 12px;
}
.order-view .order-view-add-custom-item {
  margin-bottom: 20px;
}
.order-view .order-view-add-custom-item h6 {
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #d8d8d8;
  color: #646777;
  animation: fadein 1s;
  font-weight: normal;
}
.order-view .order-view-add-custom-item .order-view-add-custom-item-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  animation: fadein 1s;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.order-view .order-view-add-custom-item .order-view-add-custom-item-btn:hover {
  color: #FD4E3B;
}
.order-view .order-view-add-custom-item form {
  animation: fadein 1s;
}
.order-view .order-view-add-custom-item .create-step-form {
  padding-top: 15px;
}
.order-view .order-view-add-custom-item .create-step-form h6 {
  font-size: 16px;
  color: #000;
  border: 0;
}
.order-view .order-view-add-custom-item .create-step-form .allFeild {
  margin-bottom: 30px;
}
.order-view .order-view-add-custom-item .create-step-form .textBox .error-msg {
  bottom: 10px;
}
.order-view .order-view-add-custom-item .add-custom-btn {
  margin-top: -10px;
  padding-top: 15px;
  text-align: end;
  border-top: 1px solid #d8d8d8;
}
.order-view .order-view-add-custom-item .add-custom-btn button {
  height: 40px;
  width: 100px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 400;
}
.order-view .order-view-add-custom-item .add-custom-btn button.cancel-btn {
  margin-right: 35px;
  border: 1px solid #D9E5F3;
  color: #383A40;
}
.order-view .review-order-item .roi-sp-instr h6 {
  font-size: 14px;
}

.order-status-wrap {
  margin-top: 40px;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}
.order-status-wrap.order-status-wrap-bottom {
  margin-top: 40px;
}

.order-status {
  background-color: #fff;
  padding: 20px 18px;
}
.order-status .os-update {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F0EDED;
}
.order-status .os-update button {
  width: 135px;
  height: 56px;
  margin-left: 15px;
  background: #FD4E3B;
  border-radius: 6px;
  box-shadow: none;
  flex-shrink: 0;
  font-weight: 600;
  color: #fff;
}
.order-status .os-update button.Mui-disabled {
  background: #FAA095;
}
.order-status fieldset {
  border-color: #D9E5F3;
}
.order-status .MuiTextField-root {
  width: 100%;
}
.order-status h6 {
  font-size: 16px;
  margin-bottom: 8px;
}
.order-status .os-note {
  padding-top: 16px;
}
.order-status .os-note .os-add-note-btn {
  width: 100px;
  height: 40px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border-radius: 6px;
}
.order-status .os-notes-history {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #F0EDED;
}
.order-status .os-notes-history .os-notes-history-cnt {
  padding: 16px;
  height: 78px;
  background: #FAFAFB;
}
.order-status .os-notes-history .os-notes-history-cnt p {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.order-status .os-notes-history .os-notes-history-cnt time {
  font-size: 12px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.order-invoice .order-view {
  border-radius: 0;
}
.order-invoice .review-order-item .roi-single:last-child {
  margin-bottom: 0;
}
.order-invoice .common-cart-viewbox.tableware {
  padding: 15px 0;
  margin-bottom: 10px;
}
.order-invoice .common-cart-viewbox.tableware b {
  font-weight: 600;
}
.order-invoice .review-order-price {
  margin-top: 0;
}
.order-invoice .review-order-price .other-charges {
  border-top: 0;
}
.order-invoice .review-order-price .other-charges .other-charges-item {
  padding: 8px 0;
}
.order-invoice .review-order-price .other-charges .other-charges-item label {
  font-size: 14px;
}
.order-invoice .review-order-price .other-charges .other-charges-item b {
  font-weight: 600;
}
.order-invoice .roi-sp-instr h6 {
  font-size: 14px;
  margin-bottom: 8px;
}
.order-invoice .review-order-price .common-cart-viewbox.cart-total {
  padding: 15px 0;
  margin-top: 10px;
}
.order-invoice .caterer-menu-footer {
  gap: 20px;
}
.order-invoice .caterer-menu-footer button {
  height: 55px;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
}
.order-invoice .caterer-menu-footer button.cancel-btn {
  border: 1px solid #D9E5F3;
  color: #383A40;
}

.issue-refund button {
  background: #FD982F;
  border-radius: 6px;
  font-weight: 600;
}

.caterer-menu-details.refund-modal {
  width: 520px;
}
.caterer-menu-details.refund-modal .caterer-menu-content {
  max-height: calc(70vh - 213px);
}
.caterer-menu-details.refund-modal .caterer-menu-content .MuiFormGroup-root {
  margin-top: 10px;
  margin-bottom: 18px;
}
.caterer-menu-details.refund-modal .caterer-menu-content .MuiFormGroup-root .MuiFormControlLabel-root {
  margin-right: 30px;
}
.caterer-menu-details.refund-modal .refund-tabs button {
  width: 33%;
  height: 55px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: inherit;
  color: #000;
}
.caterer-menu-details.refund-modal .refund-tabs .MuiTabs-indicator {
  height: 3px;
}
.caterer-menu-details.refund-modal .refund-tabs-content .h6 {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #000;
}
.caterer-menu-details.refund-modal .refund-tabs-content p {
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #646777;
}
.caterer-menu-details.refund-modal .refund-tabs-content .input-group {
  margin-bottom: 20px;
  width: 200px;
}
.caterer-menu-details.refund-modal .refund-tabs-content .input-group .form-control {
  font-size: 12px;
  color: #2C2D34;
}
.caterer-menu-details.refund-modal .refund-tabs-content .form-control {
  border: 1px solid #D9E5F3;
  border-radius: 4px;
  height: 42px;
}
.caterer-menu-details.refund-modal .refund-tabs-content .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.caterer-menu-details.refund-modal .refund-tabs-content .form-control::-webkit-input-placeholder {
  font-size: 10px;
  color: #ACAEB8;
}
.caterer-menu-details.refund-modal .refund-tabs-content .form-control::-moz-placeholder {
  font-size: 10px;
  color: #ACAEB8;
}
.caterer-menu-details.refund-modal .refund-tabs-content .form-control:-ms-input-placeholder {
  font-size: 10px;
  color: #ACAEB8;
}
.caterer-menu-details.refund-modal .refund-tabs-content .form-control:-moz-placeholder {
  font-size: 10px;
  color: #ACAEB8;
}
.caterer-menu-details.refund-modal .refund-tabs-content .refund-price {
  margin-top: 20px;
  padding-top: 25px;
  border-top: 0.6px solid #D8D8D8;
}
.caterer-menu-details.refund-modal .refund-tabs-content .refund-price .refund-price-item {
  color: #000;
  border-bottom: 0.6px solid #D8D8D8;
  padding-bottom: 14px;
  margin-bottom: 14px;
}
.caterer-menu-details.refund-modal .refund-tabs-content .refund-price .refund-price-item b {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  display: block;
}
.caterer-menu-details.refund-modal .refund-tabs-content .refund-price .refund-price-item span {
  font-size: 12px;
  line-height: 16px;
  display: block;
}
.caterer-menu-details.refund-modal .refund-tabs-content .refund-price .refund-price-item .refund-price-item-child {
  margin-bottom: 20px;
}
.caterer-menu-details.refund-modal .caterer-menu-footer {
  gap: 20px;
}
.caterer-menu-details.refund-modal .caterer-menu-footer button {
  height: 54px;
  width: 50%;
  border-radius: 6px;
}
.caterer-menu-details.refund-modal .caterer-menu-footer .cancelBtn {
  color: #383A40;
  border: 1px solid #D9E5F3;
}
.caterer-menu-details.refund-modal .caterer-menu-footer .saveBtn {
  color: #fff;
  font-weight: 600;
}

.cater-summary .location-head.catercash-head .location-head-title h5 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.gift-code {
  padding: 20px;
  gap: 20px;
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.gift-code b {
  margin-right: 20px;
  font-weight: 700;
  display: inline-block;
}

.catercash-overview {
  gap: 20px;
  padding: 20px;
  margin-top: 22px;
  display: flex;
  background-color: #fff;
}
.catercash-overview .cc-item {
  height: 140px;
  text-align: center;
  border: 1px solid;
  border-radius: 5px;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.catercash-overview .cc-item h2 {
  font-size: 44px;
  line-height: 60px;
  margin-bottom: 5px;
  font-weight: 700;
}
.catercash-overview .cc-item label {
  font-size: 14px;
  margin-left: 5px;
  display: block;
  font-weight: 700;
}
.catercash-overview .cc-item.cc-total {
  background: #F4F9FF;
  border-color: #5784BB;
  color: #2D71C5;
}
.catercash-overview .cc-item.cc-redeemed {
  background: rgba(253, 157, 57, 0.12);
  border-color: #FD9D39;
  color: #FD9D39;
}
.catercash-overview .cc-item.cc-current {
  background: #F4F9FF;
  border-color: #6BBB57;
  color: #6BBB57;
}
.catercash-overview .cc-item.cc-expired {
  background: rgba(187, 87, 119, 0.0784313725);
  border-color: #bb5777;
  color: #bb5777;
}
.catercash-overview .cc-item.cc-gift {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F4F2F2 100%);
  border-color: #9598A8;
  color: #4C5A76;
}
.catercash-overview .cc-item.cc-gift small {
  font-size: 10px;
  color: #4C5A76;
  display: block;
  margin: 0px 0 10px;
}
.catercash-overview .cc-item.cc-gift .amazon-btn {
  display: flex;
  gap: 8px;
}
.catercash-overview .cc-item.cc-gift .amazon-btn button {
  width: 55px;
  min-width: 32px;
  background: #DEDEDE;
  border-radius: 5px;
  font-size: 16px;
  color: #4C5A76;
  font-weight: 700;
}

.catercash-editor {
  border: 1px solid #d9e5f3;
  padding: 15px;
}
.catercash-editor .MuiFormLabel-root {
  margin-bottom: 10px;
}

.refer-friend {
  max-width: 680px;
  margin: 0px auto;
  padding: 50px 0;
}
.refer-friend .refer-friend-inner {
  padding: 20px;
  margin-top: 10px;
  background: #FFFFFF;
  box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}
.refer-friend .refer-friend-inner h1 {
  margin-bottom: 30px;
  color: #FD4E3B;
  text-align: center;
}
.refer-friend .refer-friend-inner p {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}
.refer-friend .refer-friend-inner h6 {
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #000;
}
.refer-friend .refer-friend-inner h6 img {
  margin-right: 5px;
}
.refer-friend .refer-friend-inner b {
  margin-bottom: 15px;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.38);
  display: block;
}
.refer-friend .refer-friend-inner .login-box.create-step-form {
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.refer-friend .refer-friend-inner .invite-btn {
  width: 151px;
  height: 42px;
  margin-top: 10px;
  background: #FD4E3B;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}

.address-wrap .address-wt-box {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}
.address-wrap .address-map {
  height: 400px;
  width: 100%;
}
.address-wrap .address-title-bar {
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #EFEFEF;
}
.address-wrap .address-title-bar h4 img {
  margin-right: 10px;
}
.address-wrap .address-title-bar .ld-edit {
  font-weight: 600;
}
.address-wrap .address-detail label {
  font-size: 12px;
  padding-bottom: 4px;
  letter-spacing: 0.4px;
  color: #646777;
}
.address-wrap .address-detail p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #4C5A76;
  font-weight: 600;
}
.address-wrap hr {
  height: 0.6px;
  background-color: #D8D8D8;
  opacity: 1;
  margin-left: -20px;
  margin-right: -20px;
}
.address-wrap button {
  height: 42px;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
}
.address-wrap .commonTabel {
  box-shadow: none;
  border: 0;
}
.address-wrap .commonTabel thead tr th {
  font-size: 16px;
}

.access-page {
  min-height: calc(100vh - 203px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-page .access-page-inner {
  text-align: center;
}
.access-page .access-page-inner img {
  width: 150px;
}
.access-page .access-page-inner h2 {
  color: #b7b7b7;
}
.access-page .access-page-inner h4 {
  color: #b7b7b7;
}

.about-banner {
  height: 360px;
  background-image: url("/public/assets/images/about-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.about-banner .about-banner-cnt {
  padding: 40px;
  max-width: 620px;
  border-radius: 5px;
  color: #fff;
  position: relative;
  z-index: 1;
}
.about-banner .about-banner-cnt h2 {
  color: #fff;
}
.about-banner .about-banner-cnt p {
  margin: 20px 0;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  font-family: "Lato", sans-serif;
}
.about-banner .about-banner-cnt button {
  width: 300px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
}

.overlay {
  position: relative;
}
.overlay:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
}

.why-cp {
  padding: 60px 0;
}
.why-cp h3 {
  margin-bottom: 40px;
  text-align: center;
}
.why-cp .why-cp-img img {
  display: block;
}
.why-cp .why-cp-cnt {
  letter-spacing: 0.02em;
}
.why-cp .why-cp-cnt ul {
  list-style: disc;
  padding-left: 30px;
}
.why-cp .why-cp-cnt ul li {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;
  color: #646777;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
.why-cp .why-cp-cnt ul li:last-child {
  margin-bottom: 0;
}
.why-cp.convinced {
  background-color: #fff;
}
.why-cp.convinced h1 {
  font-weight: 600;
}
.why-cp.convinced h4 {
  margin-bottom: 20px;
}
.why-cp.convinced .why-cp-cnt ul {
  padding-left: 16px;
}
.why-cp.convinced .why-cp-cnt ul li font {
  color: #FD4E3B;
  font-weight: 700;
}

.action-btn {
  margin-top: 30px;
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-btn button {
  width: 230px;
  height: 50px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  border-radius: 6px;
}
.action-btn button.call-btn {
  background: #2C2D34;
}

.curate {
  margin-top: 60px;
  padding: 30px;
  background: #D9E5F3;
  border-radius: 13px;
  display: flex;
  justify-content: center;
}
.curate img {
  margin-right: 50px;
}
.curate h4 {
  margin-bottom: 20px;
}
.curate p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #646777;
  font-family: "Lato", sans-serif;
}
.curate .curate-inner {
  max-width: 60%;
}

.trusted-restaurants {
  padding: 100px 0;
}
.trusted-restaurants h4 {
  margin-bottom: 40px;
}

.trustedSlider2 {
  margin-top: 20px;
}
.trustedSlider2 img {
  margin-left: 0;
}

.trustedSlider .slick-slide img {
  margin: auto;
}

.about-banner.caterer-banner {
  height: 425px;
  background-image: url("/public/assets/images/caterer-bg.jpg");
}

.how-it-work {
  padding: 40px 0 50px;
  background-color: rgba(217, 217, 217, 0.1);
}
.how-it-work h3 {
  margin-bottom: 40px;
  text-align: center;
}
.how-it-work .row {
  --bs-gutter-x: 180px;
}
.how-it-work .hw-item img {
  margin: auto;
  display: block;
}
.how-it-work .hw-item label.count {
  margin: 28px 0 16px;
  width: 38px;
  height: 38px;
  background: #FD4E3B;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.how-it-work .hw-item p {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #555555;
}

.about-banner.faq-banner {
  background-image: url("/public/assets/images/faq-banner.jpg");
}
.about-banner.faq-banner h1 {
  color: #fff;
  position: relative;
  z-index: 1;
}

.faq {
  padding: 50px 0;
}
.faq .container {
  max-width: 900px;
}
.faq .faq-item h4 {
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #E8E8E8;
  font-weight: 700;
}
.faq .faq-item .faq-sub-item {
  margin-bottom: 40px;
}
.faq .faq-item .faq-sub-item-title {
  padding: 12px 0;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #E8E8E8;
  display: block;
}
.faq .accordion .accordion-item {
  padding: 12px 0;
  border-bottom: 1px solid #E8E8E8;
  background: none;
}
.faq .accordion-header button {
  padding: 10px 0;
  font-size: 18px;
  line-height: 25px;
  background: none !important;
  display: flex;
  justify-content: space-between;
  color: #404040 !important;
  box-shadow: none;
  border-radius: 0;
  font-weight: 600;
}
.faq .accordion-header button:after {
  display: none;
}
.faq .accordion-header button img {
  width: 30px;
}
.faq .accordion-header button.accordion-button {
  color: #2C2D34;
  flex-shrink: 0;
}
.faq .accordion-header button.accordion-button .accordion-action {
  margin-left: 15px;
}
.faq .accordion-header button.accordion-button .accordion-plus {
  display: none;
}
.faq .accordion-header button.accordion-button .accordion-minus {
  display: block;
}
.faq .accordion-header button.accordion-button.collapsed {
  color: #404040;
}
.faq .accordion-header button.accordion-button.collapsed .accordion-plus {
  display: block;
}
.faq .accordion-header button.accordion-button.collapsed .accordion-minus {
  display: none;
}
.faq .accordion-header .accordion-button:not(.collapsed) {
  color: #2C2D34 !important;
}
.faq .accordion-body {
  padding: 20px 35px 20px 0;
}
.faq .accordion-body p {
  margin-bottom: 20px;
}
.faq .accordion-body p:last-child {
  margin-bottom: 0;
}

.contact {
  min-height: calc(100vh - 203px);
}
.contact .container-fluid {
  --bs-gutter-x:0;
}
.contact .row {
  --bs-gutter-x:0;
}

.contact-img {
  background-color: #C1B2B0;
  height: 100%;
  background-image: url("/public/assets/images/contact-graphics.svg");
  padding-top: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 120px;
}
.contact-img h2 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.contact-form {
  max-width: 540px;
  margin: auto;
  margin-top: 50px;
}
.contact-form p {
  margin-bottom: 30px;
}
.contact-form p a {
  color: #FD4E3B;
  text-decoration: underline;
}
.contact-form h5 {
  margin-bottom: 30px;
  font-weight: 700;
}
.contact-form .create-step-form .allFeild {
  margin-bottom: 30px;
}
.contact-form .submit-btn {
  height: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
}

.blog-wrap {
  min-height: calc(100vh - 70px);
  overflow: hidden;
}
.blog-wrap iframe {
  width: 100%;
  height: calc(100vh - 75px);
}

.general-page {
  padding: 80px 0;
}
.general-page h2.h2 {
  margin-bottom: 20px;
}
.general-page h2 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.general-page p {
  margin-bottom: 15px;
}
.general-page .h4 {
  font-weight: 700;
}
.general-page b {
  font-weight: 600;
}
.general-page .main_ul {
  padding-left: 20px;
  list-style: disc;
}
.general-page .main_ul li {
  margin-bottom: 15px;
}
.general-page .p_left {
  margin-left: 30px;
}
.general-page .terms_of_use_p {
  font-weight: 600;
  font-size: 17px;
}

.rdw-link-modal {
  min-height: 205px !important;
  height: auto !important;
}

.MuiSkeleton-root {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.circle-loader {
  outline: 0;
}
.circle-loader:focus-visible {
  outline: 0;
}

.fade {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 575.98px) {
  body {
    padding-top: 55px;
    font-size: 12px;
    line-height: 16px;
  }
  .h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
  .h6 {
    font-size: 12px;
  }
  .sideMenu {
    width: 50px;
    padding: 0;
    display: none;
  }
  .sideMenu .sideMenuItem {
    height: 40px;
  }
  .sideMenu .sideMenuItem img {
    margin: auto;
    margin-right: 0;
  }
  .sideMenu .sideMenuItem .sideMenuItemText {
    display: none;
  }
  header {
    height: 55px;
  }
  header .logo img {
    height: 35px;
  }
  header .header-menu ul {
    display: none;
  }
  header .header-menu .signin-btn {
    font-size: 10px;
    padding: 4px 8px;
  }
  header .header-menu .profile-avatar {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
  header .header-menu .header-user-name {
    font-size: 12px;
    margin-left: 5px;
  }
  header.LoggedInHeader .container-fluid {
    padding-left: 12px;
  }
  header.LoggedInHeader .loggedLogo {
    width: 100px;
  }
  header.LoggedInHeader .loggedLogo img {
    width: 80px;
  }
  .m-menu-drawer .MuiDrawer-paper {
    width: 200px;
  }
  .m-menu-drawer .m-menu-close {
    margin-top: 5px;
    margin-left: 8px;
  }
  .m-menu-drawer .m-menu-close img {
    width: 15px;
  }
  .m-menu-drawer ul {
    margin-top: 10px;
  }
  .m-menu-drawer ul li {
    border-bottom: 1px solid #d8d8d8;
    margin: 0 10px;
  }
  .m-menu-drawer ul li a {
    padding: 14px 10px;
    display: block;
    color: #000;
  }
  .m-menu-drawer ul li:hover, .m-menu-drawer ul li:focus {
    border-color: #FD4E3B;
  }
  .m-menu-drawer ul li:hover a, .m-menu-drawer ul li:focus a {
    color: #FD4E3B;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu {
    width: 200px;
    margin-top: 10px;
    padding: 0;
    display: block;
    position: inherit;
    box-shadow: none;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem {
    height: auto;
    margin: 0 10px;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem img {
    margin: 0;
    margin-right: 6px;
    width: 15px;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem .MuiTypography-root {
    font-size: 12px;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem .sideMenuItemText {
    display: block;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem.Mui-selected {
    border-bottom: 1px solid #FD4E3B;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu ul {
    margin-top: 0;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu ul li {
    margin: 0;
    border-bottom: 0;
  }
  .contentWrap {
    padding-left: 0px;
    min-height: calc(100vh - 55px);
  }
  .contentWrap .contentWrapInner {
    padding: 20px 20px 30px 15px;
  }
  .width-175 {
    min-width: 175px;
  }
  .wrap-height {
    height: calc(100vh - 55px);
  }
  .login-box.create-step-form {
    width: 100%;
    padding: 20px;
    margin: auto 30px;
  }
  .login-box.create-step-form .h4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  .login-box.create-step-form .login-btn.MuiButton-root {
    height: 40px;
    margin-top: 20px;
    font-size: 12px;
  }
  .createOneLink {
    text-align: center;
  }
  .create-form-left {
    display: none;
  }
  .create-step-wrap {
    padding: 20px;
    padding-top: 0;
  }
  .create-stepper {
    background-color: #f5f5f5;
    padding: 10px 20px;
    margin: 0 -20px;
  }
  .create-stepper .MuiStep-root {
    padding: 0;
  }
  .create-stepper .MuiStep-root:first-child {
    padding-left: 0;
  }
  .create-stepper .MuiStepLabel-label {
    font-size: 12px;
    display: none;
  }
  .create-stepper .MuiStepLabel-label.Mui-active {
    color: #000;
    display: block;
    font-size: 18px;
    font-weight: 700;
  }
  .create-stepper .MuiStepLabel-iconContainer {
    display: none;
    font-size: 18px;
  }
  .create-stepper .MuiStepIcon-root {
    color: #646777;
  }
  .create-stepper .MuiStepIcon-root.Mui-completed {
    color: #FAA095;
  }
  .create-stepper .MuiStepIcon-root.Mui-active {
    color: #FAA095;
  }
  .create-stepper .MuiStepLabel-label.Mui-completed {
    color: #FD4E3B;
    font-weight: 600;
  }
  .textBoxTopMargin {
    margin-top: 0;
  }
  .create-step-form {
    padding-top: 15px;
  }
  .create-step-form .MuiFormControlLabel-root {
    padding-bottom: 15px;
  }
  .create-step-form .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 12px;
  }
  .create-step-form .allFeild {
    margin-bottom: 20px;
  }
  .create-step-form .textField input {
    font-size: 14px;
  }
  .create-step-form .textField input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 12px;
  }
  .create-step-form .textField input::-moz-placeholder { /* Firefox 19+ */
    font-size: 12px;
  }
  .create-step-form .textField input:-ms-input-placeholder { /* IE 10+ */
    font-size: 12px;
  }
  .create-step-form .textField input:-moz-placeholder { /* Firefox 18- */
    font-size: 12px;
  }
  .textBox .error-msg {
    font-size: 8px;
    bottom: 6px;
    margin-left: 2px;
  }
  .form-space {
    padding-bottom: 20px;
  }
  .create-account-condition {
    margin-top: 0;
  }
  .createStepBtn {
    justify-content: space-between;
  }
  .createStepBtn .MuiButton-root {
    width: 165px;
    height: 35px;
    font-size: 12px;
  }
  .createStepBtn .MuiButton-root.cancelBtn {
    width: 90px;
    margin-right: 15px;
  }
  .createStepBtn .btnArrow {
    width: 15px;
  }
  .more-delivery-address-email {
    margin-top: 5px;
  }
  .more-delivery-address-email.remove {
    margin-top: -20px;
    font-size: 10px;
  }
  .crop-outer img {
    max-width: 100% !important;
    max-height: 380px !important;
  }
  .weekly-hrs-outer {
    margin-bottom: 18px;
    padding: 15px 10px;
  }
  .weekly-hrs-outer .per-day-time .MuiFormControl-root {
    min-width: 100px;
    width: 100px;
  }
  .weekly-hrs-outer .per-day-time .MuiSelect-select {
    padding: 10px;
  }
  .weekly-hrs-outer .per-day-time .MuiFormLabel-root {
    font-size: 12px;
    line-height: 12px;
  }
  .weekly-hrs-outer .per-day-time .MuiFormLabel-root.Mui-focused.MuiInputLabel-shrink {
    line-height: 18px;
  }
  .weekly-hrs-outer .per-day-time .time-separator {
    margin: 0 5px;
    width: 8px;
  }
  .weekly-hrs-outer .per-day-time .add-hours {
    margin-left: 5px;
    font-size: 11px;
  }
  .weekly-hrs-outer .per-day-time .add-hours img {
    width: 15px;
    height: 15px;
  }
  .hours-dropdown .MuiSelect-select {
    font-size: 12px;
  }
  .hours-dropdown .MuiSelect-select svg {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
  .login-box-frontend {
    width: 100%;
    margin: auto;
  }
  .login-box-frontend .h4 {
    font-size: 16px;
  }
  .login-box-frontend .login-box.create-step-form {
    padding: 30px 20px;
  }
  .login-box-frontend .login-box.create-step-form .textField input {
    font-size: 14px;
  }
  .login-box-frontend .login-box.create-step-form .login-separator {
    margin: 18px 0 12px;
  }
  .login-box-frontend .login-box.create-step-form .login-separator label {
    font-size: 14px;
  }
  .login-box-frontend .login-box.create-step-form .login-separator label::before {
    left: -20px;
  }
  .login-box-frontend .login-box.create-step-form .login-separator label::after {
    right: -20px;
  }
  .login-box-frontend .login-box.create-step-form .login-create h4 {
    font-size: 16px;
  }
  .login-box-frontend .login-box.create-step-form .new-account-btn {
    margin-top: 15px;
    gap: 12px;
  }
  .login-box-frontend .login-box.create-step-form .new-account-btn button {
    height: 40px;
    font-size: 12px;
  }
  .customer-login {
    width: auto;
    margin-top: 20px;
  }
  .customer-login .customer-login-title {
    height: 70px;
    padding: 0 20px;
    background: #F5F5F5;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .customer-login .customer-login-title h5 {
    font-weight: 700;
    margin-bottom: 0;
  }
  .customer-login .customer-login-title small {
    font-size: 10px;
    line-height: 12px;
    display: block;
  }
  .customer-login .login-box.create-step-form {
    width: auto;
    padding: 20px;
    margin: auto;
  }
  .modal-close {
    width: 15px;
  }
  .contentWrap-customer-profile {
    padding-top: 10px;
  }
  .customer-profile-wrap {
    padding-top: 0;
    padding-bottom: 30px;
  }
  .customer-profile {
    padding: 20px;
    margin: 20px 10px !important;
  }
  .customer-profile .customer-profile-title {
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .customer-profile .customer-profile-title h4 img {
    margin-right: 5px;
    max-width: 20px;
  }
  .back-link-order {
    margin-bottom: 12px;
    font-size: 12px;
  }
  .address-wrap .address-wt-box {
    padding: 15px;
  }
  .address-wrap .address-map {
    height: 250px;
  }
  .address-wrap .address-title-bar {
    margin: 10px 0;
    padding-bottom: 10px;
  }
  .address-wrap .address-title-bar h4 {
    margin-bottom: 0;
  }
  .address-wrap .commonTabel thead tr th {
    font-size: 14px;
  }
  .catercash-overview {
    gap: 10px;
    padding: 0px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: #F5F6F8;
    margin-bottom: 25px;
  }
  .catercash-overview .cc-item {
    height: 135px;
    width: 48%;
    flex-grow: inherit;
    flex-basis: auto;
    padding: 10px;
    line-height: 16px;
  }
  .catercash-overview .cc-item h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  .catercash-overview .cc-item img {
    width: 25px;
  }
  .catercash-overview .cc-item label {
    font-size: 12px;
    margin-left: 0px;
  }
  .catercash-overview .cc-item.cc-gift img {
    width: 70%;
  }
  .catercash-overview .cc-item.cc-gift small {
    font-size: 10px;
    margin: 0px 0 5px;
  }
  .catercash-overview .cc-item.cc-gift .amazon-btn {
    gap: 4px;
    justify-content: center;
  }
  .catercash-overview .cc-item.cc-gift .amazon-btn button {
    width: 28%;
    min-width: 28%;
    font-size: 9px;
    color: #4C5A76;
    font-weight: 700;
  }
  .status-btn.MuiButtonBase-root {
    width: 80px;
    height: 28px;
    border-radius: 6px;
    font-size: 11px;
  }
  .table-pagenation .rowCount .MuiSelect-select {
    padding: 8px 22px 8px 8px !important;
  }
  .table-pagenation .rowCount .MuiSvgIcon-root {
    right: 0px;
  }
  .table-pagenation.inside .rowCount {
    left: 0;
  }
  .location-details-tile {
    padding: 15px;
  }
  .location-details-tile .ld-titles {
    margin-bottom: 10px;
  }
  .location-details-tile .ld-titles h4 img {
    margin-right: 5px;
    max-width: 20px;
  }
  .ld-edit {
    font-size: 10px;
  }
  .ld-edit img {
    width: 12px;
    margin-right: 2px;
  }
  .ld-contact {
    display: block;
  }
  .ld-contact .ld-contact-img {
    flex-shrink: 0;
    position: relative;
  }
  .ld-contact .ld-contact-img img {
    object-fit: cover;
    width: 100%;
    height: 150px;
  }
  .ld-contact .ld-contact-details {
    margin-left: 0px;
    display: block;
    margin-top: 10px;
  }
  .ld-contact .ld-contact-details .ld-contact-details-item {
    width: 100%;
    margin-bottom: 10px;
  }
  .ld-contact .ld-contact-details .ld-contact-details-item:last-child {
    margin-bottom: 0;
  }
  .ld-contact .ld-contact-details label {
    font-size: 10px;
  }
  .ld-contact .ld-contact-details span {
    font-size: 12px;
  }
  .cater-cash-tag {
    width: 50px;
    height: 50px;
    right: 10px;
    top: 10px;
    border-width: 1px;
  }
  .cater-cash-tag b {
    font-size: 12px;
    line-height: 16px;
  }
  .cater-cash-tag label {
    font-size: 8px;
    line-height: 10px;
  }
  .ld-business-detail .ld-business-detail-item {
    margin-bottom: 10px;
  }
  .ld-business-detail label {
    font-size: 10px;
  }
  .ld-business-detail b {
    font-size: 12px;
  }
  .ld-others ul li {
    margin-bottom: 20px;
    font-size: 12px;
  }
  .ld-slider {
    display: flex;
    align-items: center;
  }
  .ld-slider h5 {
    font-size: 14px;
    color: #3E3C37;
    margin-right: 12px;
    font-weight: 600;
  }
  .ld-slider h6 {
    font-size: 12px;
    font-weight: 600;
    margin-left: 12px;
  }
  .common-drawer .MuiDrawer-paper {
    width: 100%;
  }
  .commonTabel thead tr th {
    padding: 14px 10px;
    font-size: 12px;
  }
  .commonTabel tbody tr th, .commonTabel tbody tr td {
    padding: 12px 10px;
    font-size: 12px;
  }
  .menu-head-btns button {
    height: 30px;
    margin-right: 10px;
  }
  .menu-sort {
    width: 90% !important;
  }
  .pageWrap h2 {
    margin-bottom: 25px;
    color: #646777;
  }
  .banner {
    height: auto;
    background-image: url("/public/assets/images/search-bg.jpg");
  }
  .banner h1 {
    font-size: 20px;
    line-height: 28px;
  }
  .banner .banner-subtext {
    font-size: 12px;
  }
  .banner-content {
    padding: 35px;
    margin: 15px 0;
  }
  .banner-search-point {
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
  }
  .logo-carousel {
    padding: 50px 0 !important;
  }
  .banner-search {
    padding: 0px;
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
  }
  .banner-search .MuiFormControl-root {
    width: 100%;
  }
  .banner-search .MuiFormControl-root a {
    font-size: 10px;
  }
  .banner-search .MuiOutlinedInput-root {
    padding: 10px !important;
  }
  .banner-search fieldset {
    border: 1px solid #D9E5F3;
    border-radius: 6px;
  }
  .banner-search .mobile-search {
    height: 40px;
    margin: 10px 0;
  }
  .banner-points {
    justify-content: space-evenly;
  }
  .banner-points .banner-points-item {
    margin: 0 5px;
    display: block;
    text-align: center;
  }
  .banner-points .banner-points-item img {
    max-height: 15px;
  }
  .banner-points .banner-points-item .banner-points-item-text {
    padding: 0;
    margin-left: 0px;
    padding-bottom: 8px;
    margin-bottom: 5px;
  }
  .banner-points .banner-points-item .banner-points-item-text h5 {
    font-size: 10px;
  }
  .list-homepage {
    padding: 10px 20px;
    font-size: 14px;
  }
  .home-cater-near {
    padding: 60px 0;
  }
  .home-cater-near .home-cater-near-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-cater-near .home-cater-near-title label {
    font-size: 12px;
    font-weight: 700;
    color: #000;
    display: inline-block;
    margin-right: 10px;
  }
  .home-cater-near .home-cater-near-title button {
    padding: 3px;
  }
  .homeCaterSlider .slick-slider {
    overflow: hidden;
  }
  .homeCaterSlider .slick-list {
    margin: 0 -15px;
  }
  .homeCaterSlider .cater-list-item {
    padding: 0 15px;
  }
  .testimonialSlider {
    overflow: hidden;
  }
  .testimonialSlider .slick-list {
    margin: 0 -20px;
  }
  .testimonialSlider .testimonial-item {
    padding: 0 20px;
  }
  .testimonials {
    padding-bottom: 40px;
  }
  .testimonials .testimonial-item {
    margin-bottom: 0;
  }
  .testimonials .testimonial-item-text .pointer-mark {
    bottom: -16px;
    left: 40px;
    position: absolute;
  }
  .testimonials .testimonial-author {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimonials .testimonial-author .testimonial-author-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 72px;
    border: 2px solid #FD4E3B;
    object-fit: cover;
  }
  .testimonials .testimonial-author span b {
    font-size: 18px;
    line-height: 22px;
    color: #404040;
    font-weight: 700;
    display: block;
  }
  .testimonials .testimonial-author span label {
    font-size: 14px;
    line-height: 17px;
    color: #404040;
  }
  .home-works {
    padding: 30px 15px;
  }
  .home-works h2 {
    margin-bottom: 30px;
    color: #fff;
  }
  .home-works .home-works-item {
    margin-bottom: 20px;
  }
  footer {
    padding: 20px 0;
  }
  footer .container {
    display: block;
  }
  footer .logo-social-link {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  footer .logo-social-link .footer-logo {
    height: 25px;
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px;
  }
  footer .logo-social-link ul li {
    margin-right: 15px;
  }
  footer .logo-social-link ul li a img {
    width: 20px;
    height: 20px;
  }
  footer .copy-terms {
    font-size: 12px;
    text-align: center;
  }
  footer .copy-terms span a {
    color: #FD4E3B;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid #FD4E3B;
  }
  footer .copy-terms span a:last-child {
    border-right: 0;
  }
  footer .copy-terms span a:hover {
    text-decoration: underline;
  }
  .menu-back {
    font-size: 12px;
  }
  .menu-back img {
    height: 10px;
    margin-top: 1px;
  }
  .listing-filter-primary {
    padding: 10px 0px;
    margin-bottom: 10px;
  }
  .listing-filter-primary .container-fluid {
    display: block;
  }
  .listing-filter-primary .lfp-label {
    font-size: 10px;
    color: #2C2D34;
    display: block;
    margin-bottom: 2px;
    font-weight: 500;
  }
  .listing-filter-primary .lfp-delivery-address {
    min-width: 100%;
    margin: 10px 0;
  }
  .listing-filter-primary .lfp-delivery-address input {
    padding: 2px 5px !important;
    font-size: 12px;
  }
  .listing-filter-primary .lfp-delivery-address .lfp-zip {
    width: 100px;
  }
  .listing-filter-primary .lfp-delivery-address .lfp-zip input {
    padding: 10px 16px !important;
  }
  .listing-filter-primary .lfp-date {
    margin: 0 10px 0 0;
  }
  .listing-filter-primary .lfp-date input {
    width: 220px;
    height: 40px;
    font-size: 12px;
    color: #2C2D34;
  }
  .listing-filter-primary .lfp-head-count {
    width: 150px;
  }
  .listing-filter-primary .lfp-head-count .MuiSelect-select {
    padding: 13.5px 9px;
    font-size: 12px;
    color: #2C2D34;
  }
  .listing-filter-primary .lfp-head-count input {
    padding: 8.5px 4px;
    color: #2C2D34;
  }
  .listing-filter-primary .lfp-head-count p {
    color: #2C2D34;
    font-weight: 600;
  }
  .listing-filter-primary .lfp-head-count.lfp-order-type {
    width: 200px;
    margin-right: 30px;
  }
  .detail-title-bar {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .detail-title-bar .detail-main-points {
    flex-wrap: wrap;
  }
  .detail-title-bar .detail-main-points li {
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 12px;
  }
  .detail-title-bar .detail-main-points li.dmp-bg {
    font-size: 12px;
  }
  .filter-mobile-title {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F2F2F2;
  }
  .filter-mobile-title h5 {
    font-size: 16px;
    margin-left: 6px;
    font-weight: 600;
  }
  .filter-mobile-title button img {
    width: 18px;
  }
  .listing-filter-secondary {
    padding: 0px;
  }
  .listing-filter-secondary.listing-filter-secondary-mob {
    padding: 15px 10px;
  }
  .listing-filter-secondary h3 {
    font-size: 16px;
  }
  .listing-filter-secondary .cater-list-filter-btn {
    padding: 5px;
  }
  .listing-filter-secondary .cater-list-filter-btn img {
    height: 15px;
  }
  .listing-filter-secondary .lfs-item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .listing-filter-secondary .lfs-search {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
  .listing-filter-secondary .lfs-search .MuiInputBase-root.MuiOutlinedInput-root {
    height: 48px;
  }
  .listing-filter-secondary .lfs-deitary {
    width: 100%;
  }
  .listing-filter-secondary .lfs-option {
    width: 100%;
  }
  .listing-filter-secondary .lfs-option .MuiSelect-select {
    padding-left: 30px !important;
    background-image: url("/public/assets/images/Options.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
  }
  .listing-filter-secondary .lfs-cuisnes {
    width: 100%;
  }
  .listing-filter-secondary .lfs-cuisnes .MuiSelect-select {
    padding-left: 30px !important;
    background-image: url("/public/assets/images/cusines.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
  }
  .listing-filter-secondary .lfs-sort {
    width: 100%;
    border-left: 0px;
    padding-left: 0px;
  }
  .listing-filter-secondary .MuiSelect-select {
    padding: 12.5px 8px 12.5px 8px !important;
    font-size: 12px;
  }
  .listing-filter-secondary .MuiSelect-select img {
    margin-right: 8px;
  }
  .listing-filter-secondary fieldset {
    border-radius: 6px;
  }
  .listing-filter-secondary .MuiInputAdornment-root {
    flex-shrink: 0;
  }
  .listing-filter-secondary .lfs-clear-btn {
    height: 32px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
  }
  .lfs-menuitem.MuiButtonBase-root {
    padding: 4px 8px;
    font-size: 12px !important;
  }
  .lfs-menuitem.MuiButtonBase-root img {
    margin-right: 8px;
  }
  .cater-list {
    height: calc(100vh - 230px);
    padding: 8px 0 10px;
  }
  .cater-list .cater-list-item-outer {
    margin-bottom: 20px;
  }
  .cater-list-item .cater-list-item-img {
    height: 220px;
    width: 100%;
    position: relative;
  }
  .cater-list-item .cater-list-item-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .cater-list-item .cater-list-item-img .cater-cash-tag {
    top: 20px;
    right: 20px;
  }
  .cater-list-item .cater-list-item-cnt {
    padding-top: 10px;
  }
  .cater-list-item .cater-list-item-cnt h4 {
    font-size: 16px;
  }
  .cater-list-item .cater-list-item-cnt h4 label {
    margin-left: 6px;
    display: flex;
    align-items: center;
  }
  .cater-list-item .cater-list-item-cnt h4 label img {
    margin-right: 5px;
  }
  .cater-list-item .cater-list-item-cnt h4 label span {
    font-size: 12px;
    color: #646777;
    margin-top: 2px;
  }
  .cater-list-item .cater-list-item-cnt h4 label span small {
    font-size: 10px;
    color: #B8BEC1;
  }
  .cater-list-item .cater-list-item-cnt h6 {
    margin: 6px 0 4px;
    font-size: 12px;
    text-transform: capitalize;
    color: #2C2D34;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order {
    font-size: 12px;
    color: #646777;
    display: flex;
    align-items: center;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span {
    display: flex;
    align-items: center;
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid #D9E5F3;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span img {
    margin-right: 6px;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span:last-child {
    border-right: 0;
  }
  .table-pagenation {
    padding: 10px 20px 10px;
  }
  .padding-0 {
    padding: 0;
  }
  .menu-category-listing {
    margin-bottom: 0;
  }
  .menu-category {
    border-radius: 0px;
    padding: 0 10px;
  }
  .menu-category .menu-category-item {
    padding: 15px 10px;
    margin-right: 5px;
  }
  .slick-next.menu-category-arrow {
    right: 2px;
    width: 15px;
    height: 46px;
  }
  .slick-next.menu-category-arrow img {
    width: 11px;
  }
  .slick-prev.menu-category-arrow {
    left: 2px;
    width: 15px;
    height: 46px;
  }
  .slick-prev.menu-category-arrow img {
    width: 11px;
  }
  .confirm-place-btn.MuiButtonBase-root {
    height: 45px;
    width: 100%;
    font-size: 14px;
  }
  .confirm-place-btn.MuiButtonBase-root img {
    width: 20px;
  }
  .add-card-btn.MuiButtonBase-root {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .menu-category {
    margin-bottom: 20px;
  }
  .menu-category-listing .menu-category-listing-col {
    margin-bottom: 12px;
  }
  .menu-category-listing .menu-category-listing-col::before {
    display: none;
  }
  .menu-category-listing .menu-category-listing-col::after {
    display: none;
  }
  .menu-category-listing .menu-category-listing-item {
    flex-direction: row-reverse;
    padding-bottom: 12px;
    border-bottom: 0.6px solid #D8D8D8;
  }
  .menu-category-listing .menu-category-listing-item h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .menu-category-listing .menu-category-listing-item p {
    font-size: 10px;
    line-height: 16px;
  }
  .menu-category-listing .menu-category-listing-item .menu-category-listing-item-img {
    margin-left: 0;
    margin-right: 15px;
  }
  .menu-category-listing .menu-category-listing-item .menu-category-listing-item-img img {
    width: 120px;
    height: 130px;
  }
  .menu-category-listing .menu-category-listing-item .menu-category-listing-item-details {
    width: 100%;
  }
  .menu-category-listing .menu-category-listing-item.skeleton h3 .MuiSkeleton-root {
    width: 250px;
    height: 16px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton .dietry-label .MuiSkeleton-root {
    width: 150px;
    height: 16px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton .price-time .MuiSkeleton-root {
    width: 200px;
    height: 16px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton p .MuiSkeleton-root {
    width: 320px;
    height: 14px;
  }
  .menu-category-listing .price-time {
    padding: 5px 0;
    font-size: 12px;
  }
  .cater-detail-box h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .cater-detail-box.detail-ordering-hours {
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
  .detail-tagline .detail-tagline-item h5 {
    font-size: 16px;
  }
  .detail-tagline .detail-tagline-item p {
    font-size: 14px;
    line-height: 18px;
  }
  .cater-detail-timing li {
    width: 100%;
    padding: 15px 0;
    font-size: 14px;
  }
  .cater-detail-timing li:nth-child(7), .cater-detail-timing li:nth-child(8) {
    border-bottom: 0 solid #000;
  }
  .cater-detail-timing li:nth-child(3n+2) {
    position: relative;
    margin: 0 0px;
  }
  .cater-detail-timing li:nth-child(3n+2)::before, .cater-detail-timing li:nth-child(3n+2)::after {
    display: none;
  }
  .cart-view {
    margin: 30px 0;
  }
  .cart-view .cart-view-list .cart-view-list-item .cart-count-name h6 {
    font-size: 12px;
  }
  .cart-view .review-cart-btn {
    height: 45px;
    font-size: 14px;
  }
  .cart-view .review-cart-btn img {
    width: 20px;
  }
  .cart-total {
    font-size: 14px;
  }
  .common-view-box h6 {
    font-size: 14px;
  }
  .review-order-price .other-charges .other-charges-item b {
    font-size: 14px;
  }
  .review-add-update-btn.MuiButtonBase-root {
    margin: auto;
    display: flex;
  }
  .review-order-price .other-amount a {
    margin-top: 8px;
  }
  .add-promo-code .promo-box {
    margin-top: 10px;
  }
  .dietry-label {
    font-size: 12px;
    margin-left: 4px;
  }
  .dietry-label img {
    width: 10px;
  }
  .caterer-menu-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .caterer-menu-modal .caterer-menu-details {
    max-height: 100vh;
    height: 100vh;
    width: 100%;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-header {
    height: 70px;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-header h5 {
    margin-bottom: 0;
    font-weight: 700;
    text-transform: capitalize;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-header small {
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-header .dietry-label {
    margin-left: 0;
    font-size: 10px;
    margin-top: 3px;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-content {
    max-height: calc(100vh - 230px);
    padding: 20px;
    font-size: 12px;
    line-height: 18px;
    color: #3E3C37;
    overflow: auto;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-footer {
    height: 122px;
    padding: 12px 20px;
    display: block;
    border-radius: 0;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
    background-color: #fff;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-footer .menu-qty {
    justify-content: space-between;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-footer .cart-price-btn {
    width: 100%;
    margin-top: 14px;
    height: 38px;
  }
  .mob-cart-button-area {
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);
    width: 100%;
    z-index: 10;
  }
  .mob-cart-button-area button {
    height: 54px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    width: 100%;
  }
  .review-star-item span {
    font-size: 17px !important;
  }
  .common-dialog-box.review-box .review-star-item span {
    font-size: 17px !important;
  }
  .common-dialog-box.review-box .review-textbox {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .common-dialog-box.review-box .review-textbox .MuiFormLabel-root {
    font-size: 14px;
  }
  .order-confirm-screen {
    height: calc(100vh - 158px);
    overflow: auto;
  }
  .order-confirm-screen .row {
    height: auto !important;
  }
  .order-confirm-screen .ocs-img {
    padding: 20px 0;
  }
  .order-confirm-screen .ocs-img img {
    width: 50%;
  }
  .order-confirm-screen .ocs-img h5 {
    margin-top: 10px;
  }
  .order-confirm-screen .ocs-text {
    width: 100%;
    padding: 20px 15px;
    text-align: center;
  }
  .order-confirm-screen .ocs-text h1 {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .order-confirm-screen .ocs-text p {
    margin-bottom: 10px;
  }
  .order-confirm-screen .ocs-text p b {
    display: block;
  }
  .order-confirm-screen .ocs-text .saveBtn {
    min-width: 120px;
    font-size: 13px;
    margin-top: 15px !important;
  }
  .order-confirm-screen .valign {
    height: auto;
  }
  .about-banner {
    height: 360px;
  }
  .about-banner .about-banner-cnt {
    padding: 20px;
  }
  .about-banner .about-banner-cnt h2 {
    color: #FD4E3B;
  }
  .about-banner .about-banner-cnt p {
    margin: 10px 0 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .about-banner .about-banner-cnt button {
    width: 200px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
  }
  .why-cp {
    padding: 30px 0;
  }
  .why-cp h3 {
    margin-bottom: 20px;
  }
  .why-cp .why-cp-cnt ul {
    padding-left: 16px;
    margin-top: 20px;
  }
  .why-cp .why-cp-cnt ul li {
    margin-bottom: 15px;
  }
  .why-cp.convinced {
    background-color: #fff;
  }
  .why-cp.convinced h1 {
    font-weight: 600;
  }
  .why-cp.convinced h4 {
    margin-bottom: 20px;
  }
  .why-cp.convinced .why-cp-cnt ul {
    padding-left: 16px;
  }
  .why-cp.convinced .why-cp-cnt ul li font {
    color: #FD4E3B;
    font-weight: 700;
  }
  .action-btn {
    margin: 20px 0;
    gap: 20px;
  }
  .action-btn button {
    width: 150px;
    height: 35px;
  }
  .curate {
    margin-top: 30px;
    padding: 30px 20px;
  }
  .curate img {
    margin: auto;
  }
  .curate h4 {
    margin: 10px 0;
  }
  .curate .curate-inner {
    max-width: 80%;
    text-align: center;
  }
  .trusted-restaurants {
    padding: 30px 0;
  }
  .trusted-restaurants h4 {
    margin-bottom: 20px;
  }
  .trustedSlider2 {
    margin-top: 20px;
  }
  .trustedSlider2 img {
    margin-left: 0;
  }
  .about-banner.caterer-banner {
    height: 350px;
    background-image: url("/public/assets/images/caterer-bg.jpg");
  }
  .how-it-work {
    padding: 30px 0 40px;
  }
  .how-it-work h3 {
    margin-bottom: 30px;
  }
  .how-it-work .row {
    --bs-gutter-x: auto;
  }
  .how-it-work .hw-item label.count {
    margin: 20px 0 10px;
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .how-it-work .hw-item p {
    font-size: 14px;
  }
  .about-banner.faq-banner {
    height: 200px;
  }
  .faq {
    padding: 30px 0;
  }
  .faq .faq-item .faq-sub-item {
    margin-bottom: 20px;
  }
  .faq .faq-item .faq-sub-item-title {
    padding: 10px 0;
    font-size: 14px;
  }
  .faq .accordion-header button {
    font-size: 14px;
    line-height: 18px;
  }
  .faq .accordion-header button span {
    flex-shrink: 0;
  }
  .faq .accordion-header button img {
    width: 15px;
  }
  .faq .accordion .accordion-item {
    padding: 8px 0;
  }
  .faq .accordion-body {
    padding: 10px 25px 10px 0;
  }
  .contact {
    min-height: auto;
  }
  .contact .container-fluid {
    --bs-gutter-x:15px;
  }
  .contact .row {
    --bs-gutter-x:0;
  }
  .contact-img {
    background: none;
    padding-top: 30px;
  }
  .contact-img h2 {
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #000;
    text-align: left;
  }
  .contact-form {
    max-width: 540px;
    margin: auto;
    margin: 20px 0 30px;
  }
  .contact-form p {
    margin-bottom: 20px;
  }
  .contact-form h5 {
    margin-bottom: 20px;
    font-weight: 700;
  }
  .contact-form .login-box.create-step-form {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .contact-form .login-box.create-step-form .allFeild {
    margin-bottom: 30px;
  }
  .contact-form .submit-btn {
    height: 50px;
  }
  .contentWrap .location-head .location-head-title {
    padding: 20px 10px;
  }
  .contentWrap .location-head .location-head-title button {
    padding: 4px 10px;
    font-size: 12px;
  }
  .contentWrap .location-head .location-head-title button img {
    width: 13px;
  }
  .cater-tab .MuiTabs-root button {
    padding: 8px 15px;
    font-size: 12px;
  }
  .refer-friend-contentWrap {
    background-color: #fff;
  }
  .cater-tab .MuiTabs-root button.refer-friend-tab {
    margin: auto;
    color: #000;
    font-weight: 600;
  }
  .refer-friend {
    max-width: 680px;
    margin: 50px auto;
  }
  .refer-friend .refer-friend-inner {
    padding: 0px;
    margin-top: 10px;
    box-shadow: none;
  }
  .refer-friend .refer-friend-inner h1 {
    font-size: 20px;
    line-height: 28px;
  }
  .refer-friend .refer-friend-inner p {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 18px;
  }
  .refer-friend .refer-friend-inner h6 {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #000;
  }
  .refer-friend .refer-friend-inner h6 img {
    margin-right: 5px;
  }
  .refer-friend .refer-friend-inner b {
    margin-bottom: 15px;
    font-size: 12px;
  }
  .refer-friend .refer-friend-inner .invite-btn {
    width: 80%;
    height: 40px;
    margin: 0 auto 40px;
    display: flex;
  }
  .common-drawer .drawer-head {
    padding: 15px 15px;
  }
  .common-drawer .drawer-head h4 img {
    margin-right: 8px;
    max-width: 20px;
  }
  .cancel-save-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cancel-save-btn button {
    width: 130px;
    height: 40px;
  }
  .order-view-title h5 span {
    padding-left: 5px;
    margin-left: 5px;
  }
  .order-view {
    padding: 20px 12px;
  }
  .order-view .order-view-address.order-view-address-mob {
    margin-bottom: 20px;
  }
  .order-view .order-view-address .MuiAvatar-root {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    font-size: 15px;
  }
  .order-view .order-view-address-wrap {
    padding-bottom: 20px;
  }
  .order-view .order-view-address-wrap h6 {
    font-size: 14px;
    padding-bottom: 5px;
  }
  .order-view .review-order-item h6 {
    font-size: 14px;
  }
  .common-dialog-box .dialog-box-title {
    padding-top: 0;
    padding-bottom: 10px;
    margin-bottom: 4px;
  }
  .common-dialog-box .dialog-box-text {
    width: 100%;
    margin: 5px auto;
  }
  .common-dialog-box .dialog-box-text h5 {
    font-size: 12px;
  }
  .common-dialog-box .MuiDialogActions-root button {
    font-size: 12px;
    height: 30px;
  }
  .common-dialog-box.headcount-dialog-box .MuiDialogActions-root {
    display: block;
  }
  .common-dialog-box.headcount-dialog-box .MuiDialogActions-root button {
    width: 100% !important;
  }
  .common-dialog-box.headcount-dialog-box .MuiDialogActions-root button.dailog-cancel-btn {
    margin-bottom: 10px !important;
  }
  .common-dialog-box.headcount-dialog-box .MuiDialogActions-root button.headcount-btn {
    margin-left: 0;
  }
}
@media (max-width: 300px) {
  .login-box.create-step-form {
    padding: 10px;
    margin: auto 10px;
  }
  .login-box.create-step-form .h4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  .login-box.create-step-form .login-btn.MuiButton-root {
    height: 40px;
    margin-top: 20px;
    font-size: 12px;
  }
}
@media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
  body {
    padding-top: 55px;
    font-size: 12px;
    line-height: 16px;
  }
  .h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
  .h6 {
    font-size: 12px;
  }
  .wrap-height {
    height: calc(100vh - 55px);
  }
  .create-form-right {
    overflow: auto;
    padding: 30px 0 !important;
  }
  header {
    height: 55px;
  }
  header .logo img {
    height: 35px;
  }
  header .header-menu .signin-btn {
    font-size: 10px;
    padding: 4px 8px;
  }
  .login-box.create-step-form {
    width: 300px;
    padding: 20px;
  }
  .login-box.create-step-form .h4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  .login-box.create-step-form .login-btn.MuiButton-root {
    height: 40px;
    margin-top: 20px;
    font-size: 12px;
  }
}
@media (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
  .h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  }
  .h2 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
  }
  .h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  .h4 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
  }
  .h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
  .h6 {
    font-size: 14px;
    font-weight: 600;
  }
  header .header-menu ul {
    display: none;
  }
  .hamburger-icon {
    margin-left: 7px;
  }
  .sideMenu {
    display: none;
  }
  .contentWrap {
    padding-left: 0px;
  }
  .contentWrap .contentWrapInner {
    padding: 20px;
  }
  .m-menu-drawer .MuiDrawer-paper {
    width: 200px;
  }
  .m-menu-drawer .m-menu-close {
    margin-top: 5px;
    margin-left: 8px;
  }
  .m-menu-drawer .m-menu-close img {
    width: 15px;
  }
  .m-menu-drawer ul {
    margin-top: 10px;
  }
  .m-menu-drawer ul li {
    border-bottom: 1px solid #d8d8d8;
    margin: 0 10px;
  }
  .m-menu-drawer ul li a {
    padding: 14px 10px;
    display: block;
    color: #000;
  }
  .m-menu-drawer ul li:hover, .m-menu-drawer ul li:focus {
    border-color: #FD4E3B;
  }
  .m-menu-drawer ul li:hover a, .m-menu-drawer ul li:focus a {
    color: #FD4E3B;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu {
    width: 200px;
    margin-top: 10px;
    padding: 0;
    display: block;
    position: inherit;
    box-shadow: none;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem {
    height: auto;
    margin: 0 10px;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem img {
    margin: 0;
    margin-right: 6px;
    width: 15px;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem .MuiTypography-root {
    font-size: 12px;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem .sideMenuItemText {
    display: block;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu .sideMenuItem.Mui-selected {
    border-bottom: 1px solid #FD4E3B;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu ul {
    margin-top: 0;
  }
  .m-menu-drawer.m-menu-drawer-myaccount .sideMenu ul li {
    margin: 0;
    border-bottom: 0;
  }
  header .header-menu ul li a {
    padding: 5px 7px;
    font-size: 13px;
  }
  header .logo img {
    height: 45px;
  }
  .banner-points .banner-points-item {
    margin-right: 15px;
  }
  .testimonialSlider .testimonial-item {
    padding: 0 15px;
  }
  .testimonials {
    padding-bottom: 30px;
  }
  .testimonials .testimonial-item-text {
    padding: 20px;
  }
  .home-works {
    padding: 40px 0;
  }
  .home-works .row {
    --bs-gutter-x:10px;
  }
  .home-works .home-works-item .home-works-item-img {
    height: 200px;
  }
  .home-works .home-works-item .home-works-item-cnt {
    padding: 15px 5px;
  }
  .home-works .home-works-item .home-works-item-cnt label {
    padding: 5px 0 5px;
  }
  footer {
    padding: 20px 0;
  }
  footer .logo-social-link .footer-logo {
    height: 40px;
    padding-right: 15px;
    margin-right: 15px;
  }
  footer .logo-social-link ul li {
    margin-right: 5px;
  }
  footer .logo-social-link ul li a img {
    width: 24px;
  }
  footer .copy-terms {
    font-size: 12px;
  }
  .about-banner {
    height: 280px;
  }
  .about-banner .about-banner-cnt {
    padding: 25px;
  }
  .about-banner .about-banner-cnt button {
    width: 250px;
    height: 40px;
  }
  .about-banner.caterer-banner {
    height: 280px;
  }
  .why-cp {
    padding: 40px 0;
  }
  .why-cp h3 {
    margin-bottom: 30px;
  }
  .why-cp .row {
    align-items: self-start !important;
  }
  .why-cp .why-cp-cnt ul {
    list-style: disc;
    padding-left: 20px;
  }
  .why-cp .why-cp-cnt ul li {
    margin-bottom: 15px;
  }
  .why-cp.convinced h4 {
    margin-bottom: 20px;
  }
  .action-btn {
    margin-top: 20px;
    gap: 20px;
  }
  .action-btn button {
    width: 150px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-radius: 6px;
  }
  .action-btn button.call-btn {
    background: #2C2D34;
  }
  .curate {
    margin-top: 30px;
    padding: 30px;
  }
  .curate .curate-inner {
    max-width: 80%;
  }
  .trusted-restaurants {
    padding: 30px 0;
  }
  .trusted-restaurants h4 {
    margin-bottom: 20px;
  }
  .trustedSlider2 {
    margin-top: 20px;
  }
  .trustedSlider2 img {
    margin-left: 0;
  }
  .how-it-work .row {
    --bs-gutter-x:50px;
  }
  .how-it-work .hw-item img {
    height: 135px;
  }
  .how-it-work .hw-item label.count {
    margin: 20px 0 10px;
  }
  .contact {
    min-height: auto;
  }
  .contact .container-fluid {
    --bs-gutter-x:0;
  }
  .contact .row {
    --bs-gutter-x:0;
  }
  .contact-img {
    background-color: #C1B2B0;
    height: 350px;
    padding-top: 30px;
    background-position-y: 80px;
  }
  .contact-img h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .contact-form {
    margin-bottom: 30px;
  }
  .contact-form p {
    margin-bottom: 20px;
  }
  .contact-form h5 {
    margin-bottom: 20px;
  }
  .contact-form .create-step-form .allFeild {
    margin-bottom: 20px;
  }
  .contact-form .submit-btn {
    height: 50px;
    width: 100%;
  }
  .faq {
    padding: 40px 20px;
  }
  .general-page {
    padding: 40px 0;
  }
  .menu-back {
    font-size: 14px;
  }
  .menu-back img {
    height: 10px;
  }
  .listing-filter-primary .lfp-delivery-address {
    min-width: 250px;
  }
  .listing-filter-primary .lfp-date input {
    width: 238px;
  }
  .listing-filter-primary .lfp-date {
    margin: 0 10px;
  }
  .listing-filter-primary .lfp-head-count {
    width: 110px;
  }
  .filter-mobile-title {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F2F2F2;
  }
  .filter-mobile-title h5 {
    font-size: 20px;
    margin-left: 6px;
    font-weight: 600;
  }
  .filter-mobile-title button img {
    width: 18px;
  }
  .listing-filter-secondary {
    padding: 0px;
  }
  .listing-filter-secondary.listing-filter-secondary-mob {
    padding: 15px 10px;
  }
  .listing-filter-secondary h3 {
    font-size: 16px;
  }
  .listing-filter-secondary .cater-list-filter-btn {
    padding: 5px;
  }
  .listing-filter-secondary .cater-list-filter-btn img {
    height: 15px;
  }
  .listing-filter-secondary .lfs-item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .listing-filter-secondary .lfs-search {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
  .listing-filter-secondary .lfs-search .MuiInputBase-root.MuiOutlinedInput-root {
    height: 48px;
  }
  .listing-filter-secondary .lfs-deitary {
    width: 100%;
  }
  .listing-filter-secondary .lfs-option {
    width: 100%;
  }
  .listing-filter-secondary .lfs-option .MuiSelect-select {
    padding-left: 30px !important;
    background-image: url("/public/assets/images/Options.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
  }
  .listing-filter-secondary .lfs-cuisnes {
    width: 100%;
  }
  .listing-filter-secondary .lfs-cuisnes .MuiSelect-select {
    padding-left: 30px !important;
    background-image: url("/public/assets/images/cusines.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
  }
  .listing-filter-secondary .lfs-sort {
    width: 100%;
    border-left: 0px;
    padding-left: 0px;
  }
  .listing-filter-secondary .MuiSelect-select {
    padding: 12.5px 8px 12.5px 8px !important;
    font-size: 12px;
  }
  .listing-filter-secondary .MuiSelect-select img {
    margin-right: 8px;
  }
  .listing-filter-secondary fieldset {
    border-radius: 6px;
  }
  .listing-filter-secondary .MuiInputAdornment-root {
    flex-shrink: 0;
  }
  .listing-filter-secondary .lfs-clear-btn {
    height: 32px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
  }
  .lfs-menuitem.MuiButtonBase-root {
    padding: 4px 8px;
    font-size: 12px !important;
  }
  .lfs-menuitem.MuiButtonBase-root img {
    margin-right: 8px;
  }
  .cater-list {
    height: calc(100vh - 198px);
  }
  .cater-list .cater-list-item-outer {
    margin-bottom: 20px;
  }
  .cater-list-item .cater-list-item-img {
    height: 200px;
  }
  .cater-list-item .cater-list-item-cnt {
    padding-top: 10px;
  }
  .cater-list-item .cater-list-item-cnt h4 {
    font-size: 16px;
  }
  .cater-list-item .cater-list-item-cnt h6 {
    margin: 4px 0 2px;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order {
    font-size: 10px;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span {
    padding-right: 4px;
    margin-right: 4px;
    line-height: initial;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span img {
    margin-right: 2px;
  }
  .detail-main-points {
    flex-wrap: wrap;
  }
  .detail-title-bar {
    padding-bottom: 0px;
    margin-bottom: 15px;
  }
  .detail-title-bar .detail-main-points li {
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .detail-title-bar .detail-main-points li.dmp-locations {
    max-width: fit-content;
  }
  .menu-category {
    margin-bottom: 30px;
  }
  .menu-category .menu-category-item {
    padding: 15px 5px;
    margin-right: 5px;
  }
  .menu-category-listing .menu-category-listing-col {
    margin-bottom: 20px;
  }
  .menu-category-listing .menu-category-listing-col::after {
    display: none;
  }
  .menu-category-listing .menu-category-listing-item {
    padding-bottom: 20px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton h3 .MuiSkeleton-root {
    width: 300px;
    height: 20px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton .dietry-label .MuiSkeleton-root {
    width: 180px;
    height: 20px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton .price-time .MuiSkeleton-root {
    width: 240px;
    height: 20px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton p .MuiSkeleton-root {
    width: 370px;
    height: 18px;
  }
  .caterer-menu-modal .caterer-menu-details {
    max-height: 100vh;
    height: 100vh;
    width: 100%;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-content {
    max-height: calc(100vh - 158px);
    padding: 20px;
  }
  .caterer-menu-modal .caterer-menu-details .caterer-menu-footer {
    padding: 20px;
    border-radius: 0;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .mob-cart-button-area {
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);
    width: 100%;
    z-index: 10;
  }
  .mob-cart-button-area button {
    height: 54px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    width: 60%;
    margin: auto;
  }
  .cart-view-col {
    height: calc(100vh - 70px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
  }
  .cart-view {
    padding: 10px;
    margin: 25px 0px;
    padding-top: 0;
    border: 1px solid #e3e3e3;
  }
  .cart-view .cart-view-title {
    margin: 0 -10px;
    padding: 10px 12px;
  }
  .cart-view .cart-review-text p {
    text-align: center;
    margin-bottom: 15px;
  }
  .cater-detail-timing li {
    width: 50%;
    font-size: 12px;
  }
  .cater-detail-timing li:nth-child(3n+2) {
    margin: 0;
  }
  .cater-detail-timing li:nth-child(3n+2)::before, .cater-detail-timing li:nth-child(3n+2)::after {
    display: none;
  }
  .cater-detail-timing li:nth-child(odd) {
    border-right: 0.6px solid #efefef;
    padding-right: 10px;
  }
  .cater-detail-timing li:nth-child(even) {
    padding-left: 10px;
  }
  .order-confirm-screen {
    height: calc(100vh - 155px);
  }
  .order-confirm-screen .ocs-text {
    width: 100%;
    padding: 0 10px;
  }
  .common-drawer .MuiDrawer-paper {
    width: 500px;
  }
  .create-form-left {
    display: none;
  }
  .create-stepper .MuiStepLabel-label {
    font-size: 15px;
  }
  .width-175 {
    min-width: 220px;
  }
  .catercash-overview {
    gap: 2%;
    flex-wrap: wrap;
  }
  .catercash-overview .cc-item {
    width: 48%;
    flex-basis: inherit;
    margin-bottom: 18px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1080px) and (orientation: landscape) {
  .h1 {
    font-size: 32px;
    line-height: 42px;
  }
  .h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .h4 {
    font-size: 20px;
    line-height: 28px;
  }
  .h5 {
    font-size: 18px;
    line-height: 24px;
  }
  .h6 {
    font-size: 14px;
  }
  header .header-menu ul li a {
    padding: 5px 10px;
    font-size: 14px;
  }
  header .header-menu .MuiAvatar-root {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  header .header-menu .header-user-name {
    font-size: 14px;
    margin-left: 8px;
  }
  .testimonials .testimonial-item-text {
    padding: 20px;
  }
  .testimonials .testimonial-item-text p {
    font-size: 14px;
  }
  .home-works .home-works-item .home-works-item-cnt {
    padding: 20px 30px;
  }
  .home-works .home-works-item .home-works-item-cnt label {
    padding: 10px 0 12px;
  }
  .home-works .home-works-item .home-works-item-img {
    height: 250px;
  }
  footer {
    padding: 20px 0;
  }
  footer .logo-social-link .footer-logo {
    height: 50px;
  }
  .listing-filter-primary .lfp-delivery-address {
    min-width: 320px;
  }
  .listing-filter-primary .lfp-date {
    margin: 0 15px;
  }
  .listing-filter-primary .lfp-date input {
    width: 250px;
  }
  .listing-filter-primary .lfp-head-count {
    width: 130px;
  }
  .listing-filter-secondary {
    padding-bottom: 8px;
  }
  .listing-filter-secondary h3 {
    font-size: 12px;
    flex-shrink: 0;
  }
  .listing-filter-secondary .lfs-item {
    margin-right: 10px;
    background-size: 12px;
  }
  .listing-filter-secondary .lfs-search {
    padding-right: 10px;
    width: 160px;
  }
  .listing-filter-secondary .lfs-deitary {
    width: 120px;
  }
  .listing-filter-secondary .lfs-option {
    width: 130px;
  }
  .listing-filter-secondary .lfs-option .MuiSelect-select {
    padding-left: 25px !important;
    background-size: 12px;
  }
  .listing-filter-secondary .lfs-cuisnes {
    width: 120px;
  }
  .listing-filter-secondary .lfs-cuisnes .MuiSelect-select {
    padding-left: 25px !important;
    background-size: 12px;
  }
  .listing-filter-secondary .lfs-sort {
    width: 150px;
    padding-left: 10px;
  }
  .listing-filter-secondary .MuiSelect-select {
    font-size: 12px;
  }
  .listing-filter-secondary .MuiSelect-select img {
    margin-right: 4px;
  }
  .listing-filter-secondary fieldset {
    border-radius: 6px;
  }
  .listing-filter-secondary .MuiInputAdornment-root {
    flex-shrink: 0;
  }
  .listing-filter-secondary .lfs-clear-btn {
    height: 32px;
    font-size: 12px;
  }
  .cater-list .cater-list-item-outer {
    margin-bottom: 25px;
  }
  .cater-list-item .cater-list-item-img {
    height: 200px;
  }
  .cater-list-item .cater-list-item-img .cater-cash-tag {
    top: 10px;
    right: 10px;
  }
  .cater-list-item .cater-list-item-cnt {
    padding-top: 10px;
  }
  .cater-list-item .cater-list-item-cnt h6 {
    margin-top: 5px;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order {
    flex-wrap: wrap;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span {
    padding-right: 3px;
    margin-right: 3px;
    width: 47%;
    border-right: 0;
  }
  .cater-list-item .cater-list-item-cnt .cater-list-order span img {
    margin-right: 5px;
  }
  .cater-cash-tag {
    width: 65px;
    height: 65px;
  }
  .cater-cash-tag b {
    font-size: 16px;
    line-height: 18px;
  }
  .cater-cash-tag label {
    line-height: 12px;
  }
  .detail-title-bar {
    padding-bottom: 10px;
  }
  .detail-title-bar .detail-main-points {
    flex-wrap: wrap;
  }
  .detail-title-bar .detail-main-points li {
    margin-bottom: 10px;
  }
  .menu-category {
    margin-bottom: 30px;
  }
  .menu-category-listing .menu-category-listing-item .menu-category-listing-item-img img {
    width: 75px;
    height: 55px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton h3 .MuiSkeleton-root {
    width: 250px;
    height: 22px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton .dietry-label .MuiSkeleton-root {
    width: 150px;
    height: 22px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton .price-time .MuiSkeleton-root {
    width: 200px;
    height: 22px;
  }
  .menu-category-listing .menu-category-listing-item.skeleton p .MuiSkeleton-root {
    width: 270px;
    height: 20px;
  }
  .cart-total, .cart-price-perhead {
    font-size: 14px;
  }
  .order-confirm-screen {
    height: calc(100vh - 165px);
  }
  .catercash-overview {
    gap: 5px;
    padding: 20px 10px;
  }
  .catercash-overview .cc-item h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .catercash-overview .cc-item label {
    line-height: 20px;
    font-size: 12px;
    margin-left: 0;
  }
  .catercash-overview .cc-item .cc-item-inner {
    display: block !important;
  }
  .catercash-overview .cc-item.cc-gift small {
    line-height: 14px;
  }
  .catercash-overview .cc-item.cc-gift .amazon-btn button {
    width: 40px;
    font-size: 12px;
  }
  .width-175 {
    min-width: 220px;
  }
  .mob-cart-button-area {
    padding: 14px 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -3px 52px rgba(0, 0, 0, 0.11);
    width: 100%;
    z-index: 10;
  }
  .mob-cart-button-area button {
    height: 50px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    width: 45%;
    margin: auto;
  }
  .cart-view-col {
    height: auto;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
  }
  .cart-view {
    padding: 0px 10px 0 10px;
    width: 70%;
    margin: 20px auto;
    border: 1px solid #e3e3e3;
  }
  .cart-view .cart-view-title {
    margin: 0 -10px;
    padding: 10px 12px;
  }
}
/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .detail-main-points {
    flex-wrap: wrap;
  }
  .detail-title-bar .detail-main-points li {
    margin-bottom: 15px;
  }
}
@media (min-width: 1700px) {
  .order-confirm-screen .ocs-text {
    width: 48%;
  }
  .catercash-overview .cc-item {
    height: 170px;
  }
}
@media (min-width: 2300px) {
  .order-confirm-screen .ocs-text {
    width: 40%;
  }
  .catercash-overview .cc-item {
    height: 200px;
  }
}
.slick-track {
  display: flex;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

#hs_form_target_widget_1730901540411-182432056907 label {
  display: none;
}

#hsForm_c6c9ed17-ed8b-47da-9e8a-519b7633b6a8_719 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

