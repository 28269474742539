.cart-view-list .cart-view-list-item {
  border-bottom: 0.6px solid #d8d8d8;
  padding: 20px 0;
}

.cart-view-list .cart-view-list-item .cart-count-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-view-list .cart-view-list-item .cart-count-name .item-count {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50px;
  background-color: #000;
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.cart-view-list .cart-count-name .item-count span {
  font-size: 12px;
  font-weight: 700;
}

.cart-view-list .cart-count-name .item-count small {
  font-size: 10px;
  font-weight: 400;
}

.cart-view-list .cart-count-name h6 {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.cart-view-list .cart-view-list-item .cart-price-delete {
  padding-left: 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.cart-view-list .cart-view-list-item .cart-price-delete b {
  font-size: 12px;
}

.cart-view-list .cart-view-list-item .cart-price-delete button {
  padding: 4px;
  margin-left: 4px;
}

.cart-view .cart-view-list .cart-view-list-item .cart-price-delete button:hover img {
  filter: invert(44%) sepia(61%) saturate(3524%) hue-rotate(337deg) brightness(103%) contrast(107%);
}

.cart-view-list .cart-view-list-item .cart-item-name {
  padding: 0 35px;
}

.cart-view-list .cart-view-list-item .cart-item-name span {
  margin-top: 3px;
  font-size: 12px;
  line-height: 14px;
  color: #3e3f45;
  display: block;
  text-transform: capitalize;
}

.cart-view-list .cart-view-list-item .cart-item-name ul {
  margin: 5px 0;
  margin-left: 8px;
}

.cart-view-list .cart-view-list-item .cart-item-name ul li {
  font-size: 10px;
  color: #646777;
  text-transform: capitalize;
}

.cart-view-list .cart-view-list-item .sp-instruction {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 5px;
  color: #939393;
}

.cart-view-list .cart-view-list-item .sp-instruction img {
  width: 15px;
  margin-right: 3px;
}
